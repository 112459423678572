import React, { FC, Suspense, useState, } from 'react'
import Modal from 'react-responsive-modal';
import { updateCustomerStatus } from '../../models/_customer';

type Props = {
    currentCustomerId: string;
    changeReactivateModal: boolean
    handleCloseReactivateCustomer: () => void;
    refreshTable?: any
}

export const ReactivateCustomerModal: FC<Props> = ({
    changeReactivateModal,
    handleCloseReactivateCustomer,
    currentCustomerId,
    refreshTable
}) => {

    const [customerStatus] = useState(true);
  const handleCustomerStatus = async (activateStatus: boolean) => {
    try {
      const recordId = currentCustomerId; 
      await updateCustomerStatus(recordId, customerStatus, activateStatus);
    } catch (error) {
      console.error('Error updating customer status:', error);
    }
    handleCloseReactivateCustomer()
    if(typeof refreshTable !== 'undefined'){
        refreshTable() 
      }
  };
    return (

        <div>
            <Suspense>
                <Modal
                    open={changeReactivateModal}
                    onClose={() => handleCloseReactivateCustomer()}
                    role="modal"
                    center
                    classNames={{
                        root: "content-wrapper",
                    }}
                >
                    <div className='modal-title'></div>
                    <div className="modal-body p-2">
                        <h4 className=" fs-4 mb-8">Do you want to reactive locations & machines as well?</h4>
                    </div>
                    <div className="modal-footer justify-content-center p-2">
                        <button className="btn btn-primary me-4 ml-4 col-3 mt-4"  onClick={() => handleCustomerStatus(true)}>Yes</button>
                        <button className="btn btn-secondary col-3 mt-4" onClick={() => handleCustomerStatus(false)}>No </button>

                    </div>
                </Modal>
               
            </Suspense>
        </div>
    )
}

export default ReactivateCustomerModal

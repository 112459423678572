const FileUpload = (props: {
    handleCloseImageChange: any;
    closeImages: any;
    removeCloseImage: any;
    handleStoreImageChange: any;
    storeImages: any;
    removeStoreImage: any;
    handleDrainImageChange: any;
    drainImages: any;
    removeDrainImage: any;
    handleSerialNumberImageChange: any;
    serialNumberImages: any;
    removeSerialNumberImage: any;
  }) => {
    const {
      handleCloseImageChange,
      closeImages,
      removeCloseImage,
      handleStoreImageChange,
      storeImages,
      removeStoreImage,
      handleDrainImageChange,
      drainImages,
      removeDrainImage,
      handleSerialNumberImageChange,
      serialNumberImages,
      removeSerialNumberImage,
    } = props;
  
    return (
      <>
        <div className="wo-form-section">
          <div className="upload-section mt-15">
            <h3>Upload Picture of Set Up (Close Up)</h3>
            <div className="upload-box">
              <input
                type="file"
                className="file-input"
                multiple
                accept="image/png, image/jpeg, image/webp, video/mp4, video/mkv, video/x-m4v, video/*"
                onChange={handleCloseImageChange}
              />
  
              {closeImages && closeImages.length > 0 ? (
                <>
                  {closeImages.map((image: any, index: any) => {
                    return (
                      <div
                        key={index}
                        className="img-box mt-3 ms-2 justify-content-center"
                      >
                        {image.type.startsWith("image/") ? (
                          <img
                            src={image.content}
                            width="150"
                            alt="Upload"
                            className="img-wrapper overflow-hidden m-2 ms-0 d-flex position-relative"
                          />
                        ) : (
                          <video
                            controls
                            src={image.content}
                            className="img-wrapper overflow-hidden m-2 ms-0 d-flex position-relative"
                            width="170"
                          />
                        )}
                        <button
                          onClick={() => removeCloseImage(image)}
                          className="close-btn img-btn"
                        >
                          <i className="fa fa-close fs-5 pt-1" />
                        </button>
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className="file-label">
                  <i
                    className="fa fa-cloud-upload cloud-icon"
                    aria-hidden="true"
                  ></i>
                  <span>Browse Files</span>
                  <p>Drag and drop files here</p>
                </div>
              )}
            </div>
          </div>
  
          <div className="upload-section mt-15">
            <h3>Upload Picture of Store Set Up</h3>
            <div className="upload-box">
              <input
                type="file"
                className="file-input"
                multiple
                accept="image/png , image/jpeg, image/webp, video/mp4, video/mkv, video/x-m4v, video/*"
                onChange={handleStoreImageChange}
              />
              {storeImages && storeImages.length > 0 ? (
                <>
                  {storeImages &&
                    storeImages.map((image: any, index: any) => {
                      return (
                        <div
                          key={index}
                          className="img-box mt-3 ms-2 justify-content-center"
                        >
                          {image.type === "image/png" ||
                          image.type === "image/jpeg" ||
                          image.type === "image/webp" ||
                          image.type === "image/jpg" ? (
                            <img
                              src={image.content}
                              key={index}
                              width="150"
                              alt="Upload"
                              className="img-wrapper overflow-hidden m-2 ms-0 d-flex position-relative"
                            />
                          ) : (
                            <video
                              controls
                              src={image.content}
                              className="img-wrapper overflow-hidden m-2 ms-0 d-flex position-relative"
                              width="170"
                            />
                          )}
                          <button
                            onClick={() => removeStoreImage(image)}
                            className="close-btn img-btn"
                          >
                            <i className="fa fa-close fs-5 pt-1" />
                          </button>
                        </div>
                      );
                    })}
                </>
              ) : (
                <div className="file-label">
                  <i
                    className="fa fa-cloud-upload cloud-icon"
                    aria-hidden="true"
                  ></i>
                  <span>Browse Files</span>
                  <p>Drag and drop files here</p>
                </div>
              )}
            </div>
          </div>
  
          <div className="upload-section mt-15">
            <h3>Upload Picture/Video of Drain Set Up</h3>
            <div className="upload-box">
              <input
                type="file"
                className="file-input"
                multiple
                accept="image/png , image/jpeg, image/webp, video/mp4, video/mkv, video/x-m4v, video/*"
                onChange={handleDrainImageChange}
              />
              {drainImages && drainImages.length > 0 ? (
                <>
                  {drainImages &&
                    drainImages.map((image: any, index: any) => {
                      return (
                        <div
                          key={index}
                          className="img-box mt-3 ms-2 justify-content-center"
                        >
                          {image.type === "image/png" ||
                          image.type === "image/jpeg" ||
                          image.type === "image/webp" ||
                          image.type === "image/jpg" ? (
                            <img
                              src={image.content}
                              key={index}
                              width="150"
                              alt="Upload"
                              className="img-wrapper overflow-hidden m-2 ms-0 d-flex position-relative"
                            />
                          ) : (
                            <video
                              controls
                              src={image.content}
                              className="img-wrapper overflow-hidden m-2 ms-0 d-flex position-relative"
                              width="170"
                            />
                          )}
                          <button
                            onClick={() => removeDrainImage(image)}
                            className="close-btn img-btn"
                          >
                            <i className="fa fa-close fs-5 pt-1" />
                          </button>
                        </div>
                      );
                    })}
                </>
              ) : (
                <div className="file-label">
                  <i
                    className="fa fa-cloud-upload cloud-icon"
                    aria-hidden="true"
                  ></i>
                  <span>Browse Files</span>
                  <p>Drag and drop files here</p>
                </div>
              )}
            </div>
          </div>
  
          <div className="upload-section mt-15">
            <h3>Upload Picture of Serial Numbers (Can Upload Multiple)</h3>
            <div className="upload-box">
              <input
                type="file"
                className="file-input"
                accept="image/png , image/jpeg, image/webp, video/mp4, video/mkv, video/x-m4v, video/*" // Only allow image files
                multiple
                onChange={handleSerialNumberImageChange}
              />
              {serialNumberImages && serialNumberImages.length > 0 ? (
                <>
                  {serialNumberImages &&
                    serialNumberImages.map((image: any, index: any) => {
                      return (
                        <div
                          key={index}
                          className="img-box mt-3 ms-2 justify-content-center"
                        >
                          {image.type === "image/png" ||
                          image.type === "image/jpeg" ||
                          image.type === "image/webp" ||
                          image.type === "image/jpg" ? (
                            <img
                              src={image.content}
                              key={index}
                              width="150"
                              alt="Upload"
                              className="img-wrapper overflow-hidden m-2 ms-0 d-flex position-relative"
                            />
                          ) : (
                            <video
                              controls
                              src={image.content}
                              className="img-wrapper overflow-hidden m-2 ms-0 d-flex position-relative"
                              width="170"
                            />
                          )}
                          <button
                            onClick={() => removeSerialNumberImage(image)}
                            className="close-btn img-btn"
                          >
                            <i className="fa fa-close fs-5 pt-1" />
                          </button>
                        </div>
                      );
                    })}
                </>
              ) : (
                <div className="file-label">
                  <i
                    className="fa fa-cloud-upload cloud-icon"
                    aria-hidden="true"
                  ></i>
                  <span>Browse Files</span>
                  <p>Drag and drop files here</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };
  
  export default FileUpload;
  
import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

export async function UpdateTicketAssignee(
  id: string,
  assignToId: string,
  comment: string
) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        mutation updateTicketAssignee (
                  $id: String!,
                  $assignToId: String!,
                  $comment: String!,
              ){
            updateTicketAssignee(
              updateTicketAssignToInput: {
                id: $id
                assignToId: $assignToId
                comment: $comment
              }
            ) {
              id
              ticketType
              machineId
              parentId
              ticketSourceId
              reportedAt
              assignToId
              comment
              lastStatusComment
              lastAssigneToComment
              status
              createdById
              createdAt
              updatedById
              updatedAt
              assignTo {
                id
                firstName
                lastName
              }
              createdBy {
                id
                firstName
                lastName
              }
              parent {
                id
                ticketType
                machineId
                parentId
                reportedAt
                assignToId
                comment
                lastStatusComment
                lastAssigneToComment
                status
                createdById
                createdAt
                updatedById
                updatedAt
              }
              subTasks {
                id
                ticketType
                machineId
                parentId
                reportedAt
                assignToId
                comment
                lastStatusComment
                lastAssigneToComment
                status
                createdById
                createdAt
                updatedById
                updatedAt
                assignTo {
                  id
                  firstName
                  lastName
                }
                createdBy {
                  id
                  firstName
                  lastName
                }
              }
            }
          }
        `,
      variables: {
        id,
        assignToId,
        comment,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}
interface UpdateTicketSignatureInput {
  id: string;
  signatureName: string;
  signatureImage: string[];
}
export async function UpdateTicketSignature(input: UpdateTicketSignatureInput) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        mutation updateTicketSignature (
                  $input: UpdateTicketSignatureInput!
              ){
                updateTicketSignature(
                  updateTicketSignatureInput:  $input
            ) {
              id
              uuid
              ticketType
              machineId
              parentId
              reportedAt
              assignToId
              priority
              issueType {
                id
                name
              }
              serviceType
              machineServices {
                id
                name
              }
              operations {
                id
                name
              }
              machineServicesDone {
                id
                name
              }
              operationsDone {
                id
                name
              }
              marketingOrOthers {
                id
                name
              }
              comment
              lastStatusComment
              lastAssigneToComment
              status
              createdAt
              updatedAt
              media {
                url
                type
              }
              signatureName
              signatureImage {
                url
                type
              }
              machine {
                id
                uuid
                serialNumber
                name
              }
              ticketSource {
                id
                name
              }
              assignTo {
                id
                firstName
                lastName
              }
              createdBy {
                id
                firstName
                lastName
              }
              updatedBy {
                id
                firstName
                lastName
              }
              parent {
                id
                uuid
                ticketType
                machineId
                signatureName
                signatureImage {
                  url
                  type
                }
              }
              subTasks {
                id
                uuid
                parentId
                updatedAt
                media {
                  url
                  type
                }
                signatureName
                signatureImage {
                  url
                  type
                }
              }
            }
          }
        `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}
interface UpdateTicketStatusInput {
  id: string;
  status: string;
  comment: string;
  media?: string[];
  machineServices?: string[];
  operations?: string[];
  marketingOrOthers?: string[];
  lastStatusComment?: "";
  howTicketClosed?: "";
  closingDateAndTime?: string;
  timeSpentOnTask: number;
  customerSatisfaction?: string | null;
  cleanliness?: string | null;
  trafficRate?: string | null;
  staffEnthusiasm?: string | null;
  travelTime?: number;
  machineServiceOtherInput?: string;
  operationOtherInput?: string;
  marketingOtherInput?: string;
  marketingMaterials?: string[];
  replaceNewTubeDoors1mmShorter?: boolean,
  blenderId?: string,
  boardId?: string,
  oldMachineShiftToLocationId?: string,
  convertToLabTicket?: boolean
}
export async function UpdateTicketStatus(input: UpdateTicketStatusInput) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        mutation updateTicketStatus (
                $input: UpdateTicketStatusInput!
              ){
                updateTicketStatus(
                  updateTicketStatusInput: $input
            ) {
              id
              uuid
              ticketType
              machineId
              parentId
              ticketSourceId
              reportedAt
              assignToId
              issueTypeId
              priority
              isFilterReplaced 
              isSanitizerReplaced
              marketingMaterials {
                id
                name
              }
              issueType {
                id
                name
              }
              serviceType
              machineServices {
                id
                name
              }
              operations {
                id
                name
              }
              machineServicesDone {
                id
                name
              }
              operationsDone {
                id
                name
              }
              marketingOrOthers {
                id
                name
              }
              comment
              lastStatusComment
              lastAssigneToComment
              status
              howTicketClosed
              closingDateAndTime
              timeSpentOnTask
              travelTime
              customerSatisfaction
              cleanliness
              trafficRate
              staffEnthusiasm
              createdById
              createdAt
              updatedById
              updatedAt
              media{
                url
                type
              }
              signatureName
              signatureImage {
                url
                type
              }
              machine {
                id
                uuid
                serialNumber
                name
              }
              ticketSource {
                id
                name
              }
              assignTo {
                id
                firstName
                lastName
              }
              createdBy {
                id
                firstName
                lastName
              }
              updatedBy {
                id
                firstName
                lastName
              }
              parent {
                id
                uuid
                ticketType
                machineId
                parentId
                ticketSourceId
                reportedAt
                assignToId
                issueTypeId
                priority
                serviceType
                comment
                lastStatusComment
                lastAssigneToComment
                status
                howTicketClosed
                closingDateAndTime
                timeSpentOnTask
                travelTime
                customerSatisfaction
                cleanliness
                trafficRate
                staffEnthusiasm
                createdById
                createdAt
                updatedById
                updatedAt
                media{
                  url
                  type
                }
              }
              subTasks {
                id
                uuid
                ticketType
                machineId
                parentId
                ticketSourceId
                reportedAt
                assignToId
                issueTypeId
                priority
                issueType {
                  id
                  name
                }
                serviceType
                machineServices {
                  id
                  name
                }
                operations {
                  id
                  name
                }
                machineServicesDone {
                  id
                  name
                }
                operationsDone {
                  id
                  name
                }
                marketingOrOthers {
                  id
                  name
                }
                comment
                lastStatusComment
                lastAssigneToComment
                status
                howTicketClosed
                closingDateAndTime
                timeSpentOnTask
                travelTime
                customerSatisfaction
                cleanliness
                trafficRate
                staffEnthusiasm
                createdById
                createdAt
                updatedById
                updatedAt
                media{
                  url
                  type
                }
              }
            }
          }
        `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

export async function UpdateTicketPriority(
  id: string,
  priority: string,
  comment: string
) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        mutation updateTicketPriority (
                  $id: String!,
                  $priority: Priority!,
                  $comment: String!,
              ){
            updateTicketPriority(
              updateTicketPriorityInput: {
                id: $id
                priority: $priority
                comment: $comment
              }
            ) {
              id
              uuid
              ticketType
              machineId
              parentId
              reportedAt
              assignToId
              priority
              issueType {
                id
                name
              }
              serviceType
              comment
              lastStatusComment
              lastAssigneToComment
              status
              createdBy {
                id
                firstName
              }
              updatedBy {
                id
                firstName
              }
              subTasks {
                id
                uuid
                ticketType
                parentId
                reportedAt
              }
            }
          }
        `,
      variables: {
        id,
        priority,
        comment,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}


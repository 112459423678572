import {
  initialValues,
  radioButtonDB,
  SelectedOption,
  initialQuestion,
  surveyQuestionTypes,
  surveyValidationSchema,
} from "./SurveyHelper";
import clsx from "clsx";
import {useState} from "react";
import Select from "react-select";
import "react-responsive-modal/styles.css";
import toast, {Toaster} from "react-hot-toast";
import {createSurvey} from "../../../models/_survey";
import {ErrorMessage, Formik, FieldArray} from "formik";

function CreateSurvey() {
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState<
    (SelectedOption | null)[]
  >([]);

  return (
    <div>
      <div id="kt_app_toolbar" className="app-toolbar py-2 pt-4">
        <div
          id="kt_app_toolbar_container"
          className="app-container container-xxl d-block d-lg-flex d-md-flex d-xl-flex flex-stack px-3"
          style={{maxWidth: "100%"}}
        >
          <div className="page-title d-flex flex-column justify-content-center flex-wrap">
            <h1 className="page-heading d-flex text-dark fw-bold fs-1 flex-column justify-content-center my-0">
              Create Survey
            </h1>
          </div>
          <div className="d-flex align-items-center gap-2 gap-lg-3 mt-3 mt-lg-0 mt-md-0">
            <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
              <li className="breadcrumb-item text-muted">
                <a href="/" className="text-muted text-hover-primary fs-5">
                  Home
                </a>
              </li>
              <li className="breadcrumb-item">
                <span className="bullet bg-gray-400 w-5px h-2px" />
              </li>
              <li className="breadcrumb-item text-dark fs-5">Create Survey</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="card mx-xl-4 mt-9">
        <Formik
          initialValues={initialValues}
          validationSchema={surveyValidationSchema}
          onSubmit={async (values, {resetForm, setSubmitting}) => {
            setLoading(true);
            const $inputData: any = {
              title: values.title,
              questions: values.questions.map((question) => ({
                question: question.question,
                answers: question.answers,
                is_multi_answer: question.is_multi_answer,
                answer_type: question.answer_type,
                from_range: question.from_range,
                to_range: question.to_range,
                start_label: question.start_label,
                end_label: question.end_label,
              })),
              other_feedback_required: values.other_feedback_required,
            };
            const result = await createSurvey($inputData);

            if (result?.data?.data?.createSurvey) {
              toast.success("Survey created successfully");
              setSubmitting(false);
              setLoading(false);
              resetForm();
            } else {
              setSubmitting(false);
              setLoading(false);
              const messages = result?.data?.errors.map((e: any) => e.message);
              toast.error(messages.join(","));
            }
          }}
        >
          {({handleChange, handleSubmit, values, setFieldValue}) => (
            <div className="card-body w-100 p-lg-7 p-md-7 p-5">
              <form onSubmit={handleSubmit} autoComplete="off">
                <div className="row">
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="form-label fs-4 required fw-bold">
                      Title
                    </label>
                    <input
                      className={clsx("form-control")}
                      name="title"
                      id="title"
                      value={values.title}
                      onChange={handleChange}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="title" />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                    <label className="form-label fs-4 required fw-bold ps-1 mt-2">
                      Questions
                    </label>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                    <button
                      type="button"
                      className="btn btn-secondary me-4 fs-4 float-end"
                      onClick={() =>
                        setFieldValue("questions", [
                          ...values.questions,
                          initialQuestion,
                        ])
                      }
                    >
                      Add Question
                    </button>
                  </div>
                </div>
                <FieldArray name="questions">
                  {({push, remove}) => (
                    <>
                      {values.questions.map((question, index) => (
                        <div key={index} className="form-field">
                          <div className="question card border m-4">
                            <div>
                              <i
                                className="fa fa-times float-end me-4 mt-2"
                                onClick={() => remove(index)}
                                aria-hidden="true"
                              ></i>
                            </div>
                            <div className="card-body w-100 p-4">
                              <div className="row mt-2 mb-6">
                                <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                  <h5 className="card-title required">
                                  {index + 1}) Display Type
                                  </h5>
                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-6 col-12">
                                  <Select
                                    className="react-select"
                                    classNamePrefix="my-react-select"
                                    name={`questions[${index}].answer_type`}
                                    id={`questions[${index}].answer_type`}
                                    placeholder=""
                                    options={Array.from(
                                      surveyQuestionTypes
                                    ).map((value: any) => {
                                      return {
                                        value: value.id,
                                        label: value.name,
                                      };
                                    })}
                                    onChange={(e: any) => {
                                      console.log(e);
                                      setFieldValue(
                                        `questions[${index}].answer_type`,
                                        e.value
                                      );
                                      const updatedOptions = [
                                        ...selectedOption,
                                      ];
                                      updatedOptions[index] = e;
                                      setSelectedOption(updatedOptions);
                                    }}
                                  />
                                  <div className="text-danger text-align-top-center mt-2">
                                    <ErrorMessage
                                      name={`questions[${index}].answer_type`}
                                    />
                                  </div>
                                </div>
                              </div>
                              {selectedOption[index]?.value && (
                                <div className="row mt-2 mb-6">
                                  <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                    <h5 className="card-title required">
                                      Question
                                    </h5>
                                  </div>
                                  <div className="col-xl-9 col-lg-9 col-md-9 col-12">
                                    <input
                                      className={clsx("form-control")}
                                      id={`questions[${index}].question`}
                                      name={`questions[${index}].question`}
                                      value={question.question}
                                      onChange={handleChange}
                                      placeholder="Enter question"
                                      type="text"
                                    />
                                    <div className="text-danger text-align-top-center mt-2">
                                      <ErrorMessage
                                        name={`questions[${index}].question`}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                              {selectedOption[index]?.value === "Button" && (
                                <>
                                  <div className="row mt-2 mb-6">
                                    <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                      <h5 className="card-title required">
                                        Answers
                                      </h5>
                                    </div>
                                    <div className="col-xl-9 col-lg-9 col-md-9 col-12">
                                      <FieldArray
                                        name={`questions[${index}].answers`}
                                      >
                                        {({push, remove}) => {
                                          // Ensure question and question.answers are defined
                                          const answers =
                                            values.questions[index]?.answers ||
                                            [];

                                          return (
                                            <>
                                              <div className="d-flex flex-wrap ">
                                                {answers.map(
                                                  (answer, ansIndex) => (
                                                    <div
                                                      key={ansIndex}
                                                      className="form-group mt-2 d-flex align-items-center me-2"
                                                    >
                                                      <div className="d-flex flex-column">
                                                        <div className="input-group">
                                                          <label
                                                            className="ms-2 mt-4 me-2"
                                                            style={{
                                                              fontSize: "1rem",
                                                              fontWeight: "600",
                                                            }}
                                                          >
                                                            A{ansIndex + 1}
                                                          </label>
                                                          <input
                                                            className={clsx(
                                                              "form-control"
                                                            )}
                                                            name={`questions[${index}].answers[${ansIndex}]`}
                                                            id={`questions[${index}].answers[${ansIndex}]`}
                                                            value={answer}
                                                            onChange={
                                                              handleChange
                                                            }
                                                            placeholder="Enter answer"
                                                            type="text"
                                                          />
                                                          {answers.length >
                                                            1 && (
                                                            <i
                                                              className="fa fa-times ms-2 mt-4 me-2"
                                                              onClick={() => {
                                                                remove(
                                                                  ansIndex
                                                                );
                                                              }}
                                                              aria-hidden="true"
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                            ></i>
                                                          )}
                                                        </div>
                                                        <div className="text-danger text-align-top-center mt-2">
                                                          <ErrorMessage
                                                            name={`questions[${index}].answers[${ansIndex}]`}
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                              <button
                                                type="button"
                                                className="btn btn-secondary mt-2"
                                                onClick={() => push("")}
                                              >
                                                Add Answer
                                              </button>
                                            </>
                                          );
                                        }}
                                      </FieldArray>
                                    </div>
                                  </div>

                                  <div className="row mt-2 mb-6">
                                    <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                      <h5 className="card-title required">
                                        Is Multi Answer?
                                      </h5>
                                    </div>
                                    <div className="col-xl-9 col-lg-9 col-md-9 col-12">
                                      {radioButtonDB.map((value) => (
                                        <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-inline-flex mt-1">
                                          <label className="fs-5 mb-0">
                                            <input
                                              className="form-check-input"
                                              id={`questions[${index}].is_multi_answer`}
                                              type="radio"
                                              name={`questions[${index}].is_multi_answer`}
                                              value={value.id.toString()}
                                              checked={
                                                question.is_multi_answer ===
                                                value.id
                                              }
                                              onChange={() =>
                                                setFieldValue(
                                                  `questions[${index}].is_multi_answer`,
                                                  value.id
                                                )
                                              }
                                            />
                                            <span className="form-check-label fs-5 mb-0 ms-2">
                                              {value.name}
                                            </span>
                                          </label>
                                        </div>
                                      ))}
                                      <div className="text-danger text-align-top-center mt-2">
                                        <ErrorMessage
                                          name={`questions[${index}].is_multi_answer`}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                              {(selectedOption[index]?.value === "Numeric_Scale" || selectedOption[index]?.value === 'Sliding_Scale') && (
                                <>
                                  <div className="row mt-2 mb-6">
                                    <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                      <h5 className="card-title required">
                                        Range
                                      </h5>
                                    </div>
                                    <div className="col-xl-3 col-lg-4 col-md-6 col-12">
                                      <input
                                        className={clsx("form-control")}
                                        name={`questions[${index}].from_range`}
                                        id={`questions[${index}].from_range`}
                                        type="number"
                                        value={question.from_range}
                                        onChange={handleChange}
                                        placeholder="From Range"
                                      />
                                      <div className="text-danger text-align-top-center mt-2">
                                        <ErrorMessage
                                          name={`questions[${index}].from_range`}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-4 col-md-6 col-12">
                                      <input
                                        className={clsx("form-control")}
                                        name={`questions[${index}].to_range`}
                                        id={`questions[${index}].to_range`}
                                        type="number"
                                        value={question.to_range}
                                        onChange={handleChange}
                                        placeholder="To Range"
                                      />
                                      <div className="text-danger text-align-top-center mt-2">
                                        <ErrorMessage
                                          name={`questions[${index}].to_range`}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row mt-2 mb-6">
                                    <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                      <h5 className="card-title">
                                        Label
                                      </h5>
                                    </div>
                                    <div className="col-xl-3 col-lg-4 col-md-6 col-12">
                                      <input
                                        className={clsx("form-control")}
                                        name={`questions[${index}].start_label`}
                                        id={`questions[${index}].start_label`}
                                        placeholder="Start Label"
                                        value={question.start_label}
                                        onChange={handleChange}
                                      />
                                      <div className="text-danger text-align-top-center mt-2">
                                        <ErrorMessage
                                          name={`questions[${index}].start_label`}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-4 col-md-6 col-12">
                                      <input
                                        className={clsx("form-control")}
                                        name={`questions[${index}].end_label`}
                                        id={`questions[${index}].end_label`}
                                        placeholder="End Label"
                                        value={question.end_label}
                                        onChange={handleChange}
                                      />
                                      <div className="text-danger text-align-top-center mt-2">
                                        <ErrorMessage
                                          name={`questions[${index}].end_label`}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </FieldArray>

                <div className="row">
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4 ">
                    <label className="form-label fs-4 required fw-bold ps-1">
                      Is Other Feedback Required?
                    </label>
                    {radioButtonDB.map((value) => (
                      <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-flex mt-1 ms-2">
                        <label className="form-check-label fs-5 mb-0 ms-2">
                          <input
                            id="other_feedback_required"
                            className="form-check-input"
                            type="radio"
                            name="other_feedback_required"
                            value={value.id.toString()} // Convert boolean to string
                            checked={
                              values.other_feedback_required === value.id
                            }
                            onChange={() =>
                              setFieldValue("other_feedback_required", value.id)
                            }
                          />
                          <span className="form-check-label fs-5 mb-0 ms-2">
                            {value.name}
                          </span>
                        </label>
                      </div>
                    ))}
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="other_feedback_required" />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <button
                      type="button"
                      className="btn btn-secondary me-4 fs-4"
                      onClick={() =>
                        setFieldValue("questions", [
                          ...values.questions,
                          initialQuestion,
                        ])
                      }
                    >
                      Add Question
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary me-4 fs-4 float-end"
                    >
                      {loading && (
                        <span
                          className="indicator-progress"
                          style={{display: "block"}}
                        >
                          Please Wait..
                          <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                        </span>
                      )}
                      {!loading && (
                        <span className="indicator-label">ADD SURVEY</span>
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )}
        </Formik>

        <Toaster
          position="bottom-left"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            className: "",
            duration: 3000,
          }}
        />
      </div>
    </div>
  );
}

export default CreateSurvey;

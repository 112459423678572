import  { Suspense, useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import TotalBlendsChart from "./TotalBlendsChart";
import FlavorWiseBlendChart from "./FlavorWiseBlendChart";
import LocationWiseBlendChart from "./LocationWiseBlendChart";
import MachineWiseBlendChart from "./MachineWiseBlendChart";
import FeedBack from "./FeedBack";
import { useParams } from "react-router-dom";
import {
  getEventById,
  getFlavorWiseBlendsByEventId,
  getLocationWiseBlendsByEventId,
  getMachineWiseBlendsByEventId,
  getTotalBlendsByEventId,
} from "../../../../models/_event";
import Moment from "moment";

function ViewSummary() {
  const { eventId } = useParams();
  const [totalBlendsData, setTotalBlendsData] = useState<any>([]);
  const [flavorWiseBlendsData, setFlavorWiseBlendsData] = useState<any>([]);
  const [locationWiseBlendsData, setLocationWiseBlendsData] = useState<any>([]);
  const [machineWiseBlendsData, setMachineWiseBlendsData] = useState<any>([]);
  const [eventInfo, setEventInfo] = useState<any>([]);


  const fetchEventSummaryData = async (eventId: string) => {
    const eventData = await getEventById(eventId);
    setEventInfo(eventData)

    const [
      totalBlendsData,
      flavorWiseBlendsData,
      locationWiseBlendsData,
      machineWiseBlendsData,
    ] = await Promise.all([
      getTotalBlendsByEventId(eventId as string),
      getFlavorWiseBlendsByEventId(eventId as string),
      getLocationWiseBlendsByEventId(eventId as string),
      getMachineWiseBlendsByEventId(eventId as string),
    ]);

    if (totalBlendsData) {
      const totalBlendsArray = [
        { day: "Previous Period", count: totalBlendsData?.previousBlends },
        { day: "Current Period", count: totalBlendsData?.currentBlends },
      ];

      setTotalBlendsData(totalBlendsArray);
    }
    flavorWiseBlendsData
      ? setFlavorWiseBlendsData(flavorWiseBlendsData)
      : setFlavorWiseBlendsData([]);
    locationWiseBlendsData
      ? setLocationWiseBlendsData(locationWiseBlendsData)
      : setLocationWiseBlendsData([]);
    machineWiseBlendsData
      ? setMachineWiseBlendsData(machineWiseBlendsData)
      : setMachineWiseBlendsData([]);
  };

  useEffect(() => {
    if (eventId) {
      fetchEventSummaryData(eventId);
    }
  }, [eventId]);

  return (
    <Suspense>
      <div>
        <div id="kt_app_toolbar" className="app-toolbar py-2 pt-4">
          <div
            id="kt_app_toolbar_container"
            className="app-container container-xxl d-block d-lg-flex d-md-flex d-xl-flex flex-stack px-3"
            style={{ maxWidth: "100%" }}
          >
            <div className="page-title d-flex flex-column justify-content-center flex-wrap">
              <h1 className="page-heading d-flex text-dark fw-bold fs-1 flex-column justify-content-center my-0">
                View Event Summary
              </h1>
            </div>
            <div className="d-flex align-items-center gap-2 gap-lg-3 mt-3 mt-lg-0 mt-md-0">
              <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <li className="breadcrumb-item text-muted">
                  <a href="/" className="text-muted text-hover-primary fs-5">
                    Home
                  </a>
                </li>
                <li className="breadcrumb-item text-muted">
                  <span className="bullet bg-gray-400 w-5px h-2px" />
                  <a
                    href="/masters/events/list"
                    className="text-muted text-hover-primary ps-2 fs-5"
                  >
                    Event List
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <span className="bullet bg-gray-400 w-5px h-2px" />
                </li>
                <li className="breadcrumb-item fs-5 text-dark">
                  View Event Summary
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="mx-xl-4 mt-9">
          <div className="card">
            <div className="card-header cursor-pointer border-bottom-0 py-4">
              <div className="card-title m-0">
                <h3 className="fw-semibold m-0"><b>{eventInfo?.title}</b> ({Moment.utc(eventInfo?.startDate).local().format("YYYY-MM-DD HH:mm:ss")} - {Moment.utc(eventInfo?.endDate).local().format("YYYY-MM-DD HH:mm:ss")})</h3>
              </div>
            </div>
          </div>
        </div>

        <div className="mx-xl-4 mt-9">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12 col-12">
              <div className="card">
                <div className="card-body">
                  <div className="p-3 border bg-light" id="chart1">
                    <h4 className="mt-2">Total Consumption</h4>
                    <TotalBlendsChart data={totalBlendsData} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-12">
              <div className="card">
                <div className="card-body">
                  <div className="p-3 border bg-light" id="chart1">
                    <h4 className="mt-2">Flavor Wise Consumption</h4>
                    <FlavorWiseBlendChart data={flavorWiseBlendsData} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-9">
            <div className="col-xl-6 col-lg-6 col-md-12 col-12">
              <div className="card">
                <div className="card-body">
                  <div className="p-3 border bg-light" id="chart1">
                    <h4 className="mt-2">Location Wise Consumption</h4>
                    <LocationWiseBlendChart data={locationWiseBlendsData} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-12">
              <div className="card">
                <div className="card-body">
                  <div className="p-3 border bg-light" id="chart1">
                    <h4 className="mt-2">Machine Wise Consumption</h4>
                    <MachineWiseBlendChart data={machineWiseBlendsData} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mx-xl-4 mt-9">
          <div className="card">
            <FeedBack eventId={eventId} />
          </div>
        </div>
      </div>
      <Toaster
        position="bottom-left"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          className: "",
          duration: 3000,
        }}
      />
    </Suspense>
  );
}

export default ViewSummary;

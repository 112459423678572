/**
 * @generated SignedSource<<05431b4282d97ed532b39c14fa0c386b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ConnectionStatus = "OFFLINE" | "ONLINE" | "%future added value";
export type ViewStatusModel_getMachineDeviceStatusLogQuery$variables = {
  machineId: string;
};
export type ViewStatusModel_getMachineDeviceStatusLogQuery$data = {
  readonly getMachineDeviceStatusLog: ReadonlyArray<{
    readonly blenderActBottomPos: number | null;
    readonly blenderActBottomPosResult: boolean | null;
    readonly blenderActMaxCur: number | null;
    readonly blenderActMaxCurResult: boolean | null;
    readonly blenderActMaxCurTravel: number | null;
    readonly blenderActMaxCurTravelResult: boolean | null;
    readonly blenderActTopPos: number | null;
    readonly blenderActTopPosResult: boolean | null;
    readonly blenderMotorCur: number | null;
    readonly blenderMotorCurRatio: number | null;
    readonly blenderMotorCurRatioResult: boolean | null;
    readonly blenderMotorCurResult: boolean | null;
    readonly cleanValveCur: number | null;
    readonly cleanValveCurResult: boolean | null;
    readonly cleanValveWaterFlowRate: number | null;
    readonly createdAt: any;
    readonly cupSensorBlkdVal: number | null;
    readonly cupSensorBlkdValResult: boolean | null;
    readonly cupSensorCupDiffVal: number | null;
    readonly cupSensorCupDiffValResult: boolean | null;
    readonly cupSensorUnBlkVal: number | null;
    readonly cupSensorUnBlkValResult: boolean | null;
    readonly doorActBottomPos: number | null;
    readonly doorActBottomPosResult: boolean | null;
    readonly doorActMaxCur: number | null;
    readonly doorActMaxCurResult: boolean | null;
    readonly doorActTopPos: number | null;
    readonly doorActTopPosResult: boolean | null;
    readonly fillValveCur: number | null;
    readonly fillValveCurResult: boolean | null;
    readonly id: string;
    readonly machine: {
      readonly blenderId: string | null;
      readonly boardId: string | null;
      readonly connectionStatus: ConnectionStatus | null;
      readonly createdAt: any | null;
      readonly createdById: string | null;
      readonly deployingPersonId: string | null;
      readonly deploymentDate: string;
      readonly hardwareVersion: string | null;
      readonly id: string;
      readonly isActive: boolean;
      readonly isPilot: boolean;
      readonly isRetired: boolean;
      readonly lastHearbeatSyncAt: any | null;
      readonly lastSanitizeTime: any | null;
      readonly location: {
        readonly name: string;
      } | null;
      readonly locationId: string | null;
      readonly macAddress: string | null;
      readonly name: string | null;
      readonly noteRelatedToMechanicalHardwareVersion: string | null;
      readonly remark: string | null;
      readonly replaceNewTubeDoors1mmShorter: boolean;
      readonly retiredAt: any | null;
      readonly retiredById: string | null;
      readonly serialNumber: string;
      readonly softwareVersion: string | null;
      readonly trialEndDate: string | null;
      readonly updatedAt: any | null;
      readonly updatedById: string | null;
      readonly uuid: string | null;
    } | null;
    readonly machineId: string;
    readonly misc12v: number | null;
    readonly misc12vResult: boolean | null;
    readonly misc5v: number | null;
    readonly misc5vResult: boolean | null;
    readonly miscTemp: number | null;
    readonly miscTempResult: boolean | null;
    readonly peelSealBlenderLower: number | null;
    readonly peelSealBlenderLowerResult: boolean | null;
    readonly peelSealOpenDoor: number | null;
    readonly peelSealOpenDoorResult: boolean | null;
    readonly pumpCur: number | null;
    readonly pumpCurResult: boolean | null;
    readonly reportDateAndTime: any;
    readonly waterFlowRate: number | null;
  }>;
};
export type ViewStatusModel_getMachineDeviceStatusLogQuery = {
  response: ViewStatusModel_getMachineDeviceStatusLogQuery$data;
  variables: ViewStatusModel_getMachineDeviceStatusLogQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "machineId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "machineId",
        "variableName": "machineId"
      }
    ],
    "concreteType": "MachineDeviceStatusObjectType",
    "kind": "LinkedField",
    "name": "getMachineDeviceStatusLog",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "reportDateAndTime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "machineId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "MachineObjectType",
        "kind": "LinkedField",
        "name": "machine",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "uuid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "serialNumber",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "macAddress",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deploymentDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "locationId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deployingPersonId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "softwareVersion",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hardwareVersion",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "connectionStatus",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdById",
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedById",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isActive",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "remark",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastHearbeatSyncAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastSanitizeTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "retiredAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "retiredById",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isRetired",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "blenderId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "boardId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "replaceNewTubeDoors1mmShorter",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "noteRelatedToMechanicalHardwareVersion",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isPilot",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "trialEndDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "LocationObjectType",
            "kind": "LinkedField",
            "name": "location",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "blenderActBottomPos",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "blenderActMaxCur",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "blenderActMaxCurTravel",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "blenderActTopPos",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "blenderMotorCur",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cleanValveCur",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cupSensorBlkdVal",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cupSensorCupDiffVal",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cupSensorUnBlkVal",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "doorActBottomPos",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "doorActMaxCur",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "doorActTopPos",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "fillValveCur",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "misc12v",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "misc5v",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "miscTemp",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "peelSealBlenderLower",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "peelSealOpenDoor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "pumpCur",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "blenderActBottomPosResult",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "blenderActMaxCurResult",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "blenderActMaxCurTravelResult",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "blenderActTopPosResult",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "blenderMotorCurResult",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cleanValveCurResult",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cupSensorBlkdValResult",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cupSensorCupDiffValResult",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cupSensorUnBlkValResult",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "doorActBottomPosResult",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "doorActMaxCurResult",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "doorActTopPosResult",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "fillValveCurResult",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "misc12vResult",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "misc5vResult",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "miscTempResult",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "peelSealBlenderLowerResult",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "peelSealOpenDoorResult",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "pumpCurResult",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "blenderMotorCurRatio",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "blenderMotorCurRatioResult",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "waterFlowRate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cleanValveWaterFlowRate",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ViewStatusModel_getMachineDeviceStatusLogQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ViewStatusModel_getMachineDeviceStatusLogQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "6df7c38d4273c959368c0cc279bdb6c2",
    "id": null,
    "metadata": {},
    "name": "ViewStatusModel_getMachineDeviceStatusLogQuery",
    "operationKind": "query",
    "text": "query ViewStatusModel_getMachineDeviceStatusLogQuery(\n  $machineId: String!\n) {\n  getMachineDeviceStatusLog(machineId: $machineId) {\n    id\n    reportDateAndTime\n    machineId\n    machine {\n      id\n      uuid\n      serialNumber\n      macAddress\n      name\n      deploymentDate\n      locationId\n      deployingPersonId\n      softwareVersion\n      hardwareVersion\n      connectionStatus\n      createdById\n      createdAt\n      updatedById\n      updatedAt\n      isActive\n      remark\n      lastHearbeatSyncAt\n      lastSanitizeTime\n      retiredAt\n      retiredById\n      isRetired\n      blenderId\n      boardId\n      replaceNewTubeDoors1mmShorter\n      noteRelatedToMechanicalHardwareVersion\n      isPilot\n      trialEndDate\n      location {\n        name\n      }\n    }\n    createdAt\n    blenderActBottomPos\n    blenderActMaxCur\n    blenderActMaxCurTravel\n    blenderActTopPos\n    blenderMotorCur\n    cleanValveCur\n    cupSensorBlkdVal\n    cupSensorCupDiffVal\n    cupSensorUnBlkVal\n    doorActBottomPos\n    doorActMaxCur\n    doorActTopPos\n    fillValveCur\n    misc12v\n    misc5v\n    miscTemp\n    peelSealBlenderLower\n    peelSealOpenDoor\n    pumpCur\n    blenderActBottomPosResult\n    blenderActMaxCurResult\n    blenderActMaxCurTravelResult\n    blenderActTopPosResult\n    blenderMotorCurResult\n    cleanValveCurResult\n    cupSensorBlkdValResult\n    cupSensorCupDiffValResult\n    cupSensorUnBlkValResult\n    doorActBottomPosResult\n    doorActMaxCurResult\n    doorActTopPosResult\n    fillValveCurResult\n    misc12vResult\n    misc5vResult\n    miscTempResult\n    peelSealBlenderLowerResult\n    peelSealOpenDoorResult\n    pumpCurResult\n    blenderMotorCurRatio\n    blenderMotorCurRatioResult\n    waterFlowRate\n    cleanValveWaterFlowRate\n  }\n}\n"
  }
};
})();

(node as any).hash = "a755c5329805daf84a59b8eec752937d";

export default node;

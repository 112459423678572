import "jquery/dist/jquery.min.js";
import "react-datetime/css/react-datetime.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import clsx from "clsx";
import Moment from "moment";
import Datetime from "react-datetime";
import {Toaster} from "react-hot-toast";
import {EditSurvey} from "./EditSurvey";
import {Component, createRef} from "react";
import {ViewQuestion} from "./ViewQuestions";
import {KTSVG} from "../../../../_metronic/helpers";
import {getSurveyById} from "../../../models/_survey";
import {AUTH_LOCAL_STORAGE_KEY} from "../../../../app/modules/auth";

export default class SurveyList extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      addLoading: false,
      filterLoading: false,
      filterFromDate: "",
      resetFromDate: createRef(),
      filterToDate: "",
      resetToDate: createRef(),
      filterTitle: "",
      resetStatus: createRef(),
      surveyEditModal: false,
      currentSurveyId: "",
      currentlyOpenRecord: {},
    };
  }

  async componentWillMount() {}
  async componentDidUpdate() {}
  async componentDidMount() {
    var _ = this;

    $("body").on("click", ".editBtn", async function () {
      const surveyId: any = $(this).attr("data-recordId");
      const surveyData = await getSurveyById(surveyId);

      _.setState({
        surveyEditModal: true,
        currentSurveyId: surveyId,
        currentlyOpenRecord: surveyData,
      });
    });

    $("body").on("click", ".viewQuestion", async function () {
      const surveyId: any = $(this).attr("data-recordId");
      const surveyData = await getSurveyById(surveyId);

      _.setState({
        viewQuestionModal: true,
        currentSurveyId: surveyId,
        currentlyOpenRecord: surveyData,
      });
    });

    await this.getFilter();
    this.renderDataTable([]);
  }

  getFilter = () => {
    let filter: any = {};
    if (this.state.filterTitle) {
      filter["title"] = {like: "%" + this.state.filterTitle + "%"};
    }
    if (this.state.filterFromDate !== "" || this.state.filterToDate !== "") {
      filter["created_at"] = {};
    }
    if (this.state.filterFromDate !== "" && this.state.filterToDate === "") {
      filter["created_at"]["gt"] = new Date(
        this.state.filterFromDate
      ).toISOString();
    } else if (
      this.state.filterFromDate === "" &&
      this.state.filterToDate !== ""
    ) {
      filter["created_at"]["lt"] = new Date(
        this.state.filterToDate
      ).toISOString();
    } else if (
      this.state.filterFromDate !== "" &&
      this.state.filterToDate !== ""
    ) {
      filter["created_at"]["between"] = [
        new Date(this.state.filterFromDate).toISOString(),
        new Date(this.state.filterToDate).toISOString(),
      ];
    }
    return filter;
  };

  renderDataTable = (rows: any) => {
    const getPermissions: any = localStorage.getItem("permissions");
    const permissions = getPermissions || [];
    const filter = this.getFilter();
    const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
    let authToken = "";
    if (lsValue) {
      const user = JSON.parse(lsValue);
      authToken = user.access_token;
    }
    $(document).ready(function () {
      $("#surveyTable").DataTable().destroy();
      $("#surveyTable").DataTable({
        pagingType: "full_numbers",
        serverSide: true,
        pageLength: 50,
        paging: true,
        searching: false,
        ajax: {
          url: process.env.REACT_APP_API_URL,
          type: "POST",
          contentType: "application/json",
          beforeSend: function (xhr) {
            xhr.setRequestHeader("Authorization", "Bearer " + authToken);
          },
          data: function (d: any) {
            var gql = `
              query getSurvey(
                $where:SurveyResolver_GetSurvey_FilterInputType
                $paginate:PaginatorArgs
              ) {
                getSurvey(where: $where, ,paginate: $paginate) {
                  data {
                    id
                    title
                    questions {
                    id
                    surveyId
                    answerType
                    question
                    fromRange
                    toRange
                    startLabel
                    endLabel
                    answers
                    isMultiAnswer
                    createdAt
                    updatedAt
                    } 
                    otherFeedbackRequired
                    createdAt
                    updatedAt
                    createdById
                    updatedById
                  }
                  totalPages
                  total
                  perPage
                  page
                }
              }
            `;
            const currentPage = d?.start ? d.start / d.length + 1 : 1;
            const length = d?.length > 0 ? d.length : 50;
            var query = {
              operationName: null,
              query: gql,
              variables: {
                where: filter,
                paginate: {
                  page: currentPage,
                  per_page: length,
                },
              },
            };
            return JSON.stringify(query);
          },
          dataSrc: function (json) {
            json.recordsTotal = json.data.getSurvey.total;
            json.recordsFiltered = json.data.getSurvey.total;
            const rows: any[] = [];
            const records = json.data.getSurvey.data;
            let counter = 0;
            for (const record of records) {
              counter++;
              let createdAt = "";
              if (record.createdAt) {
                createdAt = Moment.utc(record.createdAt)
                  .local()
                  .format("YYYY-MM-DD HH:mm:ss");
              }
              const tempRecord: any = {
                id: counter,
                recordId: record.id,
                title: record.title,
                questions: record.questions,
                createdAt,
                otherFeedbackRequired: record.otherFeedbackRequired,
                record: record,
              };
              rows.push(tempRecord);
            }
            return rows;
          },
        },
        columns: [
          {
            name: "index",
            render: function (data, type, row) {
              return row.id;
            },
          },
          {
            name: "title",
            render: function (data, type, row) {
              return row.title;
            },
          },
          {
            name: "otherFeedbackRequired",
            render: function (data, type, row) {
              return row.otherFeedbackRequired ? "Yes" : "No";
            },
          },
          {
            name: "createdAt",
            render: function (data, type, row) {
              return row.createdAt;
            },
          },
          {
            name: "action",
            render: function (data, type, row) {
              let returnData = "";
              if (permissions?.includes("update-survey") === true) {
                returnData += `
                  <div data-bs-toggle="tooltip" title="Click here to edit Survey">
                    <button class="btn btn-secondary btn editBtn p-2 px-4 m-1" data-recordId="${row.recordId}">
                      <i class='fa fa-pencil' style='font-size:1rem;'></i>
                    </button>
                  </div>
                `;
              }
              returnData += `
                <div data-bs-toggle="tooltip" title="Click here to view survey">
                  <button class="btn btn-primary viewQuestion px-4 p-2 m-1" data-recordId="${row.recordId}">
                    <i class='fa fa-eye' style='font-size:1rem;'></i>
                  </button>
                </div>
              `;
              return returnData;
            },
          },
        ],
        processing: true,
        dom: "Bfrtip",
        order: [[0, "desc"]],
        lengthMenu: [
          [10, 20, 30, 50, -1],
          [10, 20, 30, 50, "All"],
        ],
      });
    });
  };

  handleSurveyClose = () => {
    this.setState({
      surveyEditModal: false,
      currentSurveyId: "",
      currentlyOpenRecord: {},
    });
  };
  handleViewQuestionClose = () => {
    this.setState({
      viewQuestionModal: false,
      currentSurveyId: "",
      currentlyOpenRecord: {},
    });
  };

  refreshTable = async () => {
    this.renderDataTable([]);
  };

  searchData = async () => {
    this.setState({
      addLoading: true,
    });
    this.renderDataTable([]);
    this.setState({
      addLoading: false,
    });
    return false;
  };

  removeSearch = async () => {
    this.setState({
      filterLoading: true,
    });
    // eslint-disable-next-line
    this.state.resetFromDate.current.state.inputValue = "";
    // eslint-disable-next-line
    this.state.resetToDate.current.state.inputValue = "";
    this.setState({
      filterFromDate: "",
      filterToDate: "",
      filterTitle: "",
    });
    await this.getFilter();
    this.renderDataTable([]);
    this.setState({
      filterLoading: false,
    });
    return false;
  };

  render() {
    return (
      <div>
        {/* breadcrumbs */}
        <div id="kt_app_toolbar" className="app-toolbar py-2 pt-4">
          <div
            id="kt_app_toolbar_container"
            className="app-container container-xxl d-block d-lg-flex d-md-flex d-xl-flex flex-stack px-3"
            style={{maxWidth: "100%"}}
          >
            <div className="page-title d-flex flex-column justify-content-center flex-wrap">
              <h1 className="page-heading d-flex text-dark fw-bold fs-1 flex-column justify-content-center my-0">
                Survey List
              </h1>
            </div>
            <div className="d-flex align-items-center gap-2 gap-lg-3 mt-3 mt-lg-0 mt-md-0">
              <button
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#survey-filter"
                aria-expanded="false"
                aria-controls="survey-filter"
                className="btn btn-bg-light collapsed fw-bold m-0 p-0 px-5 py-3 align-items-center card-title text-center"
              >
                <KTSVG
                  path="/media/icons/duotune/general/gen031.svg"
                  className="svg-icon-4 me-1"
                />
                Filter
              </button>
              <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <li className="breadcrumb-item text-muted">
                  <a href="/" className="text-muted text-hover-primary fs-5">
                    Home
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <span className="bullet bg-gray-400 w-5px h-2px" />
                </li>
                <li className="breadcrumb-item text-dark fs-5">Survey List</li>
              </ul>
            </div>
          </div>
        </div>
        <div
          className="collapse"
          id="survey-filter"
          aria-labelledby="survey-filter"
        >
          <div className="card mt-9">
            <div className="card-body w-100 p-lg-7 p-md-7 p-5">
              <div className="row mt-6">
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">
                    Created At (From Date)
                  </label>
                  <Datetime
                    ref={this.state.resetFromDate}
                    closeOnSelect
                    dateFormat="YYYY-MM-DD"
                    timeFormat="HH:mm"
                    initialValue={this.state.filterFromDate}
                    onChange={(e: any) => {
                      const dt = e["_d"];
                      this.setState({
                        filterFromDate: dt,
                      });
                    }}
                  />
                </div>

                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">
                    Created At (To Date)
                  </label>
                  <Datetime
                    ref={this.state.resetToDate}
                    closeOnSelect
                    dateFormat="YYYY-MM-DD"
                    timeFormat="HH:mm"
                    initialValue={this.state.filterToDate}
                    onChange={(e: any) => {
                      const dt = e["_d"];
                      this.setState({
                        filterToDate: dt,
                      });
                    }}
                  />
                </div>

                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">Title</label>
                  <input
                    autoComplete="off"
                    className={clsx("form-control")}
                    name="filterTitle"
                    value={this.state.filterTitle}
                    onChange={(event: any) => {
                      this.setState({
                        filterTitle: event.target.value,
                      });
                    }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                  <button
                    type="submit"
                    onClick={this.searchData}
                    className="btn btn-primary me-4 fs-4 p-0 w-100 px-6 py-3"
                  >
                    {this.state.addLoading && (
                      <span
                        className="indicator-progress"
                        style={{display: "block"}}
                      >
                        Please Wait..
                        <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                      </span>
                    )}
                    {!this.state.addLoading && (
                      <span className="indicator-label">ADD FILTER</span>
                    )}
                  </button>
                </div>

                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                  <button
                    type="submit"
                    onClick={this.removeSearch}
                    className="btn btn-primary me-4 fs-4 p-0 w-100 px-6 py-3"
                  >
                    {this.state.filterLoading && (
                      <span
                        className="indicator-progress"
                        style={{display: "block"}}
                      >
                        Please Wait..
                        <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                      </span>
                    )}
                    {!this.state.filterLoading && (
                      <span className="indicator-label">RESET FILTER</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card mt-9">
          {/* table */}
          <div className="card-body w-100 p-lg-7 p-md-7 p-5">
            <div className="row">
              <div className="col-12">
                <div className="table-responsive p-0 pb-2 fs-6" id="data-table">
                  <table
                    id="surveyTable"
                    className="table align-items-center display justify-content-center mb-0 w-100"
                  >
                    <thead>
                      <tr>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Sr No.
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Title
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Is Other Feedback Required?
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Created At
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Action
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div id="titleModal" className="modal">
            <div className="modal-content">
              <h4>Title Details</h4>
              <p id="modalTitleContent"></p>
            </div>
            <div className="modal-footer">
              <a href="#!" className="modal-close btn">
                Close
              </a>
            </div>
          </div>

          {this.state.surveyEditModal === true && (
            <EditSurvey
              currentSurveyId={this.state.currentSurveyId}
              currentlyOpenRecord={this.state.currentlyOpenRecord}
              surveyEditModal={this.state.surveyEditModal}
              handleSurveyClose={this.handleSurveyClose}
              refreshTable={this.refreshTable}
            />
          )}

          {this.state.viewQuestionModal === true && (
            <ViewQuestion
              currentlyOpenRecord={this.state.currentlyOpenRecord}
              viewQuestionModal={this.state.viewQuestionModal}
              handleViewQuestionClose={this.handleViewQuestionClose}
            />
          )}

          <Toaster
            position="bottom-left"
            reverseOrder={false}
            gutter={8}
            containerClassName=""
            containerStyle={{}}
            toastOptions={{
              className: "",
              duration: 3000,
            }}
          />
        </div>
      </div>
    );
  }
}

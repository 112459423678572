import  { FC, Suspense } from 'react'
import Modal from 'react-responsive-modal'

type Props = {
  currentlyOpenRecord: any;
  viewEventLocationModal: boolean;
  handleEventClose: () => void;
};

export const ViewEventLocations: FC<Props> = ({
  currentlyOpenRecord,
  viewEventLocationModal,
  handleEventClose,
}) => {
  return (
    <Suspense>
      <Modal
        open={viewEventLocationModal}
        onClose={() => handleEventClose()}
        role="modal"
        center
        classNames={{ root: "content-wrapper" }}
        styles={{
          modal: {
            width: '100%'
          }
        }}
      >
        <>
          <div className="modal-header p-2 pb-4 mb-4">
            <h4 className="modal-title fs-1">{currentlyOpenRecord?.title} (Locations)</h4>
          </div>
          <div className="modal-body">
            <div className="row ">
              <div className="row">
                <div className="col-12">
                  <ul className="fs-4">
                    {currentlyOpenRecord.locations.map((row: any, idx: number) => (
                      <li key={idx}>
                        {row?.name}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </>
      </Modal>
    </Suspense>
  )
}

import clsx from "clsx";
import { ErrorMessage } from "formik";
import Datetime from "react-datetime";
import { selectMachineDB } from "../WorkOrderHelper";
import SignaturePad from "react-signature-pad-wrapper";
import Select from "react-select";

const UserInfo = (props: {
  handleChange: any;
  handleBlur: any;
  values: any;
  setFieldValue: any;
  errors: any;
  touched: any;
  handleErrorCounter: any;
  resetDate: any;
  signatureError: any;
  submitClick: any;
  sigPad: any;
  clearSignature: any;
  machinesDB: any;
  machineOneSelected:any;
  machineTwoSelected:any;
  setMachineOneSelected:any;
  setMachineTwoSelected:any;
  resetMachineOne : any;
  resetMachineTwo:any;
  setIsMachineDelployedModalOpen: any;
}) => {
  const {
    handleChange,
    handleBlur,
    values,
    setFieldValue,
    errors,
    touched,
    handleErrorCounter,
    resetDate,
    signatureError,
    submitClick,
    sigPad,
    clearSignature,
    machinesDB,
    machineOneSelected,
    machineTwoSelected,
    setMachineOneSelected,
    setMachineTwoSelected,
    resetMachineOne,
    resetMachineTwo,
    setIsMachineDelployedModalOpen
  } = props;

  return (
    <>
      <div className="wo-form-group mt-15">
        <h3>General Comments</h3>
        <textarea
          id="comment"
          name="comment"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.comment}
          placeholder="Type hear..."
          className="form-control wo-textarea"
          rows={6}
        />
      </div>

      <div className="wo-form-group mt-5">
        <h3>Did you install a second machine?</h3>
        <div className="form-group">
          {Array.from(selectMachineDB).map((value: any, id) => (
            <div
              className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-flex mt-1 ms-2"
              key={id}
            >
              <label className="form-check-label fs-5 mb-0 ms-2" key={id}>
                <input
                  id="isSecondMachineInstalled"
                  className="form-check-input"
                  type="radio"
                  name="isSecondMachineInstalled"
                  value={value.id}
                  checked={values.isSecondMachineInstalled === value.id}
                  onChange={() =>
                    setFieldValue("isSecondMachineInstalled", value.id)
                  }
                />
                <span className="form-check-label fs-5 mb-0 ms-2">
                  {value.name}
                </span>
              </label>
            </div>
          ))}
        </div>
      </div>

      <div className="row mt-15">
        <div className="col-12 wo-form-group">
          <h3>Machine 1</h3>
            <Select
              ref= {resetMachineOne}
              className="react-select"
              classNamePrefix="my-react-select"
              placeholder=""
              name="machineId1"
              id="machineId1"
              defaultValue={machineOneSelected?.value}
              onChange={(selected: any) => {
                if(selected.isDeployed) setIsMachineDelployedModalOpen(true)
                setMachineOneSelected(selected);
                setFieldValue("machineId1",selected.value);
              }}
              options={Array.from(machinesDB).map(
                (value: any) => {
                  return {
                    value: value.id,
                    label: `${value.name ?? ""} (${value?.serialNumber ?? ""
                      })`,
                    isDeployed: value?.machineStatus?.name === "Deployed"
                  };
                }
              )}
            />
        </div>
      </div>

      {values.isSecondMachineInstalled === true && (
        <div className="row">
          <div className="col-12 wo-form-group">
            <h3>Machine 2</h3>
            <Select
              ref={resetMachineTwo}
              className="react-select"
              classNamePrefix="my-react-select"
              placeholder=""
              name="machineId2"
              id="machineId2"
              defaultValue={machineTwoSelected?.value}
              onChange={(selected: any) => {
                if(selected.isDeployed) setIsMachineDelployedModalOpen(true)
                setMachineTwoSelected(selected);
                setFieldValue("machineId2",selected.value);
              }}
              options={Array.from(machinesDB).map(
                (value: any) => {
                  return {
                    value: value.id,
                    label: `${value.location?.name ?? ""} (${value?.serialNumber ?? ""
                      })`,
                    isDeployed: value?.machineStatus?.name === "Deployed"
                  };
                }
              )}
            />
          </div>
        </div>
      )}

      <div className="row mt-15">
        <div className="col-6">
          <div
            className={clsx("row wo-form-group", {
              "error-box":
                Boolean(errors.firstName || errors.lastName) &&
                touched.firstName,
            })}
          >
            <h6 className="required">First Name & Last Name</h6>
            <div className="col-6">
              <input
                id="firstName"
                type="text"
                name="firstName"
                placeholder="First Name"
                onChange={(e) => {
                  setFieldValue("firstName",e.target.value);
                  values.firstName = e.target.value;
                  handleErrorCounter(values);
                }}
                onBlur={handleBlur}
                value={values.firstName}
                className={clsx("form-control", {
                  "error-input wo-input form-input":
                    Boolean(errors.firstName) && touched.firstName,
                })}
              />
            </div>
            <div className="col-6 lastName">
              <input
                id="lastName"
                type="text"
                name="lastName"
                placeholder="Last Name"
                onChange={(e) => {
                  setFieldValue("lastName",e.target.value);
                  values.lastName = e.target.value;
                  handleErrorCounter(values);
                }}
                onBlur={handleBlur}
                value={values.lastName}
                className={clsx("form-control", {
                  "error-input wo-input form-input":
                    Boolean(errors.lastName) && touched.lastName,
                })}
              />
            </div>
            <div
              className={clsx("", {
                "wo-error mt-2": Boolean(errors.firstName) && touched.firstName,
              })}
            >
              {Boolean(errors.firstName) && touched.firstName && (
                <>
                  <i
                    className="fa fa-exclamation-circle ms-2 error-icon"
                    aria-hidden="true"
                  ></i>{" "}
                  <ErrorMessage name="firstName" />
                </>
              )}
            </div>
            <div
              className={clsx("", {
                "wo-error mt-2": Boolean(errors.lastName) && touched.lastName,
              })}
            >
              {Boolean(errors.lastName) && touched.lastName && (
                <>
                  <i
                    className="fa fa-exclamation-circle ms-2 error-icon"
                    aria-hidden="true"
                  ></i>{" "}
                  <ErrorMessage name="lastName" />
                </>
              )}{" "}
            </div>
          </div>
        </div>

        <div className="col-6 date">
          <div
            className={clsx("wo-form-group", {
              "error-box": Boolean(errors.date) && touched.date,
            })}
          >
            <h6 className="required">Date</h6>

            <Datetime
              ref={resetDate}
              className={clsx("form-input", {
                "error-input": Boolean(errors.date) && touched.date,
              })}
              dateFormat="YYYY-MM-DD"
              timeFormat="HH:mm:ss"
              closeOnSelect
              value={values.date}
              onChange={(e: any) => {
                const dt = e["_d"];
                setFieldValue("date", dt);
                values.date = dt;
                handleErrorCounter(values);
              }}
            />
            <div
              className={clsx("", {
                "wo-error mt-4": Boolean(errors.date) && touched.date,
              })}
            >
              <i
                className="fa fa-exclamation-circle ms-5 me-1 error-icon"
                aria-hidden="true"
              ></i>
              <ErrorMessage name="date" />
            </div>
          </div>
        </div>
      </div>

      <div
        className={clsx("row mt-4 mb-8", {
          "error-box": Boolean(signatureError) && signatureError && submitClick,
        })}
      >
        <div className="col-6">
          <div className="form-group">
            <h3 className="required">Signature</h3>
            <div
              className={clsx("signature-box mt-4", {
                "error-input":
                  Boolean(signatureError) && signatureError && submitClick,
              })}
            >
              <SignaturePad
                ref={sigPad}
                options={{
                  dotSize: 1,
                  minWidth: 1,
                  maxWidth: 3,
                  penColor: "#81B942",
                  backgroundColor: "#FFFFFF",
                }}
              />
            </div>
          </div>
          <div className="powered-by float-end">
            <button
              type="button"
              className="btn btn-secondary ms-2"
              onClick={clearSignature}
              style={{
                background: "#e6effe",
                padding: "5px 15px",
              }}
            >
              Clear
            </button>
          </div>
        </div>

        {submitClick && signatureError ? (
          <div
            className={clsx("", {
              "wo-error mt-4": Boolean(signatureError) && signatureError,
            })}
          >
            <i
              className="fa fa-exclamation-circle ms-2 error-icon"
              aria-hidden="true"
            ></i>
            <span className="ms-2">Signature is Required</span>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default UserInfo;

import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

interface CreateWorkOrderInstallationStep {
  freezerInstalled: string;
  footerInstalled: string;
  sanitizerInstalled: string;
  headerInstalled: string;
  boosterBarInstalled: string;
  drainageInstalled: string;
  internetConnected: string;
  flavorTagsInstalled: string;
  serviceStickerInstalled: string;
  operatingPressures: string;
  oneTwoThreeStickersInstalled: string;
  palmTree: string;
  filledFreezer: string;
}

interface CreateWorkOrderTestPerformance {
  blendedFourSmoodies: string;
  sanitizer: string;
  getStatus: string;
}

interface CreateWorkOrderStaffTraining {
  makingSmoodies: string;
  benefitsOfSmoodi: string;
  accessingServiceMenu: string;
  cleaningWipers: string;
  checkingSanitizer: string;
}

interface CreateWorkOrderMaterialInstallation {
  boosterPump: string;
  benefitsOfSmoodi: string;
  accessingServiceMenu: string;
  cleaningWipers: string;
  checkingSanitizer: string;
}

interface CreateWorkOrderRequiredTask {
  action: string;
  priority: string;
  dueDate: Date;
}

interface createWorkOrderInput {
  deploymentManagerId: string;
  locationId: string;
  installerId: string | null;
  closeUpMedia: string[];
  storeSetUpMedia: string[];
  drainSetUpMedia: string[];
  serialNumberMedia: string[];
  comment: string;
  machineId1: string;
  isSecondMachineInstalled: Boolean;
  machineId2: string;
  firstName: string;
  lastName: string;
  installerName : string | null;
  date: Date;
  signature: string;
  installationSteps: CreateWorkOrderInstallationStep[];
  testPerformance: CreateWorkOrderTestPerformance[];
  staffTraining: CreateWorkOrderStaffTraining[];
  materialInstallation: CreateWorkOrderMaterialInstallation[];
  requiredTask: CreateWorkOrderRequiredTask[];
}

export async function createWorkOrder(input: createWorkOrderInput) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
          mutation createWorkOrder (
            $input: CreateWorkOrderInput!
          ) {
            createWorkOrder(
              createWorkOrderInput: $input
            ) {
              deploymentManagerId
              installerId
              locationId
              closeUpMedia
              storeSetUpMedia
              drainSetUpMedia
              serialNumberMedia
              comment
              machineId1
              isSecondMachineInstalled
              machineId2
              firstName
              lastName
              installerName
              date
              signature
              installationSteps {
                workOrderId
                freezerInstalled
                footerInstalled
                sanitizerInstalled
                headerInstalled
                boosterBarInstalled
                drainageInstalled
                internetConnected
                flavorTagsInstalled
                serviceStickerInstalled
                operatingPressures
                oneTwoThreeStickersInstalled
                palmTree
                filledFreezer
              }
              testPerformance {
                workOrderId
                blendedFourSmoodies
                sanitizer
                getStatus
                createdAt
              }
              staffTraining {
                workOrderId
                makingSmoodies
                benefitsOfSmoodi
                accessingServiceMenu
                cleaningWipers
                checkingSanitizer
                createdAt
              }
              materialInstallation {
                workOrderId
                boosterPump
                benefitsOfSmoodi
                accessingServiceMenu
                cleaningWipers
                checkingSanitizer
                createdAt
              }
              requiredTask {
                workOrderId
                action
                priority
                dueDate
                createdAt
              }
            }
          }
        `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

interface generateSignedUrlInput {
  file_name: string;
  file_type: string;
}

export async function generateSignedUrl(input: generateSignedUrlInput) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        mutation generateSignedUrl (
          $input: GenerateSignedUrlInput!
        ) {
          generateSignedUrl(
            generateSignedUrlInput: $input
          ) {
            file_name
            file_type
            url
          }
        }
      `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}
export async function getWorkOrders(where: {} = {}, pagination: {} | undefined = undefined,extraFilter:{} = {}) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
            query  getWorkOrders(  
              $where: WorkOrderResolver_GetWorkOrders_FilterInputType,
              $paginate:PaginatorArgs,
              $extraFilter: WorkOrderExtraFilterInput
              ) {
                  getWorkOrders(where: $where ,paginate: $paginate,extraFilter:$extraFilter) {
                      data {
                        id
                        deploymentManagerId
                        locationId
                        closeUpMedia
                        storeSetUpMedia
                        drainSetUpMedia
                        serialNumberMedia
                        comment
                        machineSerialNumber1
                        machineName1
                        machineSerialNumber2
                        machineName2
                        machineId1
                        isSecondMachineInstalled
                        machineId2
                        firstName
                        lastName
                        date
                        signature
                        createdAt
                        installationSteps{
                            workOrderId
                        freezerInstalled
                        footerInstalled
                        sanitizerInstalled
                        headerInstalled
                        boosterBarInstalled
                        drainageInstalled
                        internetConnected
                        flavorTagsInstalled
                        serviceStickerInstalled
                        operatingPressures
                        oneTwoThreeStickersInstalled
                        palmTree
                        filledFreezer
                        createdAt
                        }
                        testPerformance{
                        workOrderId
                        blendedFourSmoodies
                        sanitizer
                        getStatus
                        createdAt
                        }
                        staffTraining{
                            workOrderId
                        makingSmoodies
                        benefitsOfSmoodi
                        accessingServiceMenu
                        cleaningWipers
                        checkingSanitizer
                        createdAt
                        }
                        materialInstallation{
                        workOrderId
                        boosterPump
                        benefitsOfSmoodi
                        accessingServiceMenu
                        cleaningWipers
                        checkingSanitizer
                        createdAt
                        }
                        requiredTask{
                            workOrderId
                        action
                        priority
                        dueDate
                        createdAt
                        }
                        }
                        totalPages
                        total
                        perPage
                        page
                    }
            }
            `,
      variables: { where, paginate: pagination,extraFilter: extraFilter },
    },
  });
  return data?.data?.data?.getWorkOrders;
}
export async function getWorkOrderById(id: string) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        query($id:String!) {
          getWorkOrderById(id:$id) {
            id
            deploymentManagerId
            locationId
            closeUpMedia
            storeSetUpMedia
            drainSetUpMedia
            serialNumberMedia
            comment
            machineSerialNumber1
            machineName1
            machineSerialNumber2
            machineName2
            machineId1
            isSecondMachineInstalled
            machineId2
            firstName
            lastName
            date
            signature
            installationSteps {
              workOrderId
              freezerInstalled
              footerInstalled
              sanitizerInstalled
              headerInstalled
              boosterBarInstalled
              drainageInstalled
              internetConnected
              flavorTagsInstalled
              serviceStickerInstalled
              operatingPressures
              oneTwoThreeStickersInstalled
              palmTree
              filledFreezer
              createdAt
            }
            testPerformance {
              workOrderId
              blendedFourSmoodies
              sanitizer
              getStatus
              createdAt
            }
            staffTraining {
              workOrderId
              makingSmoodies
              benefitsOfSmoodi
              accessingServiceMenu
              cleaningWipers
              checkingSanitizer
              createdAt
            }
            materialInstallation {
              workOrderId
              boosterPump
              benefitsOfSmoodi
              accessingServiceMenu
              cleaningWipers
              checkingSanitizer
              createdAt
            }
            requiredTask {
              workOrderId
              action
              priority
              dueDate
              createdAt
            }
            createdAt
          }
        }
      `,
      variables: {
        id,
      },
    },
  });
  return data?.data?.data?.getWorkOrderById;
}

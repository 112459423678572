const TICKET_SOURCE = {
 "INTERNAL_ID": "cf2f7c30-f841-4478-a7ec-8da2990e72d5"   
}

const SERVICE_TYPE = {
    "REMOTE": "REMOTE"   
}

const TICKET_TYPE = {
    "LAB": "LAB"   
}

const ISSUE_REPORTED = {
    "STUCK_IN_CALIBRATION_ID": "0ede3c32-4a60-48ea-b15c-d34d11cbbe24"   
}


export {TICKET_SOURCE,SERVICE_TYPE,ISSUE_REPORTED,TICKET_TYPE}
import { useState } from 'react'
import { useLocation } from 'react-router-dom';

function useBlendSummaryHistory() {
    const location:any = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const blendSummaryId = searchParams.get('blend_summary_id');
    const fromDate = searchParams.get("from_date");
    const toDate = searchParams.get("to_date");
    const currentStatus = searchParams.get('current_status');
    const onlyVipCustomerStatus = searchParams.get('is_vip');
    const locationType = searchParams.get('location_type');
    const customerType = searchParams.get('customer_type');
    const machineType = searchParams.get('machine_type');
    const accountType = searchParams.get('account_type');
    const customersIds = searchParams.get('customer_id');
    const customers = customersIds ? customersIds.split(',').map((x)=>x) : "";
    const regionsIds = searchParams.get('region_id');
    const regions = regionsIds ? regionsIds.split(',').map((x)=>x) : "";
    const machineStatusIds = searchParams.get('deployment_type_id');
    const machineStatus = machineStatusIds ? machineStatusIds.split(',').map((x)=>x) : "";
    const locationTypeIds = searchParams.get('deployment_type_id');
    const locationTypes = locationTypeIds ? locationTypeIds.split(',').map((x)=>x) : "";
    const locationsIds = searchParams.get('location_id');
    const locations = locationsIds ? locationsIds.split(',').map((x)=>x) : "";
    const machinesIds = searchParams.get('machine_id');
    const machines = machinesIds ? machinesIds.split(',').map((x)=>x) : "";
    const marketSegmentIds = searchParams.get('market_segment_id');
    const marketSegments = marketSegmentIds ? marketSegmentIds.split(',').map((x)=>x) : "";
    const flavorIds = searchParams.get('flavor_id');
    const flavors = flavorIds ? flavorIds.split(',').map((x)=>x) : "";

    const [manageHistory,setManageHistory] = useState<any>({
        blendSummaryId,
        fromDate,
        toDate,
        currentStatus,
        onlyVipCustomerStatus,
        locationType,
        customerType,
        machineType,
        accountType,
        customers,
        locations,
        machines,
        marketSegments,
        flavors,
        regions,
        machineStatus,
        locationTypes,
    })

    // clear location search history
    if(manageHistory?.search === null){
        location.search = null
    }

  return [manageHistory,setManageHistory];
}

export default useBlendSummaryHistory;

import "jquery/dist/jquery.min.js";
import "react-datetime/css/react-datetime.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import clsx from "clsx";
import Moment from "moment";
import Datetime from "react-datetime";
import ReactSelect from "react-select";
import {EditEvent} from "./EditEvent";
import {Toaster} from "react-hot-toast";
import {Component, createRef} from "react";
import {KTSVG} from "../../../../_metronic/helpers";
import {getEventById} from "../../../models/_event";
import {getSurveyList} from "../../../models/_survey";
import {getLocations} from "../../../models/_location";
import {ViewEventLocations} from "./ViewEventLocations";
import {Option} from "../../../../_metronic/helpers/MySelect";
import {AUTH_LOCAL_STORAGE_KEY} from "../../../../app/modules/auth";

export default class EventList extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      addLoading: false,
      filterLoading: false,
      filterFromDate: "",
      resetFromDate: createRef(),
      filterToDate: "",
      resetToDate: createRef(),
      filterTitle: "",
      filterSurvey: [],
      filterVenue: "",
      filterLocation: [],
      filterStartDate: "",
      filterEndDate: "",
      surveyDB: [],
      locationDB: [],
      applyDateFilterOn: "",
      locationSelected: null,
      surveySelected: null,
      eventEditModal: false,
      viewEventLocationModal: false,
      currentEventId: "",
      currentlyOpenRecord: {},
    };
  }

  async componentWillMount() {}
  async componentDidUpdate() {}

  async componentDidMount() {
    var _ = this;

    $("body").on("click", ".editBtn", async function () {
      const eventId: any = $(this).attr("data-recordId");
      const eventData = await getEventById(eventId);
      _.setState({
        eventEditModal: true,
        currentEventId: eventId,
        currentlyOpenRecord: eventData,
      });
    });

    $("body").on("click", ".signatureBtn", async function () {
      const eventId: any = $(this).attr("data-recordId");
      const eventData = await getEventById(eventId);
      _.setState({
        viewEventLocationModal: true,
        currentEventId: eventId,
        currentlyOpenRecord: eventData,
      });
    });

    await this.getFilter();
    await this.getFilterRecords();
    this.renderDataTable([]);
  }

  getFilter = () => {
    let filter: any = {};
    if (this.state.filterTitle) {
      filter["title"] = {like: "%" + this.state.filterTitle + "%"};
    }
    if (this.state.filterVenue) {
      filter["venue"] = {like: "%" + this.state.filterVenue + "%"};
    }
    if (this.state.filterSurvey.length > 0) {
      filter["survey_id"] = {in: this.state.filterSurvey};
    }
    if (this.state.applyDateFilterOn !== "") {
      let dateFilterAppliedOnLabel = "";
      switch (this.state.applyDateFilterOn) {
        case "createdAt":
          dateFilterAppliedOnLabel = "created_at";
          break;
        case "startDate":
          dateFilterAppliedOnLabel = "start_date";
          break;
        case "endDate":
          dateFilterAppliedOnLabel = "end_date";
          break;
      }
      if (this.state.filterFromDate !== "" || this.state.filterToDate !== "") {
        filter[dateFilterAppliedOnLabel] = {};
      }
      if (this.state.filterFromDate !== "" && this.state.filterToDate === "") {
        filter[dateFilterAppliedOnLabel]["gt"] = new Date(
          this.state.filterFromDate
        ).toISOString();
      } else if (
        this.state.filterFromDate === "" &&
        this.state.filterToDate !== ""
      ) {
        filter[dateFilterAppliedOnLabel]["lt"] = new Date(
          this.state.filterToDate
        ).toISOString();
      } else if (
        this.state.filterFromDate !== "" &&
        this.state.filterToDate !== ""
      ) {
        filter[dateFilterAppliedOnLabel]["between"] = [
          new Date(this.state.filterFromDate).toISOString(),
          new Date(this.state.filterToDate).toISOString(),
        ];
      }
    }
    return filter;
  };

  renderDataTable = (rows: any) => {
    var _this = this;
    const getPermissions: any = localStorage.getItem("permissions");
    const permissions = getPermissions || [];
    const filter = this.getFilter();
    const filterLocation = this.state.filterLocation;
    const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
    let authToken = "";
    if (lsValue) {
      const user = JSON.parse(lsValue);
      authToken = user.access_token;
    }
    $(document).ready(function () {
      $("#eventTable").DataTable().destroy();
      $("#eventTable").DataTable({
        pagingType: "full_numbers",
        serverSide: true,
        pageLength: 50,
        paging: true,
        searching: false,
        ajax: {
          url: process.env.REACT_APP_API_URL,
          type: "POST",
          contentType: "application/json",
          beforeSend: function (xhr) {
            xhr.setRequestHeader("Authorization", "Bearer " + authToken);
          },
          data: function (d: any) {
            var gql = `
              query getEvent(
                $where:EventsResolver_GetEvent_FilterInputType
                $paginate:PaginatorArgs
                 $extraFilter: EventExtraFilterInputs
              ){
                getEvent(where: $where, ,paginate: $paginate, extraFilter:  $extraFilter) {
                  data {
                   id
                  title
                  description
                  venue
                  locations{
                    id
                    name
                  }
                   survey {
                     id
                      title
                    }
                  startDate
                  endDate
                  surveyId
                  formLink
                  formPin
                  createdAt
                  updatedAt
                  createdById
                  updatedById
                }
                totalPages
                total
                perPage
                page
                }
              }
            `;
            const currentPage = d?.start ? d.start / d.length + 1 : 1;
            const length = d?.length > 0 ? d.length : 50;
            var query = {
              operationName: null,
              query: gql,
              variables: {
                where: filter,
                paginate: {
                  page: currentPage,
                  per_page: length,
                },
                extraFilter: {
                  location_id: filterLocation,
                },
              },
            };
            return JSON.stringify(query);
          },
          dataSrc: function (json) {
            json.recordsTotal = json.data.getEvent.total;
            json.recordsFiltered = json.data.getEvent.total;
            const rows: any[] = [];
            const records = json.data.getEvent.data;
            let counter = 0;
            for (const record of records) {
              counter++;
              let createdAt = "";
              if (record.createdAt) {
                createdAt = Moment.utc(record.createdAt)
                  .local()
                  .format("YYYY-MM-DD HH:mm:ss");
              }
              let startDate = "";
              if (record.startDate) {
                startDate = Moment.utc(record.startDate)
                  .local()
                  .format("YYYY-MM-DD HH:mm:ss");
              }
              let endDate = "";
              if (record.endDate) {
                endDate = Moment.utc(record.endDate)
                  .local()
                  .format("YYYY-MM-DD HH:mm:ss");
              }
              const tempRecord: any = {
                id: counter,
                recordId: record.id,
                title: record.title,
                createdAt,
                description: record.description,
                venue: record.venue,
                startDate,
                endDate,
                surveyId: record.surveyId,
                formLink: record.formLink,
                formPin: record.formPin,
                record: record,
              };
              rows.push(tempRecord);
            }
            return rows;
          },
        },
        columns: [
          {
            name: "index",
            render: function (data, type, row) {
              return row.id;
            },
          },
          {
            name: "title",
            render: function (data, type, row) {
              return row.title;
            },
          },
          {
            name: "formLink",
            render: function (data, type, row) {
              if (row?.surveyId && row?.formLink) {
                let surveyLink = `${process.env.REACT_APP_SURVEY_APP_URL}survey?id=${row.recordId}`;
                return `
                  <div data-bs-toggle="tooltip" title="Click here to open survey form">
                    <a class="clickable_entry" target="_blank" href="${
                      surveyLink
                    }">${surveyLink.substring(0, 20)}...</a>
                  </div>
                `;
              }
              return "";
            },
          },
          {
            name: "formPin",
            render: function (data, type, row) {
              return row?.formPin || "";
            },
          },
          {
            name: "description",
            render: function (data, type, row) {
              return row?.description || "";
            },
          },
          {
            name: "venue",
            render: function (data, type, row) {
              return row?.venue || "";
            },
          },
          {
            name: "surveyId",
            render: function (data, type, row) {
              const survey = _this.state.surveyDB.find(
                (a: any) => row.surveyId === a.id
              );
              return survey?.title || "";
            },
          },
          {
            name: "startDate",
            render: function (data, type, row) {
              return row.startDate;
            },
          },
          {
            name: "endDate",
            render: function (data, type, row) {
              return row.endDate;
            },
          },
          {
            name: "createdAt",
            render: function (data, type, row) {
              return row.createdAt;
            },
          },
          {
            name: "action",
            render: function (data, type, row) {
              let returnData = "";
              if (
                permissions?.includes("update-events") === true &&
                new Date(row.startDate) > new Date()
              ) {
                returnData += `
                  <div data-bs-toggle="tooltip" title="Click here to edit event">
                    <button class="btn btn-secondary btn editBtn p-2 px-4 m-1" data-recordId="${row.recordId}">
                      <i class='fa fa-pencil' style='font-size:1rem;'></i>
                    </button>
                  </div>
                `;
              }
              returnData += `
                <div data-bs-toggle="tooltip" title="Click here to view locations">
                  <button class="btn btn-primary signatureBtn btn px-4 py-1 m-1" data-recordId="${row.recordId}">
                    <i class='fa fa-location' style='font-size:1rem;'></i>
                  </button>
                </div>
              `;
              returnData += `
                <div data-bs-toggle="tooltip" title="Click here to view Event summary">
                  <a class="btn btn-primary btn p-2 px-4 m-1" href="/masters/events/list/history/${row.recordId}">
                    <i class="fa fa-eye"></i>
                  </a>
                </div>
              `;
              return returnData;
            },
          },
        ],
        processing: true,
        dom: "Bfrtip",
        order: [[0, "desc"]],
        lengthMenu: [
          [10, 20, 30, 50, -1],
          [10, 20, 30, 50, "All"],
        ],
      });
    });
  };

  async getFilterRecords() {
    const surveyRecord = await getSurveyList();
    const locationRecord = await getLocations();
    const LocationData = locationRecord?.data;
    this.setState({
      surveyDB: surveyRecord,
      locationDB: LocationData,
    });
  }

  handleEventClose = () => {
    this.setState({
      eventEditModal: false,
      currentEventId: "",
      currentlyOpenRecord: {},
    });
  };

  handleViewLocationClose = () => {
    this.setState({
      viewEventLocationModal: false,
      currentEventId: "",
      currentlyOpenRecord: {},
    });
  };

  refreshTable = async () => {
    this.renderDataTable([]);
  };

  searchData = async () => {
    this.setState({
      addLoading: true,
    });
    this.renderDataTable([]);
    this.setState({
      addLoading: false,
    });
    return false;
  };

  removeSearch = async () => {
    this.setState({
      filterLoading: true,
    });
    // eslint-disable-next-line
    this.state.resetFromDate.current.state.inputValue = "";
    // eslint-disable-next-line
    this.state.resetToDate.current.state.inputValue = "";
    this.setState({
      filterFromDate: "",
      filterToDate: "",
      filterTitle: "",
      filterSurvey: "",
      filterVenue: "",
      filterLocation: [],
      filterStartDate: "",
      filterEndDate: "",
      surveySelected: null,
      locationSelected: null,
    });
    await this.getFilter();
    await this.getFilterRecords();
    this.renderDataTable([]);
    this.setState({
      filterLoading: false,
    });
    return false;
  };

  render() {
    return (
      <div>
        {/* breadcrumbs */}
        <div id="kt_app_toolbar" className="app-toolbar py-2 pt-4">
          <div
            id="kt_app_toolbar_container"
            className="app-container container-xxl d-block d-lg-flex d-md-flex d-xl-flex flex-stack px-3"
            style={{maxWidth: "100%"}}
          >
            <div className="page-title d-flex flex-column justify-content-center flex-wrap">
              <h1 className="page-heading d-flex text-dark fw-bold fs-1 flex-column justify-content-center my-0">
                Event List
              </h1>
            </div>
            <div className="d-flex align-items-center gap-2 gap-lg-3 mt-3 mt-lg-0 mt-md-0">
              <button
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#event-filter"
                aria-expanded="false"
                aria-controls="event-filter"
                className="btn btn-bg-light collapsed fw-bold m-0 p-0 px-5 py-3 align-items-center card-title text-center"
              >
                <KTSVG
                  path="/media/icons/duotune/general/gen031.svg"
                  className="svg-icon-4 me-1"
                />
                Filter
              </button>
              <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <li className="breadcrumb-item text-muted">
                  <a href="/" className="text-muted text-hover-primary fs-5">
                    Home
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <span className="bullet bg-gray-400 w-5px h-2px" />
                </li>
                <li className="breadcrumb-item text-dark fs-5">Event List</li>
              </ul>
            </div>
          </div>
        </div>

        <div
          className="collapse"
          id="event-filter"
          aria-labelledby="event-filter"
        >
          <div className="card mt-9">
            <div className="card-body w-100 p-lg-7 p-md-7 p-5">
              <div className="row mt-6">
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">
                    Apply Date filter on
                  </label>
                  <br />
                  <label
                    className="form-check form-check-sm form-check-custom form-check-solid align-items-center fs-5 mb-1 ms-2 "
                    key="apply_date_1"
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      name="applyDateFilterOn"
                      value="createdAt"
                      checked={this.state.applyDateFilterOn === "createdAt"}
                      onChange={() =>
                        this.setState({
                          applyDateFilterOn: "createdAt",
                        })
                      }
                    />
                    <span className="form-check-label fs-5 mb-0 ms-2">
                      Created At
                    </span>
                  </label>
                  <label
                    className="form-check form-check-sm form-check-custom form-check-solid align-items-center fs-5  mb-1 ms-2"
                    key="apply_date_2"
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      name="applyDateFilterOn"
                      value="startDate"
                      checked={this.state.applyDateFilterOn === "startDate"}
                      onChange={() =>
                        this.setState({
                          applyDateFilterOn: "startDate",
                        })
                      }
                    />
                    <span className="form-check-label fs-5 mb-0 ms-2">
                      Event Start Date
                    </span>
                  </label>
                  <label
                    className="form-check form-check-sm form-check-custom form-check-solid align-items-center fs-5  mb-1 ms-2"
                    key="apply_date_3"
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      name="applyDateFilterOn"
                      value="endDate"
                      checked={this.state.applyDateFilterOn === "endDate"}
                      onChange={() =>
                        this.setState({
                          applyDateFilterOn: "endDate",
                        })
                      }
                    />
                    <span className="form-check-label fs-5 mb-0 ms-2">
                      Event End date
                    </span>
                  </label>
                </div>

                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">From Date</label>
                  <Datetime
                    ref={this.state.resetFromDate}
                    closeOnSelect
                    dateFormat="YYYY-MM-DD"
                    timeFormat="HH:mm"
                    initialValue={this.state.filterFromDate}
                    onChange={(e: any) => {
                      const dt = e["_d"];
                      this.setState({
                        filterFromDate: dt,
                      });
                    }}
                  />
                </div>

                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">To Date</label>
                  <Datetime
                    ref={this.state.resetToDate}
                    closeOnSelect
                    dateFormat="YYYY-MM-DD"
                    timeFormat="HH:mm"
                    initialValue={this.state.filterToDate}
                    onChange={(e: any) => {
                      const dt = e["_d"];
                      this.setState({
                        filterToDate: dt,
                      });
                    }}
                  />
                </div>

                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">Title</label>
                  <input
                    autoComplete="off"
                    className={clsx("form-control")}
                    name="filterTitle"
                    value={this.state.filterTitle}
                    onChange={(event: any) => {
                      this.setState({
                        filterTitle: event.target.value,
                      });
                    }}
                  />
                </div>

                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">Venue</label>
                  <input
                    autoComplete="off"
                    className={clsx("form-control")}
                    name="filterVenue"
                    value={this.state.filterVenue}
                    onChange={(event: any) => {
                      this.setState({
                        filterVenue: event.target.value,
                      });
                    }}
                  />
                </div>

                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">Survey </label>
                  <ReactSelect
                    className="react-select"
                    classNamePrefix="my-react-select"
                    placeholder=""
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(selected: any) => {
                      const value = selected?.map((e: any) => e.value);
                      this.setState({
                        surveySelected: selected,
                        filterSurvey: value,
                      });
                    }}
                    components={{
                      Option,
                    }}
                    value={this.state.surveySelected}
                    options={Array.from(this.state.surveyDB).map(
                      (value: any) => {
                        return {
                          value: value.id,
                          label: value.title,
                        };
                      }
                    )}
                  />
                </div>

                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">Locations </label>
                  <ReactSelect
                    className="react-select"
                    classNamePrefix="my-react-select"
                    placeholder=""
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(selected: any) => {
                      const value = selected?.map((e: any) => e.value);
                      this.setState({
                        locationSelected: selected,
                        filterLocation: value,
                      });
                    }}
                    components={{
                      Option,
                    }}
                    value={this.state.locationSelected}
                    options={Array.from(this.state.locationDB).map(
                      (value: any) => {
                        return {
                          value: value.id,
                          label: value.name,
                        };
                      }
                    )}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                  <button
                    type="submit"
                    onClick={this.searchData}
                    className="btn btn-primary me-4 fs-4 p-0 w-100 px-6 py-3"
                  >
                    {this.state.addLoading && (
                      <span
                        className="indicator-progress"
                        style={{display: "block"}}
                      >
                        Please Wait..
                        <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                      </span>
                    )}
                    {!this.state.addLoading && (
                      <span className="indicator-label">ADD FILTER</span>
                    )}
                  </button>
                </div>

                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                  <button
                    type="submit"
                    onClick={this.removeSearch}
                    className="btn btn-primary me-4 fs-4 p-0 w-100 px-6 py-3"
                  >
                    {this.state.filterLoading && (
                      <span
                        className="indicator-progress"
                        style={{display: "block"}}
                      >
                        Please Wait..
                        <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                      </span>
                    )}
                    {!this.state.filterLoading && (
                      <span className="indicator-label">RESET FILTER</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card mt-9">
          {/* table */}
          <div className="card-body w-100 p-lg-7 p-md-7 p-5">
            <div className="row">
              <div className="col-12">
                <div className="table-responsive p-0 pb-2 fs-6" id="data-table">
                  <table
                    id="eventTable"
                    className="table align-items-center display justify-content-center mb-0 w-100"
                  >
                    <thead>
                      <tr>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Sr No.
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Title
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Form Link
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Form Pin
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Description
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          venue
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Survey
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Event Start Date
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Event End Date
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Created At
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Action
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </div>

          {this.state.eventEditModal === true && (
            <EditEvent
              currentEventId={this.state.currentEventId}
              currentlyOpenRecord={this.state.currentlyOpenRecord}
              eventEditModal={this.state.eventEditModal}
              handleEventClose={this.handleEventClose}
              refreshTable={this.refreshTable}
            />
          )}

          {this.state.viewEventLocationModal === true && (
            <ViewEventLocations
              currentlyOpenRecord={this.state.currentlyOpenRecord}
              viewEventLocationModal={this.state.viewEventLocationModal}
              handleEventClose={this.handleViewLocationClose}
            />
          )}

          <Toaster
            position="bottom-left"
            reverseOrder={false}
            gutter={8}
            containerClassName=""
            containerStyle={{}}
            toastOptions={{
              className: "",
              duration: 3000,
            }}
          />
        </div>
      </div>
    );
  }
}

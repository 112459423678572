import {
  radioButtonDB,
  initialQuestion,
  surveyQuestionTypes,
  surveyValidationSchema,
} from "./SurveyHelper";
import clsx from "clsx";
import Select from "react-select";
import {ErrorMessage} from "formik";
import {FieldArray, Formik} from "formik";
import Modal from "react-responsive-modal";
import {FC, useState, Suspense} from "react";
import {useNavigate} from "react-router-dom";
import toast, {Toaster} from "react-hot-toast";
import {updateSurvey} from "../../../models/_survey";

type Props = {
  currentSurveyId: string;
  currentlyOpenRecord: any;
  surveyEditModal: boolean;
  handleSurveyClose: () => void;
  refreshTable?: any;
};

export const EditSurvey: FC<Props> = ({
  currentSurveyId,
  currentlyOpenRecord,
  surveyEditModal,
  handleSurveyClose,
  refreshTable,
}) => {
  const navigate = useNavigate();
  if (!currentSurveyId || !currentlyOpenRecord) {
    navigate("/masters/survey/list");
  }
  const [loading, setLoading] = useState(false);

  return (
    <Suspense>
      <Modal
        open={surveyEditModal}
        onClose={() => handleSurveyClose()}
        role="modal"
        center
        classNames={{root: "content-wrapper"}}
      >
        <Formik
          enableReinitialize
          initialValues={{
            id: currentlyOpenRecord?.id,
            title: currentlyOpenRecord?.title,
            questions: currentlyOpenRecord?.questions.map((question: any) => ({
              question_id: question?.id || "",
              question: question.question,
              answers: question.answers?.map((x: any) => x.answer),
              is_multi_answer: question.isMultiAnswer,
              answer_type: question.answerType,
              from_range: question.fromRange,
              to_range: question.toRange,
              start_label: question.startLabel,
              end_label: question.endLabel,
            })),
            other_feedback_required: currentlyOpenRecord?.otherFeedbackRequired,
          }}
          validationSchema={surveyValidationSchema}
          onSubmit={async (values, {resetForm, setSubmitting}) => {
            setLoading(true);

            const updateData = {
              title: values.title,
              questions: values.questions,
              other_feedback_required: values.other_feedback_required,
            };
            try {
              const result = await updateSurvey(values.id, updateData);
              if (result?.data?.data?.updateSurvey) {
                toast.success("Survey updated successfully");
                handleSurveyClose();
                resetForm();
                if (refreshTable) {
                  refreshTable();
                }
              } else {
                const messages = result?.data?.errors.map(
                  (e: any) => e.message
                );
                toast.error(messages.join(","));
              }
            } catch (error) {
              console.error("Error updating survey:", error);
              toast.error("Failed to update survey");
            } finally {
              setLoading(false);
            }
          }}
        >
          {({handleChange, handleSubmit, values, setFieldValue}) => (
            <form
              id="kt_modal_add_lesson_form"
              className="form"
              onSubmit={handleSubmit}
              autoComplete="off"
              noValidate
            >
              <div className="modal-header p-2 pb-4 mb-4">
                <h4 className="modal-title fs-1">Edit Survey</h4>
              </div>

              <div className="modal-body">
                <div className="row">
                  <div className="col-12 mt-2 pt-2 w-100">
                    <label className="form-label fs-4 required fw-bold">
                      Title
                    </label>
                    <input
                      className={clsx("form-control")}
                      name="title"
                      id="title"
                      value={values.title}
                      onChange={handleChange}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="title" />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                      <label className="form-label fs-4 required fw-bold ps-1 mt-2">
                        Questions
                      </label>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                      <button
                        type="button"
                        className="btn btn-secondary me-4 fs-4 float-end"
                        onClick={() =>
                          setFieldValue("questions", [
                            ...values.questions,
                            initialQuestion,
                          ])
                        }
                      >
                        Add Question
                      </button>
                    </div>
                  </div>

                  <FieldArray name="questions">
                    {({push, remove}) => (
                      <>
                        {values.questions.map((question: any, index: any) => (
                          <div key={index} className="form-field">
                            <div className="question card border m-4">
                              <div>
                                <i
                                  className="fa fa-times float-end me-4 mt-2"
                                  onClick={() => remove(index)}
                                  aria-hidden="true"
                                ></i>
                              </div>
                              <div className="card-body w-100 p-4">
                                <div className="row mt-2 mb-6">
                                  <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                    <h5 className="card-title required">
                                      {index + 1}) Display Type
                                    </h5>
                                  </div>
                                  <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                                    <Select
                                      className="react-select"
                                      classNamePrefix="my-react-select"
                                      name={`questions[${index}].answer_type`}
                                      id={`questions[${index}].answer_type`}
                                      placeholder=""
                                      defaultValue={
                                        surveyQuestionTypes.find(
                                          (item) =>
                                            item.id === question.answer_type
                                        )
                                          ? {
                                              label:
                                                surveyQuestionTypes.find(
                                                  (item) =>
                                                    item.id ===
                                                    question.answer_type
                                                )?.name ?? "",
                                              value: question.answer_type,
                                            }
                                          : null
                                      }
                                      options={Array.from(
                                        surveyQuestionTypes
                                      ).map((value: any) => {
                                        return {
                                          value: value.id,
                                          label: value.name,
                                        };
                                      })}
                                      onChange={(e: any) => {
                                        console.log(e);
                                        setFieldValue(
                                          `questions[${index}].answer_type`,
                                          e.value
                                        );
                                      }}
                                    />
                                    <div className="text-danger text-align-top-center mt-2">
                                      <ErrorMessage
                                        name={`questions[${index}].answer_type`}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row mt-2 mb-6">
                                  <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                    <h5 className="card-title required">
                                      Question
                                    </h5>
                                  </div>
                                  <div className="col-xl-9 col-lg-9 col-md-9 col-12">
                                    <input
                                      className={clsx("form-control")}
                                      id={`questions[${index}].question`}
                                      name={`questions[${index}].question`}
                                      value={question.question}
                                      onChange={handleChange}
                                      placeholder="Enter question"
                                      type="text"
                                    />
                                    <div className="text-danger text-align-top-center mt-2">
                                      <ErrorMessage
                                        name={`questions[${index}].question`}
                                      />
                                    </div>
                                  </div>
                                </div>
                                {question.answer_type === "Button" && (
                                  <>
                                    <div className="row mt-2 mb-6">
                                      <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                        <h5 className="card-title required">
                                          Answers
                                        </h5>
                                      </div>
                                      <div className="col-xl-9 col-lg-9 col-md-9 col-12">
                                        <FieldArray
                                          name={`questions[${index}].answers`}
                                        >
                                          {({push, remove}) => {
                                            const answers =
                                              values.questions[index]
                                                ?.answers || [];
                                            return (
                                              <>
                                                <div className="d-flex flex-wrap">
                                                  {answers.map(
                                                    (
                                                      answer: any,
                                                      ansIndex: number
                                                    ) => (
                                                      <div
                                                        key={ansIndex}
                                                        className="form-group mt-2 d-flex align-items-center me-2"
                                                      >
                                                        <div className="d-flex flex-column">
                                                          <div className="input-group">
                                                            <label
                                                              className="ms-2 mt-4 me-2"
                                                              style={{
                                                                fontSize:
                                                                  "1rem",
                                                                fontWeight:
                                                                  "600",
                                                              }}
                                                            >
                                                              A{ansIndex + 1}
                                                            </label>
                                                            <input
                                                              className={clsx(
                                                                "form-control"
                                                              )}
                                                              name={`questions[${index}].answers[${ansIndex}]`}
                                                              value={answer}
                                                              onChange={(e) =>
                                                                setFieldValue(
                                                                  `questions[${index}].answers[${ansIndex}]`,
                                                                  e.target.value
                                                                )
                                                              }
                                                              placeholder="Enter answer"
                                                              type="text"
                                                            />
                                                            {answers.length >
                                                              1 && (
                                                              <i
                                                                className="fa fa-times ms-2 mt-4 me-2"
                                                                onClick={() => {
                                                                  remove(
                                                                    ansIndex
                                                                  );
                                                                }}
                                                                aria-hidden="true"
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                              ></i>
                                                            )}
                                                          </div>
                                                          <div className="text-danger text-align-top-center mt-2">
                                                            <ErrorMessage
                                                              name={`questions[${index}].answers[${ansIndex}]`}
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    )
                                                  )}
                                                </div>
                                                <button
                                                  type="button"
                                                  className="btn btn-secondary mt-2"
                                                  onClick={() => push("")}
                                                >
                                                  Add Answer
                                                </button>
                                              </>
                                            );
                                          }}
                                        </FieldArray>
                                      </div>
                                    </div>

                                    <div className="row mt-2 mb-6">
                                      <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                        <h5 className="card-title required">
                                          Is Multi Answer?
                                        </h5>
                                      </div>
                                      <div className="col-xl-9 col-lg-9 col-md-9 col-12">
                                        {radioButtonDB.map((value: any) => (
                                          <div
                                            key={value.id}
                                            className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-inline-flex mt-1"
                                          >
                                            <label className="fs-5 mb-0">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name={`questions[${index}].is_multi_answer`}
                                                value={value.id.toString()}
                                                checked={
                                                  question.is_multi_answer ===
                                                  value.id
                                                }
                                                onChange={() =>
                                                  setFieldValue(
                                                    `questions[${index}].is_multi_answer`,
                                                    value.id
                                                  )
                                                }
                                              />
                                              <span className="form-check-label fs-5 mb-0 ms-2">
                                                {value.name}
                                              </span>
                                            </label>
                                          </div>
                                        ))}
                                        <div className="text-danger text-align-top-center mt-2">
                                          <ErrorMessage
                                            name={`questions[${index}].is_multi_answer`}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                                {(question.answer_type === "Numeric_Scale" ||
                                  question.answer_type === "Sliding_Scale") && (
                                  <>
                                    <div className="row mt-2 mb-6">
                                      <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                        <h5 className="card-title required">
                                          Range
                                        </h5>
                                      </div>
                                      <div className="col-xl-4 col-lg-4 col-md-6 col-12 p-2 ">
                                        <input
                                          className={clsx("form-control")}
                                          name={`questions[${index}].from_range`}
                                          id={`questions[${index}].from_range`}
                                          type="number"
                                          value={question.from_range}
                                          onChange={handleChange}
                                          placeholder="From Range"
                                        />
                                        <div className="text-danger text-align-top-center mt-2">
                                          <ErrorMessage
                                            name={`questions[${index}].from_range`}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-xl-4 col-lg-4 col-md-6 col-12 p-2 ">
                                        <input
                                          className={clsx("form-control")}
                                          name={`questions[${index}].to_range`}
                                          id={`questions[${index}].to_range`}
                                          type="number"
                                          value={question.to_range}
                                          onChange={handleChange}
                                          placeholder="To Range"
                                        />
                                        <div className="text-danger text-align-top-center mt-2">
                                          <ErrorMessage
                                            name={`questions[${index}].to_range`}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row mt-2 mb-6">
                                      <div className="col-xl-3 col-lg-3 col-md-3 col-12">
                                        <h5 className="card-title">
                                          Label
                                        </h5>
                                      </div>
                                      <div className="col-xl-4 col-lg-4 col-md-6 col-12 p-2">
                                        <input
                                          className={clsx("form-control")}
                                          name={`questions[${index}].start_label`}
                                          id={`questions[${index}].start_label`}
                                          placeholder="Start Label"
                                          value={question.start_label}
                                          onChange={handleChange}
                                        />
                                        <div className="text-danger text-align-top-center mt-2">
                                          <ErrorMessage
                                            name={`questions[${index}].start_label`}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-xl-4 col-lg-4 col-md-6 col-12 p-2">
                                        <input
                                          className={clsx("form-control")}
                                          name={`questions[${index}].end_label`}
                                          id={`questions[${index}].end_label`}
                                          placeholder="End Label"
                                          value={question.end_label}
                                          onChange={handleChange}
                                        />
                                        <div className="text-danger text-align-top-center mt-2">
                                          <ErrorMessage
                                            name={`questions[${index}].end_label`}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  </FieldArray>

                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                      <label className="form-label fs-4 required fw-bold ps-1">
                        Is Other Feedback Required?
                      </label>
                      {radioButtonDB.map((value: any) => (
                        <div
                          key={value.id}
                          className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-flex mt-1 ms-2"
                        >
                          <label className="form-check-label fs-5 mb-0 ms-2">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="other_feedback_required"
                              value={value.id.toString()}
                              checked={
                                values.other_feedback_required === value.id
                              }
                              onChange={() =>
                                setFieldValue(
                                  "other_feedback_required",
                                  value.id
                                )
                              }
                            />
                            <span className="form-check-label fs-5 mb-0 ms-2">
                              {value.name}
                            </span>
                          </label>
                        </div>
                      ))}
                      <div className="text-danger text-align-top-center mt-2">
                        <ErrorMessage name="other_feedback_required" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer mt-6 p-0 justify-content-start">
                <button
                  type="button"
                  className="btn btn-secondary me-4 fs-4 p-0 px-6 py-3 mt-6"
                  onClick={() =>
                    setFieldValue("questions", [
                      ...values.questions,
                      initialQuestion,
                    ])
                  }
                >
                  Add Question
                </button>
                <button
                  type="submit"
                  className="btn btn-primary me-4 fs-4 p-0 px-6 py-3 mt-6 m-auto"
                >
                  {loading ? (
                    <span
                      className="indicator-progress"
                      style={{display: "block"}}
                    >
                      Please Wait..
                      <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                    </span>
                  ) : (
                    <span className="indicator-label">UPDATE</span>
                  )}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </Modal>

      <Toaster
        position="bottom-left"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          className: "",
          duration: 3000,
        }}
      />
    </Suspense>
  );
};

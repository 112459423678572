import React, { useEffect, useState } from "react";
import changeLogs from "../../_metronic/helpers/change-logs.json";
import { KTSVG } from "../../_metronic/helpers";
import { getVideoTutorials } from "../models/_videoTutorials";

interface AccordionItemProps {
  title: string;
  content: any;
  isActive: boolean;
  onToggle: () => void;
}

function ChangeLogs() {
  const [activeIndex, setActiveIndex] = useState(null);
  const [changeLogDB, setChangeLogDB] = useState(changeLogs);
  const [videoTutorialDB, setVideoTutorialDB] = useState([]);

  useEffect(() => {
    const sortedData = changeLogs.sort((a, b) => b.index - a.index);
    setChangeLogDB(sortedData);
  }, [changeLogDB]);

  const videoTutorials = async () => {
    const result = await getVideoTutorials();
    setVideoTutorialDB(result);
  };

  useEffect(() => {
    videoTutorials();
  }, []);

  const handleToggle = (index: any) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  const AccordionItem: React.FC<AccordionItemProps> = ({
    title,
    content,
    isActive,
    onToggle,
  }) => {
    return (
      <div className={`accordion-item ${content?.index === 1 ? "" : "mb-10"}`}>
        <h2
          className="accordion-header"
          id={`heading-${title}`}
          style={{ cursor: "pointer" }}
        >
          <div onClick={onToggle} className="m-3">
            {isActive ? (
              <i className="fa fa-arrow-down" style={{ marginRight: "10px" }}></i>
            ) : (
              <i
                className="fa fa-arrow-right"
                style={{ marginRight: "10px" }}
              ></i>
            )}
            {title}
          </div>
        </h2>
        <div
          id={`collapse-${title}`}
          className={`accordion-collapse collapse ${isActive ? "show" : ""}`}
          aria-labelledby={`heading-${title}`}
          style={{ marginLeft: "28px" }}
        >
          <div className="accordion-body">
            <div key={content.index}>
              {content?.points?.new?.length > 0 ? (
                <div className="mb-6">
                  <h4 className="fs-6">New:</h4>
                  <ul>
                    {content.points.new.map((x: any, idx: any) => (
                      <li key={idx} className="fs-6">
                        {x}
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                ""
              )}

              {content?.points?.updates?.length > 0 ? (
                <div className="mb-6">
                  <h4 className="fs-6">Updates:</h4>
                  <ul>
                    {content.points.updates.map((y: any, idx: any) => (
                      <li key={idx} className="fs-6">
                        {y}
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                ""
              )}

              {content?.points?.fixes?.length > 0 ? (
                <div className="mb-6">
                  <h4 className="fs-6">Fixes:</h4>
                  <ul>
                    {content.points.fixes.map((z: any, idx: any) => (
                      <li key={idx} className="fs-6">
                        {z}
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                ""
              )}

              {content?.points?.video_links?.length > 0 ? (
                <div className="mb-6">
                  <h4 className="fs-6">Video Tutorials:</h4>
                  <ul>
                    {content.points.video_links.map((z: any, idx: any) => (
                      <li key={idx} className="fs-6">
                        <a href={z} target="_blank" rel="noreferrer">
                          {z}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div id="kt_app_toolbar" className="app-toolbar py-2 pt-4">
        <div
          id="kt_app_toolbar_container"
          className="app-container container-xxl d-block d-lg-flex d-md-flex d-xl-flex flex-stack px-3"
          style={{ maxWidth: "100%" }}
        >
          <div className="page-title d-flex flex-column justify-content-center flex-wrap">
            <h1 className="customerParentAccount page-heading d-flex text-dark fw-bold fs-1  my-0">
              Changelog
            </h1>
          </div>
          <div className="d-flex align-items-center gap-2 gap-lg-3 mt-3 mt-lg-0 mt-md-0 mt-3 mt-lg-0 mt-md-0">
            <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
              <li className="breadcrumb-item text-muted">
                <a href="/" className="text-muted text-hover-primary fs-5">
                  Home
                </a>
              </li>
              <li className="breadcrumb-item">
                <span className="bullet bg-gray-400 w-5px h-2px" />
              </li>
              <li className="breadcrumb-item text-dark fs-5">Change log</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="row">
        <div className={`${videoTutorialDB?.length > 0 ? 'col-xl-6 col-lg-6 col-md-6 col-12' : ''}`}>
          <div className="card mx-xl-4 mt-9">
            <div className="card-body w-100 p-lg-7 p-md-7 p-5">
              <div className="row align-items-center justify-content-between">
                <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                  <div className="d-flex">
                    <h3 style={{ margin: "auto 0" }}>
                      How to use service portal?
                    </h3>
                    <i
                      className="fa fa-arrow-right"
                      style={{ margin: "13px 13px" }}
                    ></i>
                    <a
                      href="/manual"
                      target="_blank"
                      type="button"
                      className="btn btn-bg-light collapsed fw-bold m-0 p-0 px-5 py-3 align-items-center card-title text-center"
                    >
                      <KTSVG
                        path="/media/icons/duotune/general/gen005.svg"
                        className="svg-icon-4 me-1"
                      />
                      View
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card mx-xl-4 mt-9">
            <div className="card-body w-100 p-lg-7 p-md-7 p-5">
              {changeLogDB.map((log, index) => (
                <AccordionItem
                  key={index}
                  title={`${log.version_no}${log?.release_date ? ` - ${log?.release_date}` : ""
                    }`}
                  content={log}
                  isActive={activeIndex === index}
                  onToggle={() => handleToggle(index)}
                />
              ))}
            </div>
          </div>
        </div>

        {videoTutorialDB?.length > 0 && (
          <div className="col-xl-6 col-lg-6 col-md-6 col-12">
            <div className="card mx-xl-4 mt-9">
              <div className="card-body w-100 p-lg-7 p-md-7 p-5">
                <div className="row align-items-center justify-content-between">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="d-flex">
                      <h3
                        style={{ margin: "auto 0" }}
                        className="m-0 p-0 px-5 py-3 align-items-center card-title text-center"
                      >
                        How to?
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mx-xl-4 mt-9">
              <div className="card-body w-100 p-lg-7 p-md-7 p-5">
                <div className="accordion" id="kt_accordion_1">
                  {videoTutorialDB.map((row: any, index) => (
                    <div className="accordion-item" key={index}>
                      <h2
                        className="accordion-header"
                        id={`kt_accordion_1_header_${index}`}
                      >
                        <button
                          className="accordion-button fs-4 fw-bold collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#kt_accordion_1_body_${index}`}
                          aria-expanded="false"
                          aria-controls={`kt_accordion_1_body_${index}`}
                        >
                          {row?.title}
                        </button>
                      </h2>
                      <div
                        id={`kt_accordion_1_body_${index}`}
                        className="accordion-collapse collapse"
                        aria-labelledby={`kt_accordion_1_header_${index}`}
                        data-bs-parent={`#kt_accordion_1`}
                      >
                        <div className="accordion-body">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: row.videoTranscript.replace(
                                /\n/g,
                                "<br />"
                              ),
                            }}
                          />
                          <div className="d-flex" style={{ marginTop: "15px", width: "100%" }}>
                            <iframe
                              src={row.videoUrl}
                              width="100%"
                              height="480"
                              allow="autoplay"
                              title={row?.title}
                            ></iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default ChangeLogs;

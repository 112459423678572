/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { MenuInnerWithSub } from "./MenuInnerWithSub";
import { MenuItem } from "./MenuItem";

const MegaMenu: FC = () => {
  const getPermissions: any = localStorage.getItem("permissions");
  const permissions = getPermissions || [];
  return (
    <div className="row">
      <div className="col-12">
        <div className="row">
          <div className="col-lg-6 mb-3">
            {permissions?.includes("create-blender") === true ||
              permissions?.includes("view-blender") === true ? (
              <MenuInnerWithSub
                title="Blenders"
                to="/masters/blenders"
                hasArrow={true}
                hasBullet={true}
                menuPlacement="right-start"
                menuTrigger={`{default:'click', lg: 'hover'}`}
              >
                {permissions?.includes("create-blender") === true && (
                  <MenuItem
                    title="Create Blender"
                    to="/masters/blenders/create"
                    hasBullet={true}
                  />
                )}
                {permissions?.includes("view-blender") === true && (
                  <MenuItem
                    title="Blenders List"
                    to="/masters/blenders/list"
                    hasBullet={true}
                  />
                )}
              </MenuInnerWithSub>
            ) : (
              ""
            )}
            {permissions?.includes("create-board") === true ||
              permissions?.includes("view-board") === true ? (
              <MenuInnerWithSub
                title="Board"
                to="/masters/board"
                hasArrow={true}
                hasBullet={true}
                menuPlacement="right-start"
                menuTrigger={`{default:'click', lg: 'hover'}`}
              >
                {permissions?.includes("create-board") === true && (
                  <MenuItem
                    title="Create Board"
                    to="/masters/board/create"
                    hasBullet={true}
                  />
                )}
                {permissions?.includes("view-board") === true && (
                  <MenuItem
                    title="Board List"
                    to="/masters/board/list"
                    hasBullet={true}
                  />
                )}
              </MenuInnerWithSub>
            ) : (
              ""
            )}
            {permissions?.includes("create-business-model") === true ||
              permissions?.includes("view-business-model") === true ? (
              <MenuInnerWithSub
                title="Business Model"
                to="/masters/business-model"
                hasArrow={true}
                hasBullet={true}
                menuPlacement="right-start"
                menuTrigger={`{default:'click', lg: 'hover'}`}
              >
                {permissions?.includes("create-business-model") === true && (
                  <MenuItem
                    title="Create Business Model"
                    to="/masters/business-model/create"
                    hasBullet={true}
                  />
                )}
                {permissions?.includes("view-business-model") === true && (
                  <MenuItem
                    title="Business Model List"
                    to="/masters/business-model/list"
                    hasBullet={true}
                  />
                )}
              </MenuInnerWithSub>
            ) : (
              ""
            )}
            {permissions?.includes("create-diagnosis") === true ||
              permissions?.includes("view-diagnosis") === true ? (
              <MenuInnerWithSub
                title="Diagnosis"
                to="/masters/diagnosis"
                hasArrow={true}
                hasBullet={true}
                menuPlacement="right-start"
                menuTrigger={`{default:'click', lg: 'hover'}`}
              >
                {permissions?.includes("create-diagnosis") === true && (
                  <MenuItem
                    title="Create Diagnosis"
                    to="/masters/diagnosis/create"
                    hasBullet={true}
                  />
                )}
                {permissions?.includes("view-diagnosis") === true && (
                  <MenuItem
                    title="Diagnosis List"
                    to="/masters/diagnosis/list"
                    hasBullet={true}
                  />
                )}
              </MenuInnerWithSub>
            ) : (
              ""
            )}
            {/* {permissions?.includes("create-deployment-type") === true ||
              permissions?.includes("view-deployment-type") === true ? (
              <MenuInnerWithSub
                title="Deployment Types"
                to="/masters/deployment-type"
                hasArrow={true}
                hasBullet={true}
                menuPlacement="right-start"
                menuTrigger={`{default:'click', lg: 'hover'}`}
              >
                {permissions?.includes("create-deployment-type") === true && (
                  <MenuItem
                    title="Create Deployment Type"
                    to="/masters/deployment-type/create"
                    hasBullet={true}
                  />
                )}
                {permissions?.includes("view-deployment-type") === true && (
                  <MenuItem
                    title="Deployment Types List"
                    to="/masters/deployment-type/list"
                    hasBullet={true}
                  />
                )}
              </MenuInnerWithSub>
            ) : (
              ""
            )} */}
            {permissions?.includes("create-distributor") === true ||
              permissions?.includes("view-distributor") === true ? (
              <MenuInnerWithSub
                title="Distributors"
                to="/masters/distributors"
                hasArrow={true}
                hasBullet={true}
                menuPlacement="right-start"
                menuTrigger={`{default:'click', lg: 'hover'}`}
              >
                {permissions?.includes("create-distributor") === true && (
                  <MenuItem
                    title="Create Distributor"
                    to="/masters/distributors/create"
                    hasBullet={true}
                  />
                )}
                {permissions?.includes("view-distributor") === true && (
                  <MenuItem
                    title="Distributors List"
                    to="/masters/distributors/list"
                    hasBullet={true}
                  />
                )}
              </MenuInnerWithSub>
            ) : (
              ""
            )}
            {permissions?.includes("create-issue-reported") === true ||
              permissions?.includes("view-issue-reported") === true ? (
              <MenuInnerWithSub
                title="Issue Reported"
                to="/masters/issue"
                hasArrow={true}
                hasBullet={true}
                menuPlacement="right-start"
                menuTrigger={`{default:'click', lg: 'hover'}`}
              >
                {permissions?.includes("create-issue-reported") === true && (
                  <MenuItem
                    title="Create Issue Reported"
                    to="/masters/issues/create"
                    hasBullet={true}
                  />
                )}
                {permissions?.includes("view-issue-reported") === true && (
                  <MenuItem
                    title="Issue Reported List"
                    to="/masters/issues/list"
                    hasBullet={true}
                  />
                )}
              </MenuInnerWithSub>
            ) : (
              ""
            )}
            {permissions?.includes("create-region") === true ||
              permissions?.includes("view-region") === true ? (
              <MenuInnerWithSub
                title="Location Regions"
                to="/masters/region"
                hasArrow={true}
                hasBullet={true}
                menuPlacement="right-start"
                menuTrigger={`{default:'click', lg: 'hover'}`}
              >
                {permissions?.includes("create-region") === true && (
                  <MenuItem
                    title="Create Location Region"
                    to="/masters/region/create"
                    hasBullet={true}
                  />
                )}
                {permissions?.includes("view-region") === true && (
                  <MenuItem
                    title="Location Regions List"
                    to="/masters/region/list"
                    hasBullet={true}
                  />
                )}
              </MenuInnerWithSub>
            ) : (
              ""
            )}
            {permissions?.includes("create-location-type") === true ||
              permissions?.includes("view-location-type") === true ? (
              <MenuInnerWithSub
                title="Location Types"
                to="/masters/location-type"
                hasArrow={true}
                hasBullet={true}
                menuPlacement="right-start"
                menuTrigger={`{default:'click', lg: 'hover'}`}
              >
                {permissions?.includes("create-location-type") === true && (
                  <MenuItem
                    title="Create Location Type"
                    to="/masters/location-type/create"
                    hasBullet={true}
                  />
                )}
                {permissions?.includes("view-location-type") === true && (
                  <MenuItem
                    title="Location Types List"
                    to="/masters/location-type/list"
                    hasBullet={true}
                  />
                )}
              </MenuInnerWithSub>
            ) : (
              ""
            )}
            {permissions?.includes("create-machine-serial-number") === true ||
              permissions?.includes("view-machine-serial-number") === true ? (
              <MenuInnerWithSub
                title="Machine Serial Number"
                to="/masters/machine-serial-number"
                hasArrow={true}
                hasBullet={true}
                menuPlacement="right-start"
                menuTrigger={`{default:'click', lg: 'hover'}`}
              >
                {permissions?.includes("create-machine-serial-number") === true && (
                  <MenuItem
                    title="Import Machine Serial Number"
                    to="/masters/machine-serial-number/create"
                    hasBullet={true}
                  />
                )}
                {permissions?.includes("view-machine-serial-number") === true && (
                  <MenuItem
                    title="Machine Serial Number List"
                    to="/masters/machine-serial-number/list"
                    hasBullet={true}
                  />
                )}
              </MenuInnerWithSub>
            ) : (
              ""
            )}
          </div>
          <div className="col-lg-6 mb-3">
            {permissions?.includes("create-machine-service") === true ||
              permissions?.includes("view-machine-service") === true ? (
              <MenuInnerWithSub
                title="Machine Services"
                to="/masters/services"
                hasArrow={true}
                hasBullet={true}
                menuPlacement="right-start"
                menuTrigger={`{default:'click', lg: 'hover'}`}
              >
                {permissions?.includes("create-machine-service") === true && (
                  <MenuItem
                    title="Create Machine Service"
                    to="/masters/services/create"
                    hasBullet={true}
                  />
                )}
                {permissions?.includes("view-machine-service") === true && (
                  <MenuItem
                    title="Machine Services List"
                    to="/masters/services/list"
                    hasBullet={true}
                  />
                )}
              </MenuInnerWithSub>
            ) : (
              ""
            )}
            {permissions?.includes("create-market-segment") === true ||
              permissions?.includes("view-market-segment") === true ? (
              <MenuInnerWithSub
                title="Market Segments"
                to="/masters/market-segment"
                hasArrow={true}
                hasBullet={true}
                menuPlacement="right-start"
                menuTrigger={`{default:'click', lg: 'hover'}`}
              >
                {permissions?.includes("create-market-segment") === true && (
                  <MenuItem
                    title="Create Market Segment"
                    to="/masters/market-segment/create"
                    hasBullet={true}
                  />
                )}
                {permissions?.includes("view-market-segment") === true && (
                  <MenuItem
                    title="Market Segments List"
                    to="/masters/market-segment/list"
                    hasBullet={true}
                  />
                )}
              </MenuInnerWithSub>
            ) : (
              ""
            )}
            {permissions?.includes("create-marketing-material") === true ||
              permissions?.includes("view-marketing-material") === true ? (
              <MenuInnerWithSub
                title="Marketing Materials"
                to="/masters/marketing-material"
                hasArrow={true}
                hasBullet={true}
                menuPlacement="right-start"
                menuTrigger={`{default:'click', lg: 'hover'}`}
              >
                {permissions?.includes("create-marketing-material") ===
                  true && (
                    <MenuItem
                      title="Create Marketing Material"
                      to="/masters/marketing-material/create"
                      hasBullet={true}
                    />
                  )}
                {permissions?.includes("view-marketing-material") === true && (
                  <MenuItem
                    title="Marketing Materials List"
                    to="/masters/marketing-material/list"
                    hasBullet={true}
                  />
                )}
              </MenuInnerWithSub>
            ) : (
              ""
            )}
            {permissions?.includes("create-marketing") === true ||
              permissions?.includes("view-marketing") === true ? (
              <MenuInnerWithSub
                title="Marketings"
                to="/masters/marketing"
                hasArrow={true}
                hasBullet={true}
                menuPlacement="right-start"
                menuTrigger={`{default:'click', lg: 'hover'}`}
              >
                {permissions?.includes("create-marketing") === true && (
                  <MenuItem
                    title="Create Marketing"
                    to="/masters/marketing/create"
                    hasBullet={true}
                  />
                )}
                {permissions?.includes("view-marketing") === true && (
                  <MenuItem
                    title="Marketings List"
                    to="/masters/marketing/list"
                    hasBullet={true}
                  />
                )}
              </MenuInnerWithSub>
            ) : (
              ""
            )}
            {permissions?.includes("create-operation") === true ||
              permissions?.includes("view-operation") === true ? (
              <MenuInnerWithSub
                title="Operations"
                to="/masters/operations"
                hasArrow={true}
                hasBullet={true}
                menuPlacement="right-start"
                menuTrigger={`{default:'click', lg: 'hover'}`}
              >
                {permissions?.includes("create-operation") === true && (
                  <MenuItem
                    title="Create Operation"
                    to="/masters/operations/create"
                    hasBullet={true}
                  />
                )}
                {permissions?.includes("view-operation") === true && (
                  <MenuItem
                    title="Operations List"
                    to="/masters/operations/list"
                    hasBullet={true}
                  />
                )}
              </MenuInnerWithSub>
            ) : (
              ""
            )}
            {permissions?.includes("swap-deployment-manager-location") ===
              true && (
                <MenuItem
                  title="Replace Regional Manager"
                  to="/masters/replace-manager"
                  hasBullet={true}
                />
              )}
            {permissions?.includes("create-ticket-source") === true ||
              permissions?.includes("view-ticket-source") === true ? (
              <MenuInnerWithSub
                title="Ticket Sources"
                to="/masters/ticket-source"
                hasArrow={true}
                hasBullet={true}
                menuPlacement="right-start"
                menuTrigger={`{default:'click', lg: 'hover'}`}
              >
                {permissions?.includes("create-ticket-source") === true && (
                  <MenuItem
                    title="Create Ticket Source"
                    to="/masters/ticket-source/create"
                    hasBullet={true}
                  />
                )}
                {permissions?.includes("view-ticket-source") === true && (
                  <MenuItem
                    title="Ticket Sources List"
                    to="/masters/ticket-source/list"
                    hasBullet={true}
                  />
                )}
              </MenuInnerWithSub>
            ) : (
              ""
            )}
             {permissions?.includes("create-cup-holder") === true ||
              permissions?.includes("view-cup-holder") === true ? (
            <MenuInnerWithSub
                title="Cup Holders"
                to="/masters/cup-holder"
                hasArrow={true}
                hasBullet={true}
                menuPlacement="right-start"
                menuTrigger={`{default:'click', lg: 'hover'}`}
              >
                {permissions?.includes("create-cup-holder") === true && (
                  <MenuItem
                    title="Create Cup Holder"
                    to="/masters/cup-holder/create"
                    hasBullet={true}
                  />
                )}
                    {permissions?.includes("view-cup-holder") === true && (
                  <MenuItem
                    title="Cup Holder List"
                    to="/masters/cup-holder/list"
                    hasBullet={true}
                  />
                    )}
            </MenuInnerWithSub>
              ):(
                ""
              )}
                {permissions?.includes("create-gas-supplier") === true ||
              permissions?.includes("view-gas-supplier") === true ? (
              <MenuInnerWithSub
                title="Gas Supplier"
                to="/masters/gas-supplier"
                hasArrow={true}
                hasBullet={true}
                menuPlacement="right-start"
                menuTrigger={`{default:'click', lg: 'hover'}`}
              >
                {permissions?.includes("create-gas-supplier") === true && (
                  <MenuItem
                    title="Create Gas Supplier"
                    to="/masters/gas-supplier/create"
                    hasBullet={true}
                  />
                )}
                {permissions?.includes("view-gas-supplier") === true && (
                  <MenuItem
                    title="Gas Supplier List"
                    to="/masters/gas-supplier/list"
                    hasBullet={true}
                  />
                )}
              </MenuInnerWithSub>
            ) : (
              ""
            )}
            {permissions?.includes("view-settings") === true && (
              <div data-bs-toggle="tooltip" title="Settings">
                <div data-bs-toggle="tooltip" title="Settings">
                  <MenuItem
                    to="/settingPage"
                    title="Settings"
                    hasBullet={true}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { MegaMenu };

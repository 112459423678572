import React, { useEffect, useState, Suspense, useRef } from "react";
import { ErrorMessage, Formik } from "formik";
import * as yup from "yup";
import toast, { Toaster } from "react-hot-toast";
import clsx from "clsx";
import Moment from "moment";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import Select from "react-select";
import _ from "lodash";
import {
  getMachineService,
  getMarketing,
  getOperations,
  getTicket
} from "../../models/_tickets";
import { UpdateTicketStatus } from "../../models/_updateTickets";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { getMarketingMaterials } from "../../models/_marketingMaterial";
import {
  getReplaceMachines,
} from "../../models/_machine";
import { getBoardsList } from "../../models/_board";
import { getBlendersList } from "../../models/_blenders";
import { getDiagnosisList } from "../../models/_diagnosis";
import Modal from "react-responsive-modal";
import { MACHINE_SERVICES } from "../../../_metronic/helpers/enums";
import { getLocations } from "../../models/_location";
import { TICKET_TYPE } from "./constants";

const validationSchema = yup.object({
  closeTicket: yup.boolean(),
  howTicketClosed: yup
    .string()
    .nullable()
    .when("status", {
      is: "COMPLETED",
      then: yup.string().required("Service type is required"),
    }),
  status: yup.string(),
  timeSpentOnTask: yup
    .string()
    .nullable()
    .when("status", {
      is: "COMPLETED",
      then: yup.string().required("Time spend on task is required"),
    }),
  travelTime: yup.string().nullable().when("howTicketClosed", {
    is: "ON_SITE",
    then: yup.string().required("Travel time is required"),
  }),
});

export interface IStatusChange {
  comment: string;
  howTicketClosed: string | null;
  travelTime: string;
  timeSpentOnTask: string;
  closingDateAndTime: Date;
  customerSatisfaction: string | null;
  staffEnthusiasm: string | null;
  cleanliness: string | null;
  trafficRate: string | null;
  services_other: string;
  operation_other: string;
  marketing_other: string;
  diagnosis_other: string;
  status: string;
  marketingOrOthers: string[];
  operations: string[];
  machineServices: string[];
  marketingMaterials: string[];
  diagnosis: string[]
  replaceNewTubeDoors1mmShorter?: boolean;
  blenderId?: string;
  boardId?: string;
  board?: string;
  blender?: string;
  machineId?: string;
  machine?: string;
  oldMachineShiftToLocationName?: string;
  oldMachineShiftToLocationId?: string;
  replaceFilter: undefined
  replaceSanitizer: undefined
  ticketType: string;
  convertToLabTicket?: boolean;
}

const inits: IStatusChange = {
  comment: "",
  howTicketClosed: "",
  travelTime: "",
  timeSpentOnTask: "",
  closingDateAndTime: new Date(),
  customerSatisfaction: "",
  staffEnthusiasm: "",
  cleanliness: "",
  trafficRate: "",
  services_other: "",
  operation_other: "",
  marketing_other: "",
  diagnosis_other: "",
  status: "",
  marketingOrOthers: [],
  operations: [],
  machineServices: [],
  marketingMaterials: [],
  diagnosis: [],
  replaceNewTubeDoors1mmShorter: false,
  blenderId: "",
  boardId: "",
  board: "",
  blender: "",
  machine: "",
  machineId: "",
  replaceFilter: undefined,
  replaceSanitizer: undefined,
  ticketType:"",
  oldMachineShiftToLocationName: "",
  oldMachineShiftToLocationId: "",
  convertToLabTicket: false
};
const statuses = [
  {
    id: "PENDING",
    name: "Pending",
  },
  // {
  //   id: "PENDING_INSTALLATION",
  //   name: "Pending installation",
  // },
  {
    id: "ON_HOLD",
    name: "On-hold",
  },
  {
    id: "IN_PROCESS",
    name: "In-Process",
  },
  {
    id: "COMPLETED",
    name: "Completed",
  },
  {
    id: "ARCHIVED",
    name: "Archived",
  },
];

type MachineObject = {
  id: string;
  name: string;
}
const StatusChange = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const resetClosingDate = React.useRef<any>(null);
  const inputRef = useRef<any>(null);
  const [loading, setLoading] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [machineServicesDB, setMachineServicesDB] = useState<any>([]);
  const [operationDB, setOperationDB] = useState([]);
  const [marketingDB, setMarketingDB] = useState([]);
  const [resultImages, setResultImages] = useState([]);
  const [ticket, setTicket] = useState<any>({});
  const [initValues, setInitValues] = useState(inits);
  const [statusDB, setStatusDB] = useState([]);
  const [materialDB, setMaterialDB] = useState([]);
  const [blenderDB, setBlenderDB] = useState<any>([]);
  const [boardDB, setBoardDB] = useState<any>([]);
  const [machineDB, setMachineDB] = useState<any>([]);
  const [diagnosisDB, setDiagnosisDB] = useState<any>([]);
  const [open, setOpen] = React.useState(false);
  const [locationDB, setLocationDB] = useState<any>([]);
  const [locationOpen, setLocationOpen] = React.useState(false);
  const [newSelectedMachine, setNewSelectedMachine] = React.useState<MachineObject | null>(null)  
  const handleClose = () => {
    inputRef.current.setValue("");
    setOpen(false);
  };

  const replaceMachine = () => {
    setLocationOpen(true)
    setOpen(false)
  }
  const [satisfactionDB] = useState([
    {
      id: "HIGH",
      name: "High",
    },
    {
      id: "MEDIUM",
      name: "Medium",
    },
    {
      id: "LOW",
      name: "Low",
    },
  ]);
  const [cleanlinessDB] = useState([
    {
      id: "GREAT",
      name: "Great",
    },
    {
      id: "MEDIUM",
      name: "Medium",
    },
    {
      id: "BAD",
      name: "Bad",
    },
  ]);
  const [trafficRateDB] = useState([
    {
      id: "HIGH",
      name: "High",
    },
    {
      id: "MEDIUM",
      name: "Medium",
    },
    {
      id: "LOW",
      name: "Low",
    },
  ]);
  const [enthusiasmDB] = useState([
    {
      id: "HIGH",
      name: "High",
    },
    {
      id: "MEDIUM",
      name: "Medium",
    },
    {
      id: "LOW",
      name: "Low",
    },
  ]);
  const [closedServiceTypeDB] = useState([
    {
      id: "ON_SITE",
      name: "On site",
    },
    {
      id: "REMOTE",
      name: "Remote",
    },
    // {
    //   id: "NA",
    //   name: "N/A",
    // },
  ]);

  const getBase64 = (file: any) => {
    return new Promise((resolve) => {
      let reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = () => {
        const baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };
  const onSelectFile = (event: any) => {
    const selectedFiles = event.target.files;
    const selectedFilesArray: any = Array.from(selectedFiles);
    for (let i = 0; i < selectedFilesArray.length; i++) {
      const name = selectedFilesArray[i].name;
      const type = selectedFilesArray[i].type;

      getBase64(selectedFilesArray[i])
        .then((result) => {
          const imagesArray: any = [
            { name: name, type: type, content: result },
          ];
          const resultArray: any = [{ name: name, content: result }];
          setSelectedImages((previousImages) =>
            previousImages.concat(imagesArray)
          );
          setResultImages((previousImages) =>
            previousImages.concat(resultArray)
          );
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
    event.target.value = "";
  };
  function deleteHandler(image: any) {
    setSelectedImages(
      selectedImages.filter((e: any) => e.content !== image.content)
    );
    setResultImages(
      resultImages.filter((e: any) => e.content !== image.content)
    );
    URL.revokeObjectURL(image.content);
  }

  const fetchData = async () => {
    const ticketId = location.pathname.split("/").reverse()[0];
    if (!ticketId) {
      navigate("/tickets/list");
    }
    try {
      const result = await getTicket(ticketId);
      if (result == null) {
        navigate("/tickets/list");
      } else {
        let marketingOrOthersCurrent: any = [];
        marketingOrOthersCurrent =
          result.marketingOrOthers?.map((a: any) => a.id) ?? [];
        if (result.marketingOtherInput !== "") {
          marketingOrOthersCurrent.push("other");
        }

        let diagnosisCurrent: any = [];
        diagnosisCurrent = result.diagnosisDone?.map((a: any) => a.id) ?? [];
        if (result.diagnosisOtherInput !== null && ticket.diagnosisOtherInput !== "") {
          diagnosisCurrent.push("other");
        }

        let operationsCurrent: any = [];
        operationsCurrent = result.operationsDone?.map((a: any) => a.id) ?? [];
        if (result.operationOtherInput !== "") {
          operationsCurrent.push("other");
        }

        let machineServicesCurrent: any = [];
        machineServicesCurrent =
          result.machineServicesDone?.map((a: any) => a.id) ?? [];
        if (result.machineServiceOtherInput !== "") {
          machineServicesCurrent.push("other");
        }

        const marketingMaterialsCurrent =
          result.marketingMaterials?.map((a: any) => a.id) ?? [];

        setNewSelectedMachine(result.assignedNewMachine)  
        setTicket(result);
        setInitValues((values) => ({
          ...values,
          status: result.status,
          comment: result.lastStatusComment,
          machineServices: machineServicesCurrent,
          diagnosis: diagnosisCurrent,
          operations: operationsCurrent,
          marketingOrOthers: marketingOrOthersCurrent,
          marketingMaterials: marketingMaterialsCurrent,
          replaceNewTubeDoors1mmShorter: result?.replaceNewTubeDoors1mmShorter,
          services_other: result.machineServiceOtherInput,
          operation_other: result.operationOtherInput,
          marketing_other: result.marketingOtherInput,
          diagnosis_other: result.diagnosisOtherInput ?? "",
          howTicketClosed: result.howTicketClosed,
          timeSpentOnTask: result.timeSpentOnTask,
          customerSatisfaction: result.customerSatisfaction,
          staffEnthusiasm: result.staffEnthusiasm,
          trafficRate: result.trafficRate,
          cleanliness: result.cleanliness,
          boardId: result?.boardId,
          blenderId: result?.blenderId,
          board: result?.board?.title ?? "",
          blender: result?.blender?.title ?? "",
          machineId: result?.machineId,
          machine:result?.machine?.name ? `${result?.machine?.name} (${result?.machine?.serialNumber})`:"",
          locationId: result?.machine?.locationId,
          location: result?.machine.location.name ?? "",
          oldMachineShiftToLocationId: result.oldMachineShiftToLocationId,
          oldMachineShiftToLocationName: result.oldMachineShiftToLocation?.name,
          convertToLabTicket: result.convertToLabTicket,
          ticketType:result.ticketType,
          replaceFilter: result.isFilterReplaced,
          replaceSanitizer: result.isSanitizerReplaced,
          travelTime: result.travelTime
        }));
      }
    } catch (e) {
      navigate("/tickets/list");
    }
    try {
      const machineServiceRecord = await getMachineService();
      const machineServiceResults: any = machineServiceRecord
        ? machineServiceRecord
        : [];
      machineServiceResults.push({
        id: "other",
        name: "Other",
      });

      const operationRecord = await getOperations();
      const operationResults: any = operationRecord ? operationRecord : [];
      operationResults.push({
        id: "other",
        name: "Other",
      });

      const diagnosisRecord = await getDiagnosisList();
      const diagnosisResults: any = diagnosisRecord 
    ? diagnosisRecord.filter((record: any) => record.isDefault === true) 
    : [];
      diagnosisResults.push({
        id: "other",
        name: "Other",
      });

      const materialRecord = await getMarketingMaterials();
      const materialResult: any = materialRecord ? materialRecord : [];

      const marketingRecord = await getMarketing();
      const marketingResults: any = marketingRecord ? marketingRecord : [];
      marketingResults.push({
        id: "other",
        name: "Other",
      });
      const statusResults: any = [];
      for (const record of statuses) {
        statusResults.push({
          id: record.id,
          name: record.name,
        });
      }

      const blenderRecord = await getBlendersList();
      const blenderResults: any = blenderRecord ? blenderRecord : [];

      blenderResults.sort(function (a: any, b: any) {
        if (a.title.toLowerCase() < b.title.toLowerCase()) {
          return -1;
        }
        if (a.title.toLowerCase() > b.title.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      const boardRecord = await getBoardsList();
      const boardResults: any = boardRecord ? boardRecord : [];
      boardResults.sort(function (a: any, b: any) {
        if (a.title.toLowerCase() < b.title.toLowerCase()) {
          return -1;
        }
        if (a.title.toLowerCase() > b.title.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      const filter: any = {
        // deployment_type_id: {
        //   in: [
        //     "ff74a26f-7a71-4466-8cc3-96f09a1ba46b",
        //     "f4c41d03-2884-4b69-857e-716f79899742",
        //   ],
        // },
      };
      const inStock = false;
      const machinesRecords = await getReplaceMachines(filter, inStock, {});
      const machinesResults: any = machinesRecords.data
        ? machinesRecords.data
        : [];
      machinesResults.sort(function (a: any, b: any) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      const locationRecord = await getLocations();
      const locationResults: any = locationRecord?.data ? locationRecord?.data : [];

      locationResults.sort(function (a: any, b: any) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      setMachineDB(machinesResults);
      setBlenderDB(blenderResults);
      setBoardDB(boardResults);
      setMachineServicesDB(machineServiceResults);
      setStatusDB(statusResults);
      setMaterialDB(materialResult);
      setOperationDB(operationResults);
      setMarketingDB(marketingResults);
      setDiagnosisDB(diagnosisResults)
      setLocationDB(locationResults)
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Suspense>
        <div id="kt_app_toolbar" className="app-toolbar py-2 pt-4">
          <div
            id="kt_app_toolbar_container"
            className="app-container container-xxl d-block d-lg-flex d-md-flex d-xl-flex flex-stack px-3"
            style={{ maxWidth: "100%" }}
          >
            <div className="page-title d-flex flex-column justify-content-center flex-wrap">
              <h1 className="page-heading d-flex text-dark fw-bold fs-1 flex-column justify-content-center my-0">
                Status Change - {ticket.uuid}
              </h1>
            </div>
            <div className="d-flex align-items-center gap-2 gap-lg-3 mt-3 mt-lg-0 mt-md-0">
              <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <li className="breadcrumb-item text-muted">
                  <a href="/" className="text-muted text-hover-primary fs-5">
                    Home
                  </a>
                </li>
                <li className="breadcrumb-item text-muted">
                  <span className="bullet bg-gray-400 w-5px h-2px" />
                  <a
                    href="/tickets/list"
                    className="text-muted text-hover-primary ps-2 fs-5"
                  >
                    Ticket Logs
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <span className="bullet bg-gray-400 w-5px h-2px" />
                </li>
                <li className="breadcrumb-item fs-5 text-dark">
                  Status Change
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="card mx-xl-4 mt-9">
          <Formik
            enableReinitialize
            initialValues={initValues}
            validationSchema={validationSchema}
            onSubmit={async (values, { resetForm, setSubmitting }) => {
              let hasErrors = false;
              if (values.machineServices?.includes("other")) {
                if (values.services_other === "") {
                  hasErrors = true;
                  toast.error("Other machine services is required");
                }
              }
              if (values.diagnosis?.includes("other")) {
                if (values.diagnosis_other === "") {
                  hasErrors = true;
                  toast.error("Other diagnosis is required");
                }
              }
              if (values.marketingOrOthers?.includes("other")) {
                if (values.marketing_other === "") {
                  hasErrors = true;
                  toast.error("Other marketing is required");
                }
              }

              if (
                values.machineServices?.includes(
                  MACHINE_SERVICES.REPLACE_BLENDER_ASSEMBLY
                ) &&
                values.blenderId === ""
              ) {
                hasErrors = true;
                toast.error("Blender is required");
              }

              if (
                (values.machineServices?.includes(
                  MACHINE_SERVICES.UPGRADE_PCB
                ) ||
                  values.machineServices?.includes(
                    MACHINE_SERVICES.REPLACE_PCB
                  )) &&
                values.boardId === ""
              ) {
                hasErrors = true;
                toast.error("Board is required");
              }
              if (
                values.machineServices?.includes(
                  MACHINE_SERVICES.REPLACE_MACHINE
                ) &&
                values.machineId === ""
              ) {
                hasErrors = true;
                toast.error("Machine is required");
              }
              if (values.operations?.includes("other")) {
                if (values.operation_other === "") {
                  hasErrors = true;
                  toast.error("Other operation is required");
                }
              }
              if (values.status === "COMPLETED") {
                if (!values.closingDateAndTime) {
                  hasErrors = true;
                  toast.error("Closing time is required");
                }
              }
              if (hasErrors) {
                console.log("inside has error");
                return false;
              }
              setLoading(true);
              const $inputData: any = {
                id: ticket.id,
                status: values.status,
                comment: values.comment,
                machineServices: values.machineServices.filter(
                  (a) => a !== "other"
                ),
                marketingOrOthers: values.marketingOrOthers.filter(
                  (a) => a !== "other"
                ),
                diagnosis: values.diagnosis.filter(
                  (a) => a !== "other"
                ),
                marketingMaterials: values.marketingMaterials,
                operations: values.operations.filter((a) => a !== "other"),
                howTicketClosed: null,
                closingDateAndTime: Moment(values.closingDateAndTime)
                  .utc()
                  .format("YYYY-MM-DD HH:mm:ss"),
                timeSpentOnTask: parseFloat(values.timeSpentOnTask),
                travelTime: parseFloat(values.travelTime),
                customerSatisfaction: null,
                cleanliness: null,
                trafficRate: null,
                staffEnthusiasm: null,
                machineServiceOtherInput: values.services_other,
                operationOtherInput: values.operation_other,
                marketingOtherInput: values.marketing_other,
                diagnosisOtherInput: values.diagnosis_other,
                blenderId: null,
                boardId: null,
                machineId: null,
                locationId: null,
                replaceNewTubeDoors1mmShorter: null,
                replaceFilter: undefined,
                replaceSanitizer: undefined,
                ticketType: values.ticketType === TICKET_TYPE.LAB ? TICKET_TYPE.LAB  : undefined
              };
              if (values.howTicketClosed) {
                $inputData["howTicketClosed"] = values.howTicketClosed;
              }
              if (values.customerSatisfaction) {
                $inputData["customerSatisfaction"] =
                  values.customerSatisfaction;
              }
              if (values.cleanliness) {
                $inputData["cleanliness"] = values.cleanliness;
              }
              if (values.trafficRate) {
                $inputData["trafficRate"] = values.trafficRate;
              }
              if (values.staffEnthusiasm) {
                $inputData["staffEnthusiasm"] = values.staffEnthusiasm;
              }
              if (resultImages && resultImages.length > 0) {
                $inputData["media"] = resultImages;
              }
              if (
                values.machineServices?.includes(
                  MACHINE_SERVICES.REPLACE_BLENDER_ASSEMBLY
                )
              ) {
                $inputData.blenderId = values.blenderId;
              }
              if (
                values.machineServices?.includes(
                  MACHINE_SERVICES.UPGRADE_PCB
                ) ||
                values.machineServices?.includes(
                  MACHINE_SERVICES.REPLACE_PCB
                )
              ) {
                $inputData.boardId = values.boardId;
              }
              if (
                values.machineServices?.includes(
                  MACHINE_SERVICES.REPLACE_MACHINE
                )
              ) {
                $inputData.machineId = values.machineId;
              }
              $inputData.replaceNewTubeDoors1mmShorter = false;
              if (
                values.machineServices?.includes(
                  MACHINE_SERVICES.REPLACE_TUBE_DOOR
                )
              ) {
                $inputData.replaceNewTubeDoors1mmShorter =
                  values.replaceNewTubeDoors1mmShorter ?? false;
              }
              if (values.replaceFilter === true) {
                $inputData.replaceFilter = values.replaceFilter
              }
              if (values.oldMachineShiftToLocationId) {
                $inputData.oldMachineShiftToLocationId = values.oldMachineShiftToLocationId
              }

              if (values.convertToLabTicket) {
                $inputData.convertToLabTicket = values.convertToLabTicket
              }

              if (values.replaceSanitizer === true) {
                $inputData.replaceSanitizer = values.replaceSanitizer
              }

              
              const result = await UpdateTicketStatus($inputData);
              if (result?.data?.data?.updateTicketStatus) {
                toast.success("Status updated successfully");
                resetForm();
                setSelectedImages([]);
                $("input[type=checkbox]").prop("checked", function () {
                  $(this).prop("checked", false);
                });
                setTimeout(() => {
                  navigate("/tickets/list");
                }, 1000);

                setLoading(false);
              } else {
                setSubmitting(false);
                setLoading(false);
                const messages = result?.data?.errors.map(
                  (e: any) => e.message
                );
                toast.error(messages.join(","));
              }
            }}
          >
            {({
              handleChange,
              handleSubmit,
              values,
              errors,
              touched,
              setFieldValue,
            }) => {
              return(
              <div className="card-body w-100 p-lg-7 p-md-7 p-5">
                <form onSubmit={handleSubmit} autoComplete="off">
                  <div className="row">
                    {ticket.ticketType === "REACTIVE_MAINTANANCE" ||
                      ticket.ticketType === "PREVENTIVE_MAINTANANCE" ||
                      ticket.ticketType === "LAB" ||
                      ticket?.ticketType === null ? (
                      <>
                      <div className="col-xl-6 col-lg-12 col-md-12 col-12 p-2 pe-xl-5 ps-xl-4 mb-3 pb-2">
                          <div className="accordion">
                            <div className="accordion-item">
                              <h2
                                className="accordion-header"
                                data-bs-toggle="tooltip"
                                title="Click here to add diagnosis done"
                                id="diagnosis"
                              >
                                <button
                                  className="accordion-button py-5 collapsed form-label cursor-pointer fs-4 fw-bold mb-0 bg-light"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#diagnosis-needed"
                                  aria-expanded="false"
                                  aria-controls="diagnosis-needed"
                                >
                                  Diagnosis Done
                                </button>
                              </h2>
                              <div
                                id="diagnosis-needed"
                                className="accordion-collapse collapse"
                                aria-labelledby="diagnosis"
                              >
                                <div className="accordion-body pb-1 p-3">
                                  <div className="row">
                                    {Array.from(diagnosisDB).map(
                                      (value: any, index) => {
                                        return (
                                          <div className="col-lg-6 col-md-6 col-12" key={`diagnosis-${index}`}>
                                            <label
                                              className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-flex mt-1 ms-2"
                                              key={index}
                                            >
                                              <input
                                                id="diagnosis"
                                                type="checkbox"
                                                name={value.id}
                                                defaultChecked={initValues.diagnosis?.includes(
                                                  value.id
                                                )}
                                                className="form-check-input mb-2 me-1"
                                                value={value.id}
                                                onChange={(e) => {
                                                  const { checked, name } =
                                                    e.target;
                                                  if (checked) {
                                                    setFieldValue(
                                                      "diagnosis",
                                                      [
                                                        ...values.diagnosis,
                                                        name,
                                                      ]
                                                    );
                                                  } else {
                                                    setFieldValue(
                                                      "diagnosis",
                                                      values.diagnosis.filter(
                                                        (e) => e !== name
                                                      )
                                                    );
                                                  }
                                                }}
                                              />
                                              <span className="ms-1 mb-2 fs-5 form-check-label">
                                                {value.name}
                                              </span>
                                            </label>
                                          </div>
                                        );
                                      }
                                    )}
                                    <div className="text-danger text-align-top-center mt-2">
                                      <ErrorMessage name="diagnosis" />
                                    </div>
                                  </div>

                                  {values.diagnosis?.includes("other") ||
                                    values.diagnosis_other !== "" ? (
                                    <div className="col-xl-6 col-12 p-2 pe-xl-5 pb-2 mt-3">
                                      <label className="form-label fs-4 required fw-bold">
                                        Other Diagnosis
                                      </label>
                                      <input
                                        id="diagnosis_other"
                                        name="diagnosis_other"
                                        className="form-control"
                                        value={values.diagnosis_other}
                                        onChange={handleChange}
                                      />
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-12 col-md-12 col-12 p-2 pe-xl-5 ps-xl-4 mb-3 pb-2">
                          <div className="accordion">
                            <div className="accordion-item">
                              <h2
                                className="accordion-header"
                                id="machine-services"
                                data-bs-toggle="tooltip"
                                title="Click here to add machine services done"
                              >
                                <button
                                  className="accordion-button py-5 collapsed form-label cursor-pointer fs-4 fw-bold mb-0 bg-light"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#machine-services-needed"
                                  aria-expanded="false"
                                  aria-controls="machine-services-needed"
                                >
                                  Machine Services Done
                                </button>
                              </h2>
                              <div
                                id="machine-services-needed"
                                className="accordion-collapse collapse"
                                aria-labelledby="machine-services"
                              >
                                <div className="accordion-body pb-1 p-3">
                                  <div className="row">
                                    {Array.from(machineServicesDB).map(
                                      (value: any, index) => {
                                        return (
                                          <div className="col-lg-6 col-md-6 col-12" key={`machine-service-${index}`}>
                                            <label
                                              key={index}
                                              className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-flex ms-2 mt-1"
                                            >
                                              <input
                                                id="machineServices"
                                                className="form-check-input mb-2 me-1"
                                                type="checkbox"
                                                name={value.id}
                                                value={value.id}
                                                defaultChecked={initValues.machineServices?.includes(
                                                  value.id
                                                )}
                                                onChange={(e) => {
                                                  const { checked, name } =
                                                    e.target;
                                                  if (checked) {
                                                    setFieldValue(
                                                      "machineServices",
                                                      [
                                                        ...values.machineServices,
                                                        name,
                                                      ]
                                                    );
                                                  } else {
                                                    setFieldValue(
                                                      "machineServices",
                                                      values.machineServices.filter(
                                                        (e) => e !== name
                                                      )
                                                    );
                                                  }
                                                }}
                                              />
                                              <span className="ms-2 mb-1 fs-5 form-check-label">
                                                {value.name}
                                              </span>
                                            </label>
                                          </div>
                                        );
                                      }
                                    )}
                                    <div className="text-danger text-align-top-center mt-2">
                                      <ErrorMessage name="machineServices" />
                                    </div>
                                  </div>

                                  {values.machineServices?.includes("other") ||
                                    values.services_other !== "" ? (
                                    <div className="col-xl-6 col-12 p-2 pe-xl-5 pb-2 mt-3">
                                      <label className="form-label fs-4 required fw-bold">
                                        Other Machine services
                                      </label>
                                      <input
                                        id="services_other"
                                        name="services_other"
                                        className={clsx("form-control", {
                                          "is-invalid":
                                            Boolean(errors.services_other) &&
                                            touched.services_other,
                                        })}
                                        value={values.services_other}
                                        onChange={handleChange}
                                      />
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-12 col-md-12 col-12 p-2 pe-xl-5 ps-xl-4 mb-3 pb-2">
                          <div className="accordion">
                            <div className="accordion-item">
                              <h2
                                className="accordion-header"
                                data-bs-toggle="tooltip"
                                title="Click here to add operations done"
                                id="operations"
                              >
                                <button
                                  className="accordion-button py-5 collapsed form-label cursor-pointer fs-4 fw-bold mb-0 bg-light"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#operations-needed"
                                  aria-expanded="false"
                                  aria-controls="operations-needed"
                                >
                                  Operations Done
                                </button>
                              </h2>
                              <div
                                id="operations-needed"
                                className="accordion-collapse collapse"
                                aria-labelledby="operations"
                              >
                                <div className="accordion-body pb-1 p-3">
                                  <div className="row">
                                    {Array.from(operationDB).map(
                                      (value: any, index) => {
                                        return (
                                          <div className="col-lg-6 col-md-6 col-12" key={`operation-${index}`}>
                                            <label
                                              className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-flex mt-1 ms-2"
                                              key={index}
                                            >
                                              <input
                                                id="operations"
                                                type="checkbox"
                                                name={value.id}
                                                defaultChecked={initValues.operations?.includes(
                                                  value.id
                                                )}
                                                className="form-check-input mb-2 me-1"
                                                value={value.id}
                                                onChange={(e) => {
                                                  const { checked, name } =
                                                    e.target;
                                                  if (checked) {
                                                    setFieldValue(
                                                      "operations",
                                                      [
                                                        ...values.operations,
                                                        name,
                                                      ]
                                                    );
                                                  } else {
                                                    setFieldValue(
                                                      "operations",
                                                      values.operations.filter(
                                                        (e) => e !== name
                                                      )
                                                    );
                                                  }
                                                }}
                                              />
                                              <span className="ms-1 mb-2 fs-5 form-check-label">
                                                {value.name}
                                              </span>
                                            </label>
                                          </div>
                                        );
                                      }
                                    )}
                                    <div className="text-danger text-align-top-center mt-2">
                                      <ErrorMessage name="operations" />
                                    </div>
                                  </div>

                                  {values.operations?.includes("other") ||
                                    values.operation_other !== "" ? (
                                    <div className="col-xl-6 col-12 p-2 pe-xl-5 pb-2 mt-3">
                                      <label className="form-label fs-4 required fw-bold">
                                        Other Operations
                                      </label>
                                      <input
                                        id="operation_other"
                                        name="operation_other"
                                        className="form-control"
                                        value={values.operation_other}
                                        onChange={handleChange}
                                      />
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {ticket.ticketType === "MARKETING_OTHER" ||
                      ticket?.ticketType === null ? (
                      <div className="col-lg-6 col-md-6 col-12 p-2 pe-xl-5 ps-xl-4 mb-3 pb-2">
                        <div className="accordion">
                          <div className="accordion-item">
                            <h2
                              className="accordion-header"
                              data-bs-toggle="tooltip"
                              title="Click here to add marketing purpose"
                              id="marketing"
                            >
                              <button
                                className="accordion-button py-5 collapsed form-label cursor-pointer fs-4 fw-bold mb-0 bg-light"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#marketing-purpose"
                                aria-expanded="false"
                                aria-controls="marketing-purpose"
                              >
                                Marketing Purpose
                              </button>
                            </h2>
                            <div
                              id="marketing-purpose"
                              className="accordion-collapse collapse"
                              aria-labelledby="marketing"
                            >
                              <div className="accordion-body pb-1 p-3">
                                <div className="row">
                                  {Array.from(marketingDB).map(
                                    (value: any, index: any) => {
                                      return (
                                        <div className="col-lg-6 col-md-6 col-12" key={`marketing-${index}`}>
                                          <label
                                            className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-flex mt-2 ms-2"
                                            key={index}
                                          >
                                            <input
                                              id="marketingOrOthers"
                                              type="checkbox"
                                              name={value.id}
                                              defaultChecked={initValues.marketingOrOthers?.includes(
                                                value.id
                                              )}
                                              className="form-check-input mb-2 me-1"
                                              value={value.id}
                                              onChange={(e) => {
                                                const { checked, name } =
                                                  e.target;
                                                if (checked) {
                                                  setFieldValue(
                                                    "marketingOrOthers",
                                                    [
                                                      ...values.marketingOrOthers,
                                                      name,
                                                    ]
                                                  );
                                                } else {
                                                  setFieldValue(
                                                    "marketingOrOthers",
                                                    values.marketingOrOthers.filter(
                                                      (e) => e !== name
                                                    )
                                                  );
                                                }
                                              }}
                                            />
                                            <span className="ms-1 mb-2 fs-5 form-check-label">
                                              {value.name}
                                            </span>
                                          </label>
                                        </div>
                                      );
                                    }
                                  )}
                                  <div className="text-danger text-align-top-center mt-2">
                                    <ErrorMessage name="marketingOrOthers" />
                                  </div>
                                </div>
                                {values.marketingOrOthers?.includes("other") ||
                                  values.marketing_other !== "" ? (
                                  <div className="col-xl-6 col-12 p-2 pe-xl-5 pb-2 mt-3 ">
                                    <label className="form-label fs-4 required fw-bold">
                                      Other Marketing
                                    </label>
                                    <input
                                      id="marketing_other"
                                      name="marketing_other"
                                      className="form-control"
                                      value={values.marketing_other}
                                      onChange={handleChange}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="row">
                    {values.machineServices?.includes(
                      MACHINE_SERVICES.REPLACE_BLENDER_ASSEMBLY
                    ) ? (
                      <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                        <label className="form-label required fs-4 fw-bold">
                          Blender
                        </label>
                        <Select
                          className="react-select"
                          classNamePrefix="my-react-select"
                          name="blenderId"
                          placeholder=""
                          options={Array.from(blenderDB).map((value: any) => {
                            return {
                              value: value.id,
                              label: value.title,
                            };
                          })}
                          defaultValue={{
                            label: _.capitalize(_.lowerCase(values.blender)),
                            value: values.blenderId,
                          }}
                          onChange={(e: any) => {
                            setFieldValue("blenderId", e.value);
                          }}
                        />
                        <div className="text-danger text-align-top-center mt-2">
                          <ErrorMessage name="blenderId" />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {values.machineServices?.includes(
                      MACHINE_SERVICES.UPGRADE_PCB
                    ) ||
                      values.machineServices?.includes(
                        MACHINE_SERVICES.REPLACE_PCB
                      ) ? (
                      <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                        <label className="form-label required fs-4 fw-bold">
                          Board
                        </label>
                        <Select
                          className="react-select"
                          classNamePrefix="my-react-select"
                          name="boardId"
                          placeholder=""
                          defaultValue={{
                            label: _.capitalize(_.lowerCase(values.board)),
                            value: values.boardId,
                          }}
                          options={Array.from(boardDB).map((value: any) => {
                            return {
                              value: value.id,
                              label: value.title,
                            };
                          })}
                          onChange={(e: any) => {
                            setFieldValue("boardId", e.value);
                          }}
                        />
                        <div className="text-danger text-align-top-center mt-2">
                          <ErrorMessage name="boardId" />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {values.machineServices?.includes(
                      MACHINE_SERVICES.REPLACE_MACHINE
                    ) ? (
                      <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                        <label className="form-label required fs-4 fw-bold">
                          Machine
                        </label>
                        <Select
                          ref={inputRef}
                          className="react-select"
                          classNamePrefix="my-react-select"
                          name="machineId"
                          placeholder=""
                          defaultValue={{
                            label: _.capitalize(_.lowerCase((newSelectedMachine ? newSelectedMachine.name : undefined))),
                            value: (newSelectedMachine ? newSelectedMachine.id : null),
                          }}
                          options={Array.from(machineDB).map((value: any) => {
                            return {
                              value: value.id,

                              label: `${value.name ? value?.name : (value?.location?.name  ?? "")} (${value?.serialNumber ?? ""
                                })`,
                            };
                          })}
                          onChange={(e: any) => {
                            const selectedId = e.value;
                            setFieldValue("machineId", selectedId);
                            setFieldValue("oldMachineShiftToLocationId", "");
                            setFieldValue("oldMachineShiftToLocationName", "")
                            const machinesWithLocation = machineDB.filter((machine: any) => machine.location !== null);
                            const machine = machinesWithLocation.find((machine: any) => machine.id === selectedId);
                            if (e.value === machine?.id) {
                              setOpen(true);
                            } else {
                              setOpen(false);
                              setLocationOpen(true);
                            }
                          }}
                        />
                        <div className="text-danger text-align-top-center mt-2">
                          <ErrorMessage name="machineId" />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                     {(locationOpen || values.oldMachineShiftToLocationId) ? (
                    <>
                      <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                        <label className="form-label fs-4 fw-bold">
                        Where you want to shift old machine ?
                        </label>
                        <Select
                          ref={inputRef}
                          className="react-select"
                          classNamePrefix="my-react-select"
                          name="oldMachineShiftToLocationId"
                          placeholder=""
                          defaultValue={{
                            label: _.capitalize(_.lowerCase(values.oldMachineShiftToLocationName)),
                            value: values.oldMachineShiftToLocationId,
                          }}
                          options={Array.from(locationDB).map((value: any) => {
                            return {
                              value: value.id,
                              label: value.name,
                            };
                          })}
                          onChange={(e: any) => {
                            const selectedId = e.value;
                            setFieldValue("oldMachineShiftToLocationId", selectedId);
                            setFieldValue("oldMachineShiftToLocationName", e.label)
                          }}
                        />
                      </div>
                    </>
                     ) : (
                      ""
                    )}
                    {values.machineServices?.includes(
                      MACHINE_SERVICES.REPLACE_TUBE_DOOR
                    ) ? (
                      <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-3">
                        <label className="form-label fs-4 fw-bold d-block">
                          Is tube door -1mm shorter ?
                        </label>
                        <div className="form-check form-switch form-check-custom form-check-solid w-100">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            defaultChecked={
                              values.replaceNewTubeDoors1mmShorter
                            }
                            onChange={(event) => {
                              setFieldValue(
                                "replaceNewTubeDoors1mmShorter",
                                event.target.checked
                              );
                            }}
                            name="replaceNewTubeDoors1mmShorter"
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="row mt-3">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-12 p-2 pe-xl-5 ps-xl-4 mb-3 mb-lg-4">
                      <label className="form-label fs-4 required fw-bold">
                        Comments
                      </label>
                      <textarea
                        className="form-control"
                        name="comment"
                        id="comment"
                        value={values.comment}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-12 p-2 pe-xl-5 mb-3 mb-lg-4 mt-md-9 mt-lg-9 mt-3">
                      <div className="btn btn-bg-light fs-4 w-100 p-3 m-0 border">
                        <label
                          className="form-label fs-4 mb-0 text-dark"
                          style={{ cursor: "pointer" }}
                        >
                          Click here to upload images & videos
                          <input
                            id="upload"
                            type="file"
                            accept="image/png , image/jpeg, image/webp, video/mp4, video/mkv, video/x-m4v, video/*"
                            name="uploadImageVideo"
                            className="form-control d-none"
                            onChange={onSelectFile}
                          />
                        </label>
                      </div>
                      {selectedImages.length > 0 && (
                        <>
                          {selectedImages &&
                            selectedImages.map((image, index) => {
                              return (
                                <div
                                  key={image}
                                  className="img-box mt-3 ms-2 justify-content-center"
                                >
                                  {image["type"] === "image/png" ||
                                    image["type"] === "image/jpeg" ||
                                    image["type"] === "image/webp" ||
                                    image["type"] === "image/jpg" ? (
                                    <img
                                      src={image["content"]}
                                      key={index}
                                      width="150"
                                      alt="Upload"
                                      className="img-wrapper overflow-hidden m-2 ms-0 d-flex position-relative"
                                    />
                                  ) : (
                                    <video
                                      controls
                                      src={image["content"]}
                                      className="img-wrapper overflow-hidden m-2 ms-0 d-flex position-relative"
                                      width="170"
                                    />
                                  )}
                                  <button
                                    onClick={() => deleteHandler(image)}
                                    className="close-btn img-btn text-gray-700 text-hover-gray-800"
                                  >
                                    <i className="fa fa-close fs-5 pt-1" />
                                  </button>
                                </div>
                              );
                            })}
                        </>
                      )}
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6 col-12 pe-xl-5 ps-xl-5 mb-3">
                      <label className="form-check form-check-custom form-check-solid me-5 d-flex align-items-center">
                        <input
                          id="replaceFilter"
                          type="checkbox"
                          name="replaceFilter"
                          className="form-check-input"
                          value="replaceFilter"
                          checked={values.replaceFilter}
                          onChange={handleChange}
                        />
                        <span
                          className="form-label fw-bold mt-2 ms-3 fs-3"
                          style={{ cursor: "pointer" }}
                        >
                          Replaced Filter
                        </span>
                      </label>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6 col-12 pe-xl-5 ps-xl-5 mb-3">
                      <label className="form-check form-check-custom form-check-solid me-5 d-flex align-items-center">
                        <input
                          id="replaceSanitizer"
                          type="checkbox"
                          name="replaceSanitizer"
                          className="form-check-input"
                          value="replaceSanitizer"
                          checked={values.replaceSanitizer}
                          onChange={handleChange}
                        />
                        <span
                          className="form-label fw-bold mt-2 ms-3 fs-3"
                          style={{ cursor: "pointer" }}
                        >
                          Replaced Sanitizer
                        </span>
                      </label>
                    </div>
                    
                    {ticket.ticketType === "MARKETING_OTHER" ||
                      ticket?.ticketType === null ? (
                      <div className="col-xl-6 col-lg-12 col-md-12 col-12 p-2 pe-xl-5 ps-xl-4 mb-3 mt-3 pb-2">
                        <div className="accordion">
                          <div className="accordion-item">
                            <h2
                              className="accordion-header"
                              id="marketing-material"
                              data-bs-toggle="tooltip"
                              title="Click here to add marketing materials used"
                            >
                              <button
                                className="accordion-button py-5 collapsed form-label cursor-pointer fs-4 fw-bold mb-0 bg-light"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#marketing-materials-used"
                                aria-expanded="false"
                                aria-controls="marketing-materials-used"
                              >
                                Marketing Materials Used
                              </button>
                            </h2>
                            <div
                              id="marketing-materials-used"
                              className="accordion-collapse collapse"
                              aria-labelledby="marketing-material"
                              data-bs-parent="#accordionFlushExample"
                            >
                              <div className="accordion-body pb-1 p-3">
                                <div className="row">
                                  {Array.from(materialDB).map(
                                    (value: any, index) => {
                                      return (
                                        <div className="col-lg-6 col-md-6 col-12" key={`material-${index}`}>
                                          <label
                                            key={index}
                                            className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-flex ms-2 mt-1"
                                          >
                                            <input
                                              id="marketingMaterials"
                                              className="form-check-input mb-2 me-1"
                                              type="checkbox"
                                              name={value.id}
                                              value={value.id}
                                              defaultChecked={initValues.marketingMaterials?.includes(
                                                value.id
                                              )}
                                              onChange={(e) => {
                                                const { checked, name } =
                                                  e.target;
                                                if (checked) {
                                                  setFieldValue(
                                                    "marketingMaterials",
                                                    [
                                                      ...values.marketingMaterials,
                                                      name,
                                                    ]
                                                  );
                                                } else {
                                                  setFieldValue(
                                                    "marketingMaterials",
                                                    values.marketingMaterials.filter(
                                                      (e) => e !== name
                                                    )
                                                  );
                                                }
                                              }}
                                            />
                                            <span className="ms-2 mb-1 fs-5 form-check-label">
                                              {value.name}
                                            </span>
                                          </label>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="row">
                    <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-6 pb-2">
                      <div className="form-label fs-4 mb-3 required fw-bold">
                        Status
                      </div>
                      {Array.from(statusDB).map((value: any, id) => (
                        <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-flex mt-2" key={id}>
                          <label className="ms-2 fs-5" key={id}>
                            <input
                              id="status"
                              className="form-check-input"
                              type="radio"
                              name="status"
                              value={value.id}
                              defaultChecked={value.id === ticket.status}
                              onChange={(event: any) => {
                                setFieldValue("status", event.target.value);
                              }}
                            />
                            <span className="form-check-label fs-5 mb-0 ms-2">
                              {value.name}
                            </span>
                          </label>
                        </div>
                      ))}
                      <div className="text-danger text-align-top-center mt-2">
                        <ErrorMessage name="status" />
                      </div>
                    </div>

                    {(values.status === 'COMPLETED' && values.machineServices?.includes(
                  MACHINE_SERVICES.REPLACE_MACHINE
                )) ?
                    <>
                    <div className="row">
                     <div className="col-xl-3 col-lg-4 col-md-6 col-12 pe-xl-5 ps-xl-5 mb-3">
                      <label className="form-check form-check-custom form-check-solid me-5 d-flex align-items-center">
                      <input
                      id="convertToLabTicket"
                      type="checkbox"
                      name="convertToLabTicket"
                      className="form-check-input"
                      checked={values.convertToLabTicket} 
                      onChange={(e) => {
                        setFieldValue("convertToLabTicket", true);
                      }}
                    />
                        <span
                          className="form-label fw-bold mt-2 ms-3 fs-3"
                          style={{ cursor: "pointer" }}
                        >
                           Do you want to convert this ticket to lab ticket?
                        </span>
                      </label>
                    </div>
                    </div>
                    </>
                  : ""}

                    {values.status === "COMPLETED" ? (
                      <>
                        <div className="row">
                          <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-6 pb-2">
                            <label className="form-label fs-4 required fw-bold ps-1">
                              How Ticket Closed
                            </label>
                            {Array.from(closedServiceTypeDB).map(
                              (value: any, index) => (
                                <label
                                  key={index}
                                  className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-flex mt-1 ms-2 mb-2"
                                >
                                  <input
                                    id="howTicketClosed"
                                    className="form-check-input"
                                    type="radio"
                                    name="howTicketClosed"
                                    value={value.id}
                                    checked={
                                      values.howTicketClosed === value.id
                                    }
                                    onChange={() =>
                                      setFieldValue("howTicketClosed", value.id)
                                    }
                                  />
                                  <span className="form-check-label fs-5 mb-0 ms-2">
                                    {value.name}
                                  </span>
                                </label>
                              )
                            )}
                            <div className="text-danger text-align-top-center mt-2">
                              <ErrorMessage name="howTicketClosed" />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-6 pb-2">
                            <label className="form-label fs-4 required fw-bold">
                              Closing Date & Time
                            </label>
                            <Datetime
                              ref={resetClosingDate}
                              dateFormat="YYYY-MM-DD"
                              timeFormat="HH:mm"
                              closeOnSelect
                              value={values.closingDateAndTime}
                              onChange={(e: any) => {
                                const dt = e["_d"];
                                setFieldValue("closingDateAndTime", dt);
                              }}
                            />
                          </div>
                          <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-6 pb-2">
                            <label className="form-label fs-4 required fw-bold">
                              Time Spent On Task (Minutes)
                            </label>
                            <input
                              type="number"
                              className={clsx("form-control", {
                                "is-invalid":
                                  Boolean(errors.timeSpentOnTask) &&
                                  touched.timeSpentOnTask,
                              })}
                              name="timeSpentOnTask"
                              id="timeSpentOnTask"
                              value={values.timeSpentOnTask}
                              onChange={handleChange}
                            />
                            <div className="text-danger text-align-top-center mt-2">
                              <ErrorMessage name="timeSpentOnTask" />
                            </div>
                          </div>
                          {values.howTicketClosed === "ON_SITE" ? (
                            <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-6 pb-2">
                              <label className="form-label fs-4 required fw-bold">
                                Travel Time (Minutes)
                              </label>
                              <input
                                className={clsx("form-control", {
                                  "is-invalid":
                                    Boolean(errors.travelTime) &&
                                    touched.travelTime,
                                })}
                                type="number"
                                value={values.travelTime}
                                name="travelTime"
                                id="travelTime"
                                onChange={handleChange}
                              />
                              <div className="text-danger text-align-top-center ms-1 mt-2">
                                <ErrorMessage name="travelTime" />
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="row">
                          <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4 mt-lg-0 mt-md-0 mt-3">
                            <label className="form-label d-block fw-bold fs-4 ps-1 pb-2">
                              Customer Satisfaction
                            </label>
                            {Array.from(satisfactionDB).map(
                              (value: any, id) => (
                                <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-inline-flex mt-1 ms-2"  key={`satisfaction-${id}`}>
                                  <label className="ms-2 fs-5" key={id}>
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="customerSatisfaction"
                                      value={value.id}
                                      checked={
                                        values.customerSatisfaction === value.id
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "customerSatisfaction",
                                          value.id
                                        )
                                      }
                                    />
                                    <span className="form-check-label fs-5 mb-0 ms-2">
                                      {value.name}
                                    </span>
                                  </label>
                                </div>
                              )
                            )}
                          </div>
                          {values.howTicketClosed !== "REMOTE" ? (
                            <>
                              <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4 mt-lg-0 mt-md-0 mt-3">
                                <label className="form-label d-block fw-bold fs-4 ps-1 pb-2">
                                  Cleanliness
                                </label>
                                {Array.from(cleanlinessDB).map(
                                  (value: any, id) => (
                                    <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-inline-flex mt-1 ms-2" key={`cleanliness-${id}`}>
                                      <label className="ms-2 fs-5" key={id}>
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="cleanliness"
                                          value={value.id}
                                          checked={
                                            values.cleanliness === value.id
                                          }
                                          onChange={() =>
                                            setFieldValue(
                                              "cleanliness",
                                              value.id
                                            )
                                          }
                                        />
                                        <span className="form-check-label fs-5 mb-0 ms-2">
                                          {value.name}
                                        </span>
                                      </label>
                                    </div>
                                  )
                                )}
                              </div>

                              <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4 mt-lg-0 mt-md-0 mt-3">
                                <label className="form-label d-block fw-bold fs-4 ps-1 pb-2">
                                  Traffic Rate
                                </label>
                                {Array.from(trafficRateDB).map(
                                  (value: any, id) => (
                                    <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-inline-flex mt-1 ms-2" key={`traffic-rate-${id}`}>
                                      <label className="ms-2 fs-5" key={id}>
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="trafficRate"
                                          value={value.id}
                                          checked={
                                            values.trafficRate === value.id
                                          }
                                          onChange={() =>
                                            setFieldValue(
                                              "trafficRate",
                                              value.id
                                            )
                                          }
                                        />
                                        <span className="form-check-label fs-5 mb-0 ms-2">
                                          {value.name}
                                        </span>
                                      </label>
                                    </div>
                                  )
                                )}
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4 mt-lg-0 mt-md-0 mt-3">
                            <label className="form-label d-block fw-bold fs-4 ps-1 pb-2">
                              Staff Enthusiasm
                            </label>
                            {Array.from(enthusiasmDB).map((value: any, id) => (
                              <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-inline-flex mt-1 ms-2" key={`enthusiam-${id}`}>
                                <label className="ms-2 fs-5" key={id}>
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="staffEnthusiasm"
                                    value={value.id}
                                    checked={
                                      values.staffEnthusiasm === value.id
                                    }
                                    onChange={() =>
                                      setFieldValue("staffEnthusiasm", value.id)
                                    }
                                  />
                                  <span className="form-check-label fs-5 mb-0 ms-2">
                                    {value.name}
                                  </span>
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="row">
                    <div className="col-xl-3 col-lg-3 col-md-4 col-12 px-xl-5 p-2 mt-4">
                      <button
                        type="submit"
                        className="btn btn-primary me-4 fs-4 w-100"
                      >
                        {loading && (
                          <span
                            className="indicator-progress"
                            style={{ display: "block" }}
                          >
                            Please Wait..
                            <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                          </span>
                        )}
                        {!loading && (
                          <span className="indicator-label">SUBMIT</span>
                        )}
                      </button>
                      <div>
                      <Suspense>
                          <Modal open={open} onClose={handleClose} role="modal"
                            center
                            classNames={{
                              root: "content-wrapper",
                            }}>
                            <div className='modal-title'></div>
                              <div className="modal-body p-2">
                                <h4 className=" fs-4 mb-8 mt-4">This machine is already activated at "location" do you want to replace machine</h4>
                              </div>
                            <div className="modal-footer justify-content-center p-2">
                              <button className="btn btn-primary me-4 ml-4 col-3 mt-4" onClick={() => replaceMachine()}>Yes </button>
                              <button className="btn btn-secondary col-3 mt-4" onClick={() => handleClose()}>No </button>
                            </div>
                          </Modal>
                        </Suspense>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            )}}
          </Formik>
        </div>
        <Toaster
          position="bottom-left"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            className: "",
            duration: 4000,
          }}
        />
      </Suspense>
    </>
  );
};
export default StatusChange;

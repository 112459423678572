import {Toaster} from "react-hot-toast";
import Modal from "react-responsive-modal";
import  {FC, Suspense} from "react";

type Props = {
  currentlyOpenRecord: any;
  viewQuestionModal: boolean;
  handleViewQuestionClose: () => void;
};

export const ViewQuestion: FC<Props> = ({
  currentlyOpenRecord,
  viewQuestionModal,
  handleViewQuestionClose,
}) => {
  return (
    <Suspense>
      <Modal
        open={viewQuestionModal}
        onClose={() => handleViewQuestionClose()}
        role="modal"
        center
        classNames={{root: "content-wrapper"}}
        styles={{modal: {width: "100%"}}}
      >
        <>
          <div className="modal-header p-2 pb-4 mb-4">
            <h4 className="modal-title fs-1">{currentlyOpenRecord?.title}</h4>
          </div>

          <div className="modal-body">
            <div className="row ">
              {currentlyOpenRecord?.questions.map((a: any, index: number) => (
                <div className="border mt-2" key={index}>
                  <div className="row">
                    <div className="col-md-4 col-lg-3 col-12">
                      <label className="form-label fs-4 fw-bold ps-1 mt-2">
                        Question Type:
                      </label>
                    </div>
                    <div className="col-md-8 col-lg-9 col-12">
                      <div className="fs-4 ps-1 mt-2">{a.answerType}</div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 col-lg-3 col-12">
                      <label className="form-label fs-4 fw-bold ps-1 mt-2">
                        Question:
                      </label>
                    </div>
                    <div className="col-md-8 col-lg-9 col-12">
                      <div className="fs-4 ps-1 mt-2">{a.question}</div>
                    </div>
                  </div>
                  {a.answerType === "Button" && (
                    <>
                      <div className="row">
                        <div className="col-md-4 col-lg-3 col-12">
                          <label className="form-label fs-4 fw-bold ps-1 mt-2">
                            Answers:
                          </label>
                        </div>
                        <div className="col-md-8 col-lg-9 col-12">
                          <ul className="fs-4 mt-2">
                            {a.answers.map((row: any, idx: number) => (
                              <li key={idx}>{row?.answer}</li>
                            ))}
                          </ul>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-4 col-lg-3 col-12">
                          <label className="form-label fs-4 fw-bold ps-1 mt-2">
                            Is Multi Answer?:
                          </label>
                        </div>
                        <div className="col-md-8 col-lg-9 col-12">
                          <div className="fs-4 ps-1 mt-2">
                            {a?.isMultiAnswer ? "Yes" : "No"}
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {a.answerType === "Numeric_Scale" && (
                    <>
                      <div className="row">
                        <div className="col-md-4 col-lg-3 col-12">
                          <label className="form-label fs-4 fw-bold ps-1 mt-2">
                            From Range:
                          </label>
                        </div>
                        <div className="col-md-8 col-lg-9 col-12">
                          <div className="fs-4 ps-1 mt-2">{a.fromRange}</div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4 col-lg-3 col-12">
                          <label className="form-label fs-4 fw-bold ps-1 mt-2">
                            To Range:
                          </label>
                        </div>
                        <div className="col-md-8 col-lg-9 col-12">
                          <div className="fs-4 ps-1 mt-2">{a.toRange}</div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4 col-lg-3 col-12">
                          <label className="form-label fs-4 fw-bold ps-1 mt-2">
                            Start Label:
                          </label>
                        </div>
                        <div className="col-md-8 col-lg-9 col-12">
                          <div className="fs-4 ps-1 mt-2">{a.startLabel}</div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4 col-lg-3 col-12">
                          <label className="form-label fs-4 fw-bold ps-1 mt-2">
                            End Label:
                          </label>
                        </div>
                        <div className="col-md-8 col-lg-9 col-12">
                          <div className="fs-4 ps-1 mt-2">{a.endLabel}</div>
                        </div>
                      </div>
                    </>
                  )}

                  {a.answerType === "Sliding_Scale" && (
                    <>
                      <div className="row">
                        <div className="col-md-4 col-lg-3 col-12">
                          <label className="form-label fs-4 fw-bold ps-1 mt-2">
                            From Range:
                          </label>
                        </div>
                        <div className="col-md-8 col-lg-9 col-12">
                          <div className="fs-4 ps-1 mt-2">{a.fromRange}</div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4 col-lg-3 col-12">
                          <label className="form-label fs-4 fw-bold ps-1 mt-2">
                            To Range:
                          </label>
                        </div>
                        <div className="col-md-8 col-lg-9 col-12">
                          <div className="fs-4 ps-1 mt-2">{a.toRange}</div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4 col-lg-3 col-12">
                          <label className="form-label fs-4 fw-bold ps-1 mt-2">
                            Start Label:
                          </label>
                        </div>
                        <div className="col-md-8 col-lg-9 col-12">
                          <div className="fs-4 ps-1 mt-2">{a.startLabel}</div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4 col-lg-3 col-12">
                          <label className="form-label fs-4 fw-bold ps-1 mt-2">
                            End Label:
                          </label>
                        </div>
                        <div className="col-md-8 col-lg-9 col-12">
                          <div className="fs-4 ps-1 mt-2">{a.endLabel}</div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
        </>
      </Modal>

      <Toaster
        position="bottom-left"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          className: "",
          duration: 3000,
        }}
      />
    </Suspense>
  );
};

import "jquery/dist/jquery.min.js";
import "react-datetime/css/react-datetime.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import Moment from "moment";
import { Toaster } from "react-hot-toast";
import { Component } from "react";
import { AUTH_LOCAL_STORAGE_KEY } from "../../../../../app/modules/auth";

export default class FeedBack extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      addLoading: false,
    };
  }

  async componentWillMount() { }
  async componentDidUpdate() { }

  async componentDidMount() {
    this.renderDataTable([]);
  }
  renderDataTable = (rows: any) => {
    const eventId = this.props.eventId;
    const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
    let authToken = "";
    if (lsValue) {
      const user = JSON.parse(lsValue);
      authToken = user.access_token;
    }
    $(document).ready(function () {
      $("#eventTable").DataTable().destroy();
      $("#eventTable").DataTable({
        pagingType: "full_numbers",
        serverSide: true,
        pageLength: 50,
        paging: true,
        searching: false,
        "ajax": {
          "url": process.env.REACT_APP_API_URL,
          "type": "POST",
          "contentType": "application/json",
          "beforeSend": function (xhr) {
            xhr.setRequestHeader('Authorization', "Bearer " + authToken);
          },
          "data": function (d: any) {
            var gql = `
              query getSurveyResultByEvent(
                $id:String!
                $paginate:PaginatorArgs
              ){
                getSurveyResultByEvent(id:$id, paginate: $paginate) {
                  data {
                   id
                    sessionId
                    surveyId
                    totalQuestion
                    totalAnswer
                    rating
                    feedback
                    createdAt
                  }
                  totalPages
                  total
                  perPage
                  page
                }
              }
            `;
            const currentPage = (d?.start) ? (d.start / d.length) + 1 : 1;
            const length = d?.length > 0 ? d.length : 50;
            var query = {
              "query": gql,
              "variables": {
                paginate: {
                  "page": currentPage,
                  "per_page": length
                },
                id: eventId,
              }
            };
            return JSON.stringify(query);
          },
          "dataSrc": function (json) {
            json.recordsTotal = json.data.getSurveyResultByEvent.total;
            json.recordsFiltered = json.data.getSurveyResultByEvent.total;
            const rows: any[] = [];
            const records = json.data.getSurveyResultByEvent.data;
            let counter = 0;
            for (const record of records) {
              counter++;
              let createdAt = "";
              if (record.createdAt) {
                createdAt = Moment.utc(record.createdAt).local().format("YYYY-MM-DD HH:mm:ss");
              }

              const tempRecord: any = {
                id: counter,
                recordId: record.id,
                sessionId: record.sessionId,
                createdAt,
                totalQuestion: record.totalQuestion,
                totalAnswer: record.totalAnswer,
                rating: record.rating,
                feedback: record.feedback,
                surveyId: record.surveyId,
                record: record,
              };
              rows.push(tempRecord);
            }
            return rows;
          }
        },
        columns: [
          {
            name: "index",
            render: function (data, type, row) {
              return row.id;
            },
          },
          {
            name: "sessionId",
            render: function (data, type, row) {
              return row.sessionId;
            },
          },
          {
            name: "totalQuestion",
            render: function (data, type, row) {
              return row.totalQuestion;
            },
          },
          {
            name: "totalAnswer",
            render: function (data, type, row) {
              return row.totalAnswer;
            },
          },
          {
            name: "rating",
            render: function (data, type, row) {
              return row.rating;
            },
          },
          {
            name: "feedback",
            render: function (data, type, row) {
              return row.feedback;
            },
          },
          {
            name: "createdAt",
            render: function (data, type, row) {
              return Moment.utc(row.createdAt).local().format("YYYY-MM-DD HH:mm:ss");
            },
          },
        ],
        processing: true,
        dom: "Bfrtip",
        order: [[0, "desc"]],
        lengthMenu: [
          [10, 20, 30, 50, -1],
          [10, 20, 30, 50, "All"],
        ],
      });
    });
  };

  refreshTable = async () => {
    this.renderDataTable([]);
  }

  render() {
    return (
      <div>
        <div className="card ">
          {/* table */}
          <div className="card-body w-100 p-lg-7 p-md-7 p-5">
            <div className="row">
              <div className="col-12">
                <div className="table-responsive p-0 pb-2 fs-6" id="data-table">
                  <div className="row align-items-center justify-content-between mb-4">
                    <div className="col-xl-2 col-lg-3 col-md-4 col-12">
                      <h3 className="fw-semibold m-0">Survey Summary</h3>
                    </div>
                  </div>
                  <table
                    id="eventTable"
                    className="table align-items-center display justify-content-center mb-0 w-100"
                  >
                    <thead>
                      <tr>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Sr No.
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          SessionId
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Total Question
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Total Answer
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Rating
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          FeedBack
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Created At
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <Toaster
            position="bottom-left"
            reverseOrder={false}
            gutter={8}
            containerClassName=""
            containerStyle={{}}
            toastOptions={{
              className: "",
              duration: 3000,
            }}
          />
        </div>
      </div>
    );
  }
}

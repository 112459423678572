import Modal from "react-responsive-modal";
import { useNavigate } from "react-router-dom";
import React, { FC, Suspense } from "react";


type Props = {
    currentlyOpenRecord: any;
    signatureImageModal: boolean;
    handleSurveyClose: () => void;
    refreshTable?: any;
};
export const SignatureImage: FC<Props> = ({
    currentlyOpenRecord,
    signatureImageModal,
    handleSurveyClose,
    refreshTable,
}) => {
    const navigate = useNavigate();
    if (!currentlyOpenRecord) {
        navigate("/workOrder");
    }
    return (
        <Suspense>
            <Modal
                open={signatureImageModal}
                onClose={() => handleSurveyClose()}
                role="modal"
                center
                classNames={{ root: "content-wrapper" }}
            >
                <div>
                    <img
                        src={currentlyOpenRecord}
                        alt={`signature`}
                        className=""
                    />
                </div>
            </Modal>
        </Suspense>
    );
};

import React, { useState } from "react";
import { KTSVG } from "../../../_metronic/helpers";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { getRegion } from "../../models/_location";
import { getLocations } from "../../models/_location";
import ReactSelect from "react-select";
import * as _ from "lodash";
import moment from "moment";
import { getCustomers } from "../../models/_customer";
import {
  getTicketSummary,
  getTicketDetailSummary
} from "../../models/_ticketSummary";
import toast, { Toaster } from "react-hot-toast";
import { getMachineStatusList, getMachines } from "../../models/_machine";
import IssueReportedChart from '../../components/TicketSummary/IssueReportedChart'
import MarketingChart from '../../components/TicketSummary/MarketingChart'
import OperationsChart from '../../components/TicketSummary/OperationsChart'
import MarketingServicesChart from '../../components/TicketSummary/MarketingServicesChart'
import { Option } from '../../../_metronic/helpers/MySelect';

function TicketSummary() {
  const endDate = moment().subtract(1, 'weeks').endOf('isoWeek').toDate();
  const startDate = moment().subtract(1, 'weeks').startOf('isoWeek').toDate();
  const [addLoading, setAddLoading] = useState(false);
  const [filterFromDate, setFilterFromDate] = useState(startDate);
  const [filterToDate, setFilterToDate] = useState(endDate);
  const [filterLoading, setFilterLoading] = useState(false);
  const [locationRegionDB, setLocationRegionDB] = useState([]);
  const [locationDB, setLocationDB] = useState([]);
  const [filterRegion, setFilterRegion] = useState("");
  const [filterLocation, setFilterLocation] = useState("");
  const [locationRegionSelected, setLocationRegionSelected] = useState(null)
  const [locationSelected, setLocationSelected] = useState(null)
  const [machineStatusSelected, setMachineStatusSelected] = useState<any>(null)
  const [filterMachineStatus, setFilterMachineStatus] = useState("")
  const [machineStatusDB, setMachineStatusDB] = useState<any>([])
  const [customerDB, setCustomerDB] = useState([])
  const [filterCustomer, setFilterCustomer] = useState("")
  const [customerSelected, setCustomerSelected] = useState(null)
  const [ticketSummary, setTicketSummary] = useState([])
  const [machineDB, setMachineDB] = useState([])
  const [filterMachine, setFilterMachine] = useState("")
  const [machineSelected, setMachineSelected] = useState(null)
  const [issueReportedResult, setIssueReportedResult] = useState<any>([])
  const [marketingResult, setMarktingResult] = useState<any>([])
  const [operationResult, setOprationResult] = useState<any>([])
  const [marketingServicesResult, setMarktingServicesResult] = useState<any>([])

  const ticketDetailData = async (filter: any) => {
    try {
      const ticketDetailSummaryrecord = await getTicketDetailSummary({ ...filter, fromDate: filterFromDate, toDate: filterToDate })
      const issueReportedData: any = ticketDetailSummaryrecord?.issueReported ?? []
      setIssueReportedResult(issueReportedData)
      const marketingData: any = ticketDetailSummaryrecord?.marketings ?? []
      setMarktingResult(marketingData)
      const operationData: any = ticketDetailSummaryrecord?.operationsDone ?? []
      setOprationResult(operationData)
      const marketingSerivesData: any = ticketDetailSummaryrecord?.machineServicesDone ?? []
      setMarktingServicesResult(marketingSerivesData)
    } catch (error) {
      console.log(error)
    }

  }
  const ticketData = async (filter: any) => {
    try {
      const ticketSummaryRecords = await getTicketSummary({ ...filter, fromDate: filterFromDate, toDate: filterToDate });
      const TicketSummaryResult = ticketSummaryRecords ?? []
      setTicketSummary(TicketSummaryResult)

    } catch (error) {
      console.log(error)
    }
  }
  const fetchData = async () => {
    try {
      const locationRegionRecord = await getRegion();
      const locationRegionResults: any = locationRegionRecord
        ? locationRegionRecord
        : [];
      locationRegionResults.sort(function (a: any, b: any) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      const machineRecord = await getMachines();
      const machineResult: any = machineRecord?.data ? machineRecord?.data : [];
      machineResult.sort(function (a: any, b: any) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      const locationIdRecord = await getLocations();
      const locationIdResults: any = locationIdRecord?.data ? locationIdRecord?.data : [];

      locationIdResults.sort(function (a: any, b: any) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      const machineStatusRecord = await getMachineStatusList();
      const machineStatusResults: any = machineStatusRecord ? machineStatusRecord : [];
      machineStatusResults.sort(function (a: any, b: any) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      const customerRecord = await getCustomers();
      const customerResults: any = customerRecord?.data ? customerRecord?.data : [];
      customerResults.sort(function (a: any, b: any) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      setLocationDB(locationIdResults);
      setLocationRegionDB(locationRegionResults);
      setMachineStatusDB(machineStatusResults)
      setCustomerDB(customerResults)
      setMachineDB(machineResult)

    } catch (error) {
      console.log(error)
    }
  };


  const searchData = () => {
    let hasErrors = false;
    if (filterFromDate === undefined) {
      hasErrors = true;
      toast.error("From date is required");
    }
    if (filterToDate === undefined) {
      hasErrors = true;
      toast.error("To date is required");
    }

    if (hasErrors) {
      return false;
    }
    let filter: any = {};
    if (filterRegion) {
      filter["regionId"] = filterRegion;
    }
    if (filterLocation.length > 0) {
      filter["locationId"] = filterLocation
    }
    if (filterCustomer.length > 0) {
      filter["customerId"] = filterCustomer
    }
    if (filterMachineStatus.length > 0) {
      filter["machineStatusId"] = filterMachineStatus

    }
    if (filterMachine.length > 0) {
      filter["machineId"] = filterMachine

    }
    if (filterFromDate) {
      filter["fromDate"] = filterFromDate

    }
    if (filterToDate) {
      filter["toDate"] = filterToDate

    }
    setAddLoading(true);
    ticketData(filter)
    ticketDetailData(filter)
    setAddLoading(false);

  }
  const removeSearch = () => {
    if (filterFromDate === undefined) {
      setFilterFromDate(startDate)
    }
    if (filterToDate === undefined) {
      setFilterToDate(endDate)
    }
    setFilterLoading(true);
    setFilterFromDate(startDate);
    setFilterToDate(endDate);
    setLocationRegionSelected(null);
    setLocationSelected(null);
    setCustomerSelected(null);
    setMachineSelected(null);
    setMachineStatusSelected(null);
    setFilterRegion("");
    setFilterMachine("")
    setFilterLocation("");
    setFilterCustomer("");
    setFilterMachineStatus("");
    setIssueReportedResult([]);
    setMarktingResult([]);
    setOprationResult([]);
    setMarktingServicesResult([]);
    ticketData({});
    ticketDetailData({});
    setFilterLoading(false);
  };


  React.useEffect(() => {

    searchData()
    fetchData();
    // eslint-disable-next-line
  }, []);
  ;

  return (
    <div>
      <div id="kt_app_toolbar" className="app-toolbar py-2 pt-4">
        <div
          id="kt_app_toolbar_container"
          className="app-container container-xxl d-block d-lg-flex d-md-flex d-xl-flex flex-stack px-3"
          style={{ maxWidth: "100%" }}
        >
          <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
            <h1 className="page-heading d-flex text-dark fw-bold fs-1 flex-column justify-content-center my-0">
              Ticket Summary
            </h1>
          </div>
        </div>
      </div>

      <div className="card mt-9">
        <div className="card-body w-100 p-lg-7 p-md-7 p-5">
          <div className="row align-items-center justify-content-between">
            <div className="col-xl-2 col-lg-3 col-md-4 col-12">
              <h3 className="btn btn-bg-light fw-bold m-0 mb-3 p-0 px-5 py-3 align-items-center card-title text-center">
                <KTSVG
                  path="/media/icons/duotune/general/gen031.svg"
                  className="svg-icon-4 me-1"
                />
                Filter
              </h3>
            </div>
          </div>
          <div className="row mt-6">
            <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 ps-xl-5 mb-3">
              <label className="form-label fs-4 fw-bold">From Date</label>
              <Datetime
                closeOnSelect
                dateFormat="YYYY-MM-DD"
                timeFormat="HH:mm"
                initialValue={filterFromDate}
                onChange={(e: any) => {
                  const dt = e["_d"];
                  setFilterFromDate(dt);
                }}
              />
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
              <label className="form-label fs-4 fw-bold">To Date</label>
              <Datetime
                closeOnSelect
                dateFormat="YYYY-MM-DD"
                timeFormat="HH:mm"
                initialValue={filterToDate}
                onChange={(e: any) => {
                  const dt = e["_d"];
                  setFilterToDate(dt);
                }}
              />
            </div>

            <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
              <label className="form-label fs-4 fw-bold">Region</label>
              <ReactSelect
                className="react-select"
                placeholder=""
                classNamePrefix="my-react-select"
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                onChange={(selected: any) => {
                  const value = selected?.map((e: any) => e.value);
                  setLocationRegionSelected(selected)
                  setFilterRegion(value)
                }}
                components={{
                  Option,
                }}
                value={locationRegionSelected}
                options={Array.from(locationRegionDB).map(
                  (value: any) => {
                    return {
                      value: value.id,
                      label: value.name,
                    };
                  }
                )}
              />

            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
              <label className="form-label fs-4 fw-bold">Customers</label>
              <ReactSelect
                className="react-select"
                placeholder=""
                classNamePrefix="my-react-select"
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                onChange={(selected: any) => {
                  const value = selected?.map((e: any) => e.value);
                  setCustomerSelected(selected)
                  setFilterCustomer(value)
                }}
                components={{
                  Option,
                }}
                value={customerSelected}
                options={Array.from(customerDB).map(
                  (value: any) => {
                    return {
                      value: value.id,
                      label: value.name,
                    };
                  }
                )}
              />
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
              <label className="form-label fs-4 fw-bold">Locations</label>
              <ReactSelect
                className="react-select"
                placeholder=""
                classNamePrefix="my-react-select"
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                onChange={(selected: any) => {
                  const value = selected?.map((e: any) => e.value);
                  setLocationSelected(selected)
                  setFilterLocation(value)
                }}
                components={{
                  Option,
                }}
                value={locationSelected}
                options={Array.from(locationDB).map(
                  (value: any) => {
                    return {
                      value: value.id,
                      label: value.name,
                    };
                  }
                )}
              />

            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
              <label className="form-label fs-4 fw-bold">Machine Name</label>
              <ReactSelect
                className="react-select"
                placeholder=""
                classNamePrefix="my-react-select"
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                onChange={(selected: any) => {
                  const value = selected?.map((e: any) => e.value);
                  setMachineSelected(selected)
                  setFilterMachine(value)
                }}
                components={{
                  Option,
                }}
                value={machineSelected}
                options={Array.from(machineDB).map(
                  (value: any) => {
                    return {
                      value: value.id,
                      label: value.name,
                    };
                  }
                )}
              />
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
              <label className="form-label fs-4 fw-bold">Machine Status</label>
              <ReactSelect
                className="react-select"
                placeholder=""
                classNamePrefix="my-react-select"
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                onChange={(selected: any) => {
                  const value = selected?.map((e: any) => e.value);
                  setMachineStatusSelected(selected)
                  setFilterMachineStatus(value)
                }}
                components={{
                  Option,
                }}
                value={machineStatusSelected}
                options={Array.from(machineStatusDB).map(
                  (value: any) => {
                    return {
                      value: value.id,
                      label: _.capitalize(_.lowerCase(value.name)),
                    };
                  }
                )}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
              <button
                type="submit"
                onClick={() => searchData()}
                className="btn btn-primary me-4 fs-4 p-0 w-100 px-6 py-3"
              >
                {addLoading && (
                  <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    Please Wait..
                    <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                  </span>
                )}
                {!addLoading && (
                  <span className="indicator-label">ADD FILTER</span>
                )}
              </button>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
              <button
                type="submit"
                onClick={() => removeSearch()}
                className="btn btn-primary me-4 fs-4 p-0 w-100 px-6 py-3"
              >
                {filterLoading && (
                  <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    Please Wait..
                    <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                  </span>
                )}
                {!filterLoading && (
                  <span className="indicator-label">RESET FILTER</span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        {issueReportedResult.length > 0 ? (
          <div className="col-xl-6 col-lg-6 col-md-6 col-12 mt-3">
            <div className="card mt-6 mx-3">
              <div className="card-body d-flex flex-column p-7">
                <div
                  className="btn-bg-light btn-sm btn fs-3 fw-semibold text-start mb-6 border-0"
                >
                  Issue Reported
                </div>
                <div className="d-flex flex-column me-2">

                  <IssueReportedChart issueReportedResult={issueReportedResult} />
                </div>
              </div>
            </div>
          </div>) : (null)}
        <div className="col-xl-6 col-lg-6 col-md-6 col-12 mt-3">
          <div className="card mt-6 mx-3">
            <div className="card-body d-flex flex-column p-7">
              <div
                className="btn-bg-light btn-sm btn fs-3 fw-semibold text-start mb-6 border-0"
              >
                Marketing Services
              </div>
              <div className="d-flex flex-column me-2">

                <MarketingServicesChart marketingServicesResult={marketingServicesResult} />
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-12 mt-3">
          <div className="card mt-6 mx-3">
            <div className="card-body d-flex flex-column p-7">
              <div
                className="btn-bg-light btn-sm btn fs-3 fw-semibold text-start mb-6 border-0"
              >
                Operation
              </div>
              <div className="d-flex flex-column me-2">

                <OperationsChart operationResult={operationResult} />
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-12 mt-3">
          <div className="card mt-6 mx-3">
            <div className="card-body d-flex flex-column p-7">
              <div
                className="btn-bg-light btn-sm btn fs-3 fw-semibold text-start mb-6 border-0"
              >
                Marketing
              </div>
              <div className="d-flex flex-column me-2">

                <MarketingChart marketingResult={marketingResult} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
          <div className="card mt-9">
            <div className="card-body w-100 p-lg-7 p-md-7 p-5">
              <div className="table-responsive p-0 pb-2 fs-6 border-0 dashboardtable">
                <div className="col-xl-2 col-lg-3 col-md-4 col-12">
                  <h3 className="btn btn-bg-light fw-bold m-0 mb-3 p-0 px-5 py-3 align-items-center card-title text-center">
                    <KTSVG
                      path="/media/icons/duotune/general/gen031.svg"
                      className="svg-icon-4 me-1"
                    />
                    Ticket Summary
                  </h3>
                </div>
                <table className="table dashboard-table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                  <thead>
                    <tr className="fs-5 fw-bold">
                      <th className="fs-5 text-muted">Ticket Type</th>
                      <th className="fs-5 text-muted">Total Tickets</th>
                      <th className="fs-5 text-muted">Closed Tickets</th>
                      <th className="fs-5 text-muted">Inprocess Tickets</th>
                      <th className="fs-5 text-muted">Pending Tickets</th>
                      <th className="fs-5 text-muted">Closed In 72Hrs</th>
                      <th className="fs-5 text-muted">Open Since 3Days</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ticketSummary.length > 0 ? (
                      Array.from(ticketSummary).map((ticketSummary: any) => (
                        <>
                          <tr className="dashboard-body">
                            <td data-title="Ticket Type">
                              {_.capitalize(_.lowerCase(ticketSummary?.ticketType ?? ""))}
                            </td>
                            <td data-title="Total Tickets">
                              {ticketSummary?.totalTickets ?? ""}
                            </td>
                            <td data-title="Closed Tickets">
                              {ticketSummary?.closedTickets ?? ""}
                            </td>

                            <td data-title="Inprocess Tickets">
                              {ticketSummary?.inprocessTickets ?? ""}
                            </td>

                            <td data-title="Pending Tickets">
                              {ticketSummary?.pendingTickets ?? ""}
                            </td>

                            <td data-title="Closed In 72Hrs">
                              {ticketSummary?.closedIn72Hrs ?? ""}
                            </td>
                            <td data-title="Open Since 3Days">
                              {ticketSummary?.openSince3Days ?? ""}
                            </td>
                          </tr>
                        </>
                      ))
                    ) : (
                      <>
                        <tr>
                          <td align="center" colSpan={4}>
                            No records found
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* toast message */}
      <Toaster
        position="bottom-left"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          className: "",
          duration: 3000,
        }}
      />
    </div>
  )
}

export default TicketSummary


import clsx from "clsx";
import * as yup from "yup";
import moment from 'moment';
import { Formik } from "formik";
import Select from "react-select";
import Datetime from "react-datetime";
import { ErrorMessage } from "formik";
import ReactSelect from 'react-select';
import Modal from 'react-responsive-modal';
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from "react-hot-toast";
import { updateEvent } from '../../../models/_event';
import { getSurveyList } from '../../../models/_survey';
import { getLocations } from '../../../models/_location';
import { Option } from '../../../../_metronic/helpers/MySelect';
import { FC, useState, Suspense, useEffect } from 'react'

type Props = {
  currentEventId: string;
  currentlyOpenRecord: any;
  eventEditModal: boolean;
  handleEventClose: () => void;
  refreshTable?: any
};

const editCustomerSchema = yup.object().shape({
  title: yup.string().required("Title is required"),
  startDate: yup.date().required("Event start date is required"),
  endDate: yup.date().required("Event end date is required")
    .when('startDate', (startDate, schema) => {
      return schema.min(startDate, 'Event end date must be greater than or equal to event start date');
    }),
  formPin: yup.string().required("Form Pin is required"),
  locationId: yup.array().min(1, "Location is required")
});

export const EditEvent: FC<Props> = ({
  currentEventId,
  currentlyOpenRecord,
  eventEditModal,
  handleEventClose,
  refreshTable
}) => {
  const navigate = useNavigate();
  if (!currentEventId) {
    navigate("/masters/events/list");
  }

  if (currentlyOpenRecord == null || currentlyOpenRecord === undefined || currentlyOpenRecord === 'undefined') {
    navigate("/masters/events/list");
  }

  const [surveyDB, setSurveyDB] = useState([]);
  const [locationDB, setLocationDB] = useState([]);
  const [loading, setLoading] = useState(false);
  
  const location = currentlyOpenRecord.locations.map((id: any) => ({
    value: id.id,
    label: id.name,
  }));

  const fetchData = async () => {
    try {
      const locationRecord = await getLocations();
      const LocationData = locationRecord?.data
      setLocationDB(LocationData)
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const loadSurveyData = async () => {
      const data = await getSurveyList();
      setSurveyDB(data);
    };
    loadSurveyData();
    fetchData();
  }, []);

  return (
    <Suspense>
      <Modal
        open={eventEditModal}
        onClose={() => handleEventClose()}
        role="modal"
        center
        classNames={{ root: "content-wrapper" }}
      >
        <Formik
          enableReinitialize
          initialValues={{
            id: currentlyOpenRecord?.id,
            title: currentlyOpenRecord.title,
            description: currentlyOpenRecord.description,
            venue: currentlyOpenRecord.venue,
            startDate: moment.utc(currentlyOpenRecord.startDate).local().format("YYYY-MM-DD HH:mm:ss"),
            endDate: moment.utc(currentlyOpenRecord.endDate).local().format("YYYY-MM-DD HH:mm:ss"),
            surveyId: currentlyOpenRecord.surveyId,
            formPin: currentlyOpenRecord.formPin,
            locationId: currentlyOpenRecord.locations.map((loc: any) => { return loc.id }),
            survey: surveyDB.find((a: any) => currentlyOpenRecord.surveyId === a.id)
          }}
          validationSchema={editCustomerSchema}
          onSubmit={async (values, { resetForm, setSubmitting }) => {
            setLoading(true);

            const updateData: any = {
              title: values.title,
              description: values.description,
              venue: values.venue,
              startDate: moment(values.startDate).utc().format("YYYY-MM-DD HH:mm:ss"),
              endDate: moment(values.endDate).utc().format("YYYY-MM-DD HH:mm:ss"),
              surveyId: values.surveyId,
              formPin: values.formPin,
              locationId: values.locationId,
            };
            const result = await updateEvent(values.id, updateData);

            if (result?.data?.data?.updateEvent) {
              setLoading(false)
              toast.success("Event updated successfully");
              handleEventClose()
              resetForm()
              if (typeof refreshTable !== 'undefined') {
                refreshTable()
              }
            } else {
              setLoading(false)
              const messages = result?.data?.errors.map((e: any) => e.message);
              toast.error(messages.join(","));
            }
          }}
        >
          {({
            handleChange,
            handleSubmit,
            values,
            errors,
            touched,
            setFieldValue,
          }) => (
            <form
              id="kt_modal_add_lesson_form"
              className="form"
              onSubmit={handleSubmit} autoComplete="off"
              noValidate
            >
              <div className="modal-header p-2 pb-4 mb-4">
                <h4 className="modal-title fs-1">
                  Edit Event
                  {currentlyOpenRecord?.uuid}
                </h4>
              </div>

              <div className="modal-body">
                <div className="row">

                  <div className="col-lg-6 col-md-12 col-12 mt-3 pt-2">
                    <label className="form-label fs-4 required fw-bold">
                      Title
                    </label>
                    <input
                      className={clsx("form-control", {
                        "is-invalid": Boolean(errors.title) && touched.title,
                      })}
                      name="title"
                      id="title"
                      value={values.title}
                      onChange={handleChange}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="name" />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-12 col-12 mt-3 pt-2">
                    <label className="form-label fs-4 fw-bold">
                      Description
                    </label>
                    <textarea
                      className="form-control"
                      name="description"
                      id="description"
                      value={values.description}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-lg-6 col-md-12 col-12 mt-3 pt-2">
                    <label className="form-label fs-4 fw-bold">
                      Venue
                    </label>
                    <input
                      className="form-control"
                      name="venue"
                      id="venue"
                      value={values.venue}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-lg-6 col-md-12 col-12 mt-3 pt-2">
                    <label className="form-label fs-4 fw-bold">
                      Survey
                    </label>
                    <Select
                      className={clsx("react-select", {
                        "is-invalid":
                          Boolean(errors.surveyId) &&
                          touched.surveyId,
                      })}
                      defaultValue={{
                        label: currentlyOpenRecord?.survey?.title,
                        value: values.surveyId
                      }}
                      classNamePrefix="my-react-select"
                      name="surveyId"
                      placeholder=""
                      onChange={(event: any) => {
                        setFieldValue("surveyId", event.value);
                      }}
                      options={Array.from(surveyDB).map((value: any) => {
                        return {
                          value: value.id,
                          label: value.title,
                        };
                      })}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="surveyId" />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-12 col-12 mt-3 pt-2">
                    <label className="form-label fs-4 required fw-bold">
                      Locations
                    </label>
                    <ReactSelect
                      className={clsx("react-select", {
                        "is-invalid":
                          Boolean(errors.locationId) &&
                          touched.locationId,
                      })}
                      defaultValue={location}
                      classNamePrefix="my-react-select"
                      isMulti
                      name="locationId"
                      placeholder=""
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      onChange={(event: any) => {
                        setFieldValue("locationId", event.map((a: any) => { return a.value }));
                      }}
                      components={{
                        Option,
                      }}
                      options={Array.from(locationDB).map((value: any) => {
                        return {
                          value: value.id,
                          label: value.name,
                        };
                      })}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="locationId" />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-12 col-12 mt-3 pt-2">
                    <label className="form-label fs-4 required fw-bold">
                      Form Pin
                    </label>
                    <input
                      className={clsx("form-control", {
                        "is-invalid": Boolean(errors.formPin) && touched.formPin,
                      })}
                      name="formPin"
                      id="formPin"
                      value={values.formPin}
                      onChange={handleChange}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="formPin" />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-12 col-12 mt-3 pt-2">
                    <label className="form-label fs-4 required fw-bold">Event Start Date </label>
                    <Datetime
                      dateFormat="YYYY-MM-DD"
                      timeFormat="HH:mm:ss"
                      closeOnSelect
                      value={values.startDate}
                      onChange={(e: any) => {
                        const dt = e["_d"];
                        setFieldValue("startDate", dt);
                      }}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="startDate" />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-12 col-12 mt-3 pt-2">
                    <label className="form-label fs-4 required fw-bold">Event End Date</label>
                    <Datetime
                      dateFormat="YYYY-MM-DD"
                      timeFormat="HH:mm:ss"
                      closeOnSelect
                      value={values.endDate}
                      onChange={(e: any) => {
                        const dt = e["_d"];
                        setFieldValue("endDate", dt);
                      }}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="endDate" />
                    </div>
                  </div>

                </div>
              </div>

              <div className="modal-footer mt-6 p-0 justify-content-start">
                <button
                  type="submit"
                  className="btn btn-primary me-4 fs-4 p-0 px-6 py-3 mt-6"
                >
                  {loading && (
                    <span className="indicator-progress" style={{ display: "block" }}>
                      Please Wait..
                      <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                    </span>
                  )}
                  {!loading && (
                    <span className="indicator-label">UPDATE</span>
                  )}
                </button>
              </div>

            </form>
          )}
        </Formik>
      </Modal>

      <Toaster
        position="bottom-left"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          className: "",
          duration: 3000,
        }}
      />
    </Suspense>
  )
}

import React, { useRef, useEffect, useState } from "react";
import * as d3 from "d3";

interface Data {
  previosPeriod: {
    blends: number;
    flavor: string;
    colorCode: string;
  }[];
  currentPeriod: {
    blends: number;
    flavor: string;
    colorCode: string;
  }[];
}

interface StackedBarChartProps {
  data: Data;
}

const FlavorWiseBlendChart: React.FC<StackedBarChartProps> = ({ data }) => {
  const svgRef = useRef<SVGSVGElement | null>(null);
  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const [dimensions, setDimensions] = useState<{ width: number; height: number }>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const handleResize = () => {
      if (svgRef.current) {
        const parentWidth = svgRef.current.parentElement?.clientWidth ?? 1500;
        setDimensions({
          width: parentWidth - 60, // Adjust for margins
          height: 400,
        });
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (!svgRef.current || !tooltipRef.current) return;

    // Clear any existing SVG content
    d3.select(svgRef.current).selectAll("*").remove();

    // Check if data is valid and defined
    if (!data || !data.currentPeriod || !data.previosPeriod) {
      console.error("Data is not properly defined or is missing necessary keys.");
      return;
    }

    // Combine current and previous period data
    const combinedData = data.currentPeriod.map((current, index) => {
      const prev = data.previosPeriod[index] || { blends: 0, flavor: current.flavor, colorCode: current.colorCode };
      return {
        flavor: current.flavor,
        currentPeriod: current.blends,
        previosPeriod: prev.blends,
        currentColor: current.colorCode, // Dynamic color for currentPeriod
        previosColor: prev.colorCode,    // Dynamic color for previosPeriod
      };
    });

    const { width, height } = dimensions;
    const margin = { top: 40, right: 0, bottom: 83, left: 60 }; // Increased right margin for legend

    // Create the SVG container
    const svg = d3
      .select(svgRef.current)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Define the x and y scales
    const x0 = d3
      .scaleBand()
      .domain(combinedData.map(d => d.flavor))
      .range([0, width])
      .padding(0.2);

    const x1 = d3
      .scaleBand()
      .domain(["previosPeriod", "currentPeriod"])
      .range([0, x0.bandwidth()])
      .padding(0.05);

    const y = d3
      .scaleLinear()
      .domain([0, d3.max(combinedData, d => Math.max(d.currentPeriod, d.previosPeriod)) ?? 0])
      .nice()
      .range([height, 0]);

    // Add the X axis
    svg
      .append("g")
      .attr("transform", `translate(0, ${height})`)
      .call(d3.axisBottom(x0))
      .selectAll("text")
      .style("text-anchor", "middle")
      .style("font-size", "12px");

    // Add X axis label
    svg
      .append("text")
      .attr("x", width / 2)
      .attr("y", height + margin.bottom - 17)
      .attr("text-anchor", "middle")
      .text("Flavor")
      .style("font-size", "12px");

    // Add the Y axis
    svg.append("g").call(d3.axisLeft(y));

    // Add Y axis label
    svg
      .append("text")
      .attr("x", -(height / 2))
      .attr("y", -margin.left + 15)
      .attr("text-anchor", "middle")
      .attr("transform", "rotate(-90)")
      .text("Consumption")
      .style("font-size", "12px");

    // Add bars for each period
    const barGroups = svg
      .selectAll(".bar-group")
      .data(combinedData)
      .enter()
      .append("g")
      .attr("transform", d => `translate(${x0(d.flavor)},0)`);

    const tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "tooltip")
      .style("position", "absolute")
      .style("background-color", "#fff")
      .style("border", "1px solid #ccc")
      .style("padding", "8px")
      .style("border-radius", "5px")
      .style("opacity", 0)
      .style("pointer-events", "none");

    barGroups
      .selectAll("rect")
      .data(d => [
        { key: "previosPeriod", value: d.previosPeriod, color: d.previosColor, flavor: d.flavor },
        { key: "currentPeriod", value: d.currentPeriod, color: d.currentColor, flavor: d.flavor },
      ])
      .enter()
      .append("rect")
      .attr("x", d => x1(d.key)!)
      .attr("y", d => y(d.value))
      .attr("width", x1.bandwidth())
      .attr("height", d => height - y(d.value))
      .attr("fill", d => d.color) // Use dynamic color from data
      .on("mouseover", (event, d) => {
        tooltip.transition().duration(200).style("opacity", 0.9);
        tooltip
          .html(`Flavor: ${d.flavor}<br>Period: ${d.key === 'currentPeriod' ? 'Current Period' : 'Previos Period'}<br>Total Consumption: ${d.value}`)
          .style("left", `${event.pageX + 15}px`)
          .style("top", `${event.pageY - 40}px`);
      })
      .on("mousemove", function (event: MouseEvent) {
        tooltip
          .style("left", `${event.pageX + 15}px`)
          .style("top", `${event.pageY - 40}px`);
      })
      .on("mouseout", function () {
        tooltip.transition().duration(500).style("opacity", 0);
      });

    // Add labels on top of the bars
    barGroups
      .selectAll(".label")
      .data(d => [
        { key: "previosPeriod", value: d.previosPeriod, flavor: d.flavor },
        { key: "currentPeriod", value: d.currentPeriod, flavor: d.flavor },
      ])
      .enter()
      .append("text")
      .attr("x", d => x1(d.key)! + x1.bandwidth() / 2)
      .attr("y", d => y(d.value) + 40)
      .attr("text-anchor", "middle")
      .style("font-size", "12px")
      .style("fill", "white")
      .text(d => d.value);

  }, [data, dimensions]);

  return (
    <>
      <svg ref={svgRef} style={{ width: "100%", height: "auto" }} />
      <div
        ref={tooltipRef}
        style={{
          display: "none",
          position: "absolute",
          backgroundColor: "#fff",
          color: "#000",
          padding: "5px",
          borderRadius: "3px",
          boxShadow: "0 2px 6px rgba(0,0,0,0.2)", // Optional shadow for better visibility
          pointerEvents: "none",
          fontSize: "12px",
        }}
      />
    </>
  );
};

export default FlavorWiseBlendChart;

import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

interface CreateSubTaskInput {
    parentId: string,
    assignToId: string,
    priority: string,
    issueTypeId: string,
    comment: string,
    // reportedAt?: string;
    machineServices?: string[];
    marketingOrOthers?: string[];
    operations?: string[];
    machineServiceOtherInput?: string;
    operationOtherInput?: string;
    marketingOtherInput?: string;
}

export async function createSubTask(input: CreateSubTaskInput) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        mutation createSubTask (
          $input: CreateSubTaskInput!
        ){
          createSubTask(
            createSubTaskInput: $input
            ) {
                id
                uuid
                ticketType
                machineId
                parentId
                ticketSourceId
                reportedAt
                assignToId
                issueTypeId
                priority
                issueType {
                  id
                  name
                }
                serviceType
                machineServices {
                  id
                  name
                }
                operations {
                  id
                  name
                }
                marketingOrOthers {
                  id
                  name
                }
                comment
                lastStatusComment
                lastAssigneToComment
                status
                howTicketClosed
                closingDateAndTime
                timeSpentOnTask
                travelTime
                customerSatisfaction
                cleanliness
                trafficRate
                staffEnthusiasm
                createdById
                createdAt
                updatedById
                updatedAt
                media {
                  url
                  type
                }
                signatureName
                signatureImage {
                  url
                  type
                }
                machine {
                  id
                  uuid
                  serialNumber
                  name
                  deploymentDate
                  locationId
                  deployingPersonId
                  softwareVersion
                  hardwareVersion
                  createdById
                  updatedById
                  isActive
                  remark
                }
                ticketSource {
                  id
                  name
                  createdAt
                  updatedAt
                }
                assignTo {
                  id
                  firstName
                  lastName
                }
                createdBy {
                  id
                  firstName
                  lastName
                }
                updatedBy {
                  id
                  firstName
                  lastName
                }
                parent {
                  id
                  uuid
                  ticketType
                  machineId
                  parentId
                  ticketSourceId
                  reportedAt
                  assignToId
                  issueTypeId
                  priority
                  comment
                  lastStatusComment
                  lastAssigneToComment
                  status
                  howTicketClosed
                  closingDateAndTime
                  timeSpentOnTask
                  travelTime
                  customerSatisfaction
                  cleanliness
                  trafficRate
                  staffEnthusiasm
                  createdById
                  createdAt
                  updatedById
                  updatedAt
                  signatureName
                }
                subTasks {
                  id
                  uuid
                  ticketType
                  machineId
                  parentId
                  ticketSourceId
                  reportedAt
                  assignToId
                  issueTypeId
                  priority
                  serviceType
                  comment
                  lastStatusComment
                  lastAssigneToComment
                  status
                  howTicketClosed
                  closingDateAndTime
                  timeSpentOnTask
                  travelTime
                  customerSatisfaction
                  cleanliness
                  trafficRate
                  staffEnthusiasm
                  createdById
                  createdAt
                  updatedById
                  updatedAt
                  signatureName
                }
            }
        }
        `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));

  return data;
}

export async function getIssueType() {
    const data = await axios({
      url: API_URL,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        query: `
                query {
                    getIssueTypes {
                      id
                      name
                      createdAt
                      updatedAt
                    }
                  }
                `,
        variables: {},
      },
    });
    return data?.data?.data?.getIssueTypes;
  }
import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

interface CreateSurveyInput {
  title: string;
  questions: [
    {
      question: string;
      answers: [];
      is_multi_answer: boolean;
      from_range: number;
      to_range: number;
      start_label:string;
      end_label:string;
    }
  ];
  other_feedback_required: boolean;
}

interface UpdateSurveyInput {
  title: string;
  questions: [
    {
      question_id: string;
      question: string;
      answers: [];
      is_multi_answer: boolean;
      from_range: number;
      to_range: number;
      start_label:string;
      end_label:string;
    }
  ];
  other_feedback_required: boolean;
}

export async function createSurvey(input: CreateSurveyInput) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        mutation createSurvey (
          $input:CreateSurveyInput!
        ) {
          createSurvey(
            createSurveyInput:$input
          ) {
            id
            title
            questions {
                   id
                    surveyId
                    answerType
                    question
                    fromRange
                    toRange
                    startLabel
                    endLabel
                    answers
                    isMultiAnswer
                    createdAt
                    updatedAt
            }
            otherFeedbackRequired
            createdAt
          }
        }
      `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

export async function updateSurvey(id: string, input: UpdateSurveyInput) {
  let data: any;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        mutation updateSurvey (
          $id: String!
          $input: UpdateSurveyInput!
        ) {
          updateSurvey(
            id: $id
            updateSurveyInput: $input
          ) {
            id
            title
            questions {
                    id
                    surveyId
                    answerType
                    question
                    fromRange
                    toRange
                    startLabel
                    endLabel
                    answers
                    isMultiAnswer
                    createdAt
                    updatedAt
            }
            otherFeedbackRequired
            createdAt
          }
        }
      `,
      variables: {id, input},
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

export async function getSurveyList() {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        query {
          getSurveyList {
            id
            title
            questions {
                    id
                    surveyId
                    answerType
                    question
                    fromRange
                    toRange
                    startLabel
                    endLabel
                    answers
                    isMultiAnswer
                    createdAt
                    updatedAt
            }
            otherFeedbackRequired
            createdAt
          }
        }
      `,
      variables: {},
    },
  });
  return data?.data?.data?.getSurveyList;
}

export async function getSurveyById(id: string) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        query($id:String!) {
          getSurveyById(id:$id) {
            id
            title
            questions {
                    id
                    surveyId
                    answerType
                    question
                    fromRange
                    toRange
                    startLabel
                    endLabel
                    answers
                    isMultiAnswer
                    createdAt
                    updatedAt
            } 
            otherFeedbackRequired
            createdAt
          }
        }
      `,
      variables: {
        id,
      },
    },
  });
  return data?.data?.data?.getSurveyById;
}

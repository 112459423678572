import * as yup from "yup";

export interface SelectedOption {
  value: string;
  label: string;
}

const initialQuestion = {
  question: "",
  answers: [""],
  is_multi_answer: false,
  answer_type: "",
  from_range: undefined,
  to_range: undefined,
  start_label: "",
  end_label: "",
};

const initialValues = {
  title: "",
  questions: [
    initialQuestion,
  ],
  other_feedback_required: true,
};

const radioButtonDB = [
  { id: true, name: "Yes" },
  { id: false, name: "No" },
];

const surveyQuestionTypes = [
  { id: "Button", name: "Button" },
  { id: "Numeric_Scale", name: "Numeric Scale" },
  { id: "Sliding_Scale", name: "Sliding Scale" },
];

const surveyValidationSchema = yup.object().shape({
  title: yup.string().required("Title is required"),
  questions: yup.array().of(
    yup.object().shape({
      answer_type: yup
        .string()
        .oneOf(
          ["Button", "Numeric_Scale", "Sliding_Scale"],
          "Answer type must be Button, Numeric Scale, or Sliding Scale"
        )
        .required("Answer type is required"),
      question: yup.string().required("Question is required"),
      answers: yup.array().when("answer_type", {
        is: "Button",
        then: yup
          .array()
          .of(yup.string().required("Answer is required"))
          .min(1, "At least one answer is required for Button"),
        otherwise: yup.array().nullable(),
      }),
      is_multi_answer: yup.boolean().when("answer_type", {
        is: "Button",
        then: yup.boolean().required("Is multi answer is required"),
        otherwise: yup.boolean().notRequired(),
      }),
      from_range: yup.number().when("answer_type", {
        is: (val: any) => val === "Numeric_Scale" || val === "Sliding_Scale",
        then: yup.number().required("From range is required"),
        otherwise: yup.number().nullable(),
      }),
      to_range: yup.number().when("answer_type", {
        is: (val: any) => val === "Numeric_Scale" || val === "Sliding_Scale",
        then: yup.number().required("To range is required"),
        otherwise: yup.number().nullable(),
      }),
      // start_label: yup.string().when("answer_type", {
      //   is: (val: any) => val === "Numeric_Scale" || val === "Sliding_Scale",
      //   then: yup.string().required("Start label is required"),
      //   otherwise: yup.string().nullable(),
      // }),
      // end_label: yup.string().when("answer_type", {
      //   is: (val: any) => val === "Numeric_Scale" || val === "Sliding_Scale",
      //   then: yup.string().required("End label is required"),
      //   otherwise: yup.string().nullable(),
      // }),
    })
  ),
  other_feedback_required: yup
    .boolean()
    .required("Other feedback required is required"),
});

export {
  radioButtonDB,
  initialValues,
  initialQuestion,
  surveyQuestionTypes,
  surveyValidationSchema,
};

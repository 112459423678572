import clsx from "clsx";
import * as yup from "yup";
import moment from "moment";
import Select from "react-select";
import Datetime from "react-datetime";
import ReactSelect from "react-select";
import "react-responsive-modal/styles.css";
import { ErrorMessage, Formik } from "formik";
import toast, { Toaster } from "react-hot-toast";
import { createEvent } from "../../../models/_event";
import { getSurveyList } from "../../../models/_survey";
import { getLocations } from "../../../models/_location";
import  { useEffect, useRef, useState } from "react";
import { Option } from '../../../../_metronic/helpers/MySelect';

const validationSchema = yup.object({
  title: yup.string().required("Title is required"),
  startDate: yup.date().required("Event start date is required"),
  endDate: yup.date().required("Event end date is required")
    .when('startDate', (startDate, schema) => {
      return schema.min(startDate, 'Event end date must be greater than or equal to event start date');
    }),
  formPin: yup.string().required("Form Pin is required"),
  locationId: yup.array().min(1, "Location is required")
});

function CreateEvent() {
  const resetSurveyId = useRef<any>(null);
  const [loading, setLoading] = useState(false);
  const [surveyDB, setSurveyDB] = useState([]);
  const [locationDB, setLocationDB] = useState([]);
  const fetchData = async () => {
    try {
      const surveyRecord = await getSurveyList();
      const locationRecord = await getLocations();
      const LocationData = locationRecord?.data
      setLocationDB(LocationData)
      setSurveyDB(surveyRecord);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <div id="kt_app_toolbar" className="app-toolbar py-2 pt-4">
        <div
          id="kt_app_toolbar_container"
          className="app-container container-xxl d-block d-lg-flex d-md-flex d-xl-flex flex-stack px-3"
          style={{ maxWidth: "100%" }}
        >
          <div className="page-title d-flex flex-column justify-content-center flex-wrap">
            <h1 className="page-heading d-flex text-dark fw-bold fs-1 flex-column justify-content-center my-0">
              Create Event
            </h1>
          </div>
          <div className="d-flex align-items-center gap-2 gap-lg-3 mt-3 mt-lg-0 mt-md-0">
            <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
              <li className="breadcrumb-item text-muted">
                <a href="/" className="text-muted text-hover-primary fs-5">
                  Home
                </a>
              </li>
              <li className="breadcrumb-item">
                <span className="bullet bg-gray-400 w-5px h-2px" />
              </li>
              <li className="breadcrumb-item text-dark fs-5">
                Create Event
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="card mx-xl-4 mt-9">
        <Formik
          initialValues={{
            title: "",
            description: "",
            venue: "",
            startDate: new Date(),
            endDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
            surveyId: "",
            formPin: "",
            locationId: []
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, { resetForm, setSubmitting }) => {
            setLoading(true);

            const $inputData: any = {
              title: values.title,
              description: values.description,
              venue: values.venue,
              startDate: moment(values.startDate).utc().format("YYYY-MM-DD HH:mm:ss"),
              endDate: moment(values.endDate).utc().format("YYYY-MM-DD HH:mm:ss"),
              surveyId: values.surveyId,
              formPin: values.formPin,
              locationId: values.locationId
            };
            const result = await createEvent($inputData);

            if (result?.data?.data?.createEvent) {
              toast.success("Event created successfully");
              resetSurveyId.current.setValue("");
              setSubmitting(false);
              setLoading(false);
              resetForm();
            } else {
              setSubmitting(false);
              setLoading(false);
              const messages = result?.data?.errors.map((e: any) => e.message);
              toast.error(messages.join(","));
            }
          }}
        >
          {({
            handleChange,
            handleSubmit,
            values,
            errors,
            touched,
            setFieldValue,
          }) => (
            <div className="card-body w-100 p-lg-7 p-md-7 p-5">
              <form onSubmit={handleSubmit} autoComplete="off">
                <div className="row">
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="form-label fs-4 required fw-bold">
                      Title
                    </label>
                    <input
                      className={clsx("form-control", {
                        "is-invalid": Boolean(errors.title) && touched.title,
                      })}
                      name="title"
                      id="title"
                      value={values.title}
                      onChange={handleChange}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="title" />
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="form-label fs-4 fw-bold">
                      Description
                    </label>
                    <textarea
                      className="form-control"
                      name="description"
                      id="description"
                      value={values.description}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="form-label fs-4 fw-bold">
                      Venue
                    </label>
                    <input
                      className="form-control"
                      name="venue"
                      id="venue"
                      value={values.venue}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="form-label fs-4 fw-bold">
                      Survey
                    </label>
                    <Select
                      ref={resetSurveyId}
                      className={clsx("react-select", {
                        "is-invalid":
                          Boolean(errors.surveyId) &&
                          touched.surveyId,
                      })}
                      classNamePrefix="my-react-select"
                      name="surveyId"
                      placeholder=""
                      onChange={(event: any) => {
                        setFieldValue("surveyId", event.value);
                      }}
                      options={Array.from(surveyDB).map((value: any) => {
                        return {
                          value: value.id,
                          label: value.title,
                        };
                      })}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="surveyId" />
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="form-label fs-4 required fw-bold">
                      Locations
                    </label>
                    <ReactSelect
                      className={clsx("react-select", {
                        "is-invalid":
                          Boolean(errors.locationId) &&
                          touched.locationId,
                      })}
                      classNamePrefix="my-react-select"
                      isMulti
                      name="locationId"
                      placeholder=""
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      onChange={(event: any) => {
                        setFieldValue("locationId", event.map((a: any) => { return a.value }));
                      }}
                      components={{
                        Option,
                      }}
                      options={Array.from(locationDB).map((value: any) => {
                        return {
                          value: value.id,
                          label: value.name,
                        };
                      })}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="locationId" />
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="form-label fs-4 required fw-bold">
                      Form Pin
                    </label>
                    <input
                      className={clsx("form-control", {
                        "is-invalid": Boolean(errors.formPin) && touched.formPin,
                      })}
                      name="formPin"
                      id="formPin"
                      value={values.formPin}
                      onChange={handleChange}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="formPin" />
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                    <label className="form-label fs-4 required fw-bold">Event Start Date </label>
                    <Datetime
                      dateFormat="YYYY-MM-DD"
                      timeFormat="HH:mm:ss"
                      closeOnSelect
                      value={values.startDate}
                      onChange={(e: any) => {
                        const dt = e["_d"];
                        setFieldValue("startDate", dt);
                      }}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="startDate" />
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                    <label className="form-label fs-4 required fw-bold">Event End Date</label>
                    <Datetime
                      dateFormat="YYYY-MM-DD"
                      timeFormat="HH:mm:ss"
                      closeOnSelect
                      value={values.endDate}
                      onChange={(e: any) => {
                        const dt = e["_d"];
                        setFieldValue("endDate", dt);
                      }}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="endDate" />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 px-xl-5 p-2">
                    <button
                      type="submit"
                      className="btn btn-primary me-4 fs-4 w-100"
                    >
                      {loading && (
                        <span
                          className="indicator-progress"
                          style={{ display: "block" }}
                        >
                          Please Wait..
                          <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                        </span>
                      )}
                      {!loading && (
                        <span className="indicator-label">ADD EVENT</span>
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )}
        </Formik>

        <Toaster
          position="bottom-left"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            className: "",
            duration: 3000,
          }}
        />
      </div>
    </div>
  )
}
export default CreateEvent;
